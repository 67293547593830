<template>
    <div>
        <div class="app-content content">
            <div class="content-wrapper">
                <div class="content-header row">
                </div>
                <div class="content-body">
                    <section class="flexbox-container">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <div class="col-xl-4 col-lg-7 col-md-8 col-12 box-shadow-2 p-0">
                                <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                    <div class="card-header border-0">
                                        <div class="text-center mb-1">
                                            <img src="/images/logo_tsm_sign.png" alt="branding logo" width="100px">
                                        </div>
                                        <div class="font-large-1  text-center">
                                            {{ trans('app.register.title') }}
                                        </div>
                                    </div>
                                    <div class="card-content">

                                        <div class="card-body">
                                            <ul class="nav nav-tabs col-12 pb-2">
                                                <li class="nav-item col-12 col-lg-6 text-center">
                                                    <a class="nav-link active" id="base-tab1" data-toggle="tab"
                                                       aria-controls="tab1" href="#tab1"
                                                       aria-expanded="true"
                                                       @click="setMode(1)"
                                                    >
                                                        {{ trans('app.register.client_individual') }}
                                                    </a>
                                                </li>
                                                <li class="nav-item col-12 col-lg-6 text-center">
                                                    <a class="nav-link" id="base-tab2" data-toggle="tab"
                                                       aria-controls="tab2" href="#tab2"
                                                       aria-expanded="false"
                                                       @click="setMode(2)"
                                                    >
                                                        {{ trans('app.register.client_entity') }}
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="tab-content px-1 pt-1">
                                                <fieldset
                                                    class="form-group position-relative has-icon-left text-center">
                                                    <input id="last_name" v-model="form.last_name" type="text"
                                                           class="form-control round"
                                                           :class="{'validated-error': errors.has(form.last_name)}"
                                                           name="last_name" value="old('first_name')" required
                                                           autocomplete="last_name" autofocus
                                                           :placeholder="trans('app.register.last_name')">
                                                    <div class="form-control-position">
                                                        <i class="ft-user"></i>
                                                    </div>
                                                    <div class="text-danger" v-if="errors.has('last_name')"
                                                         v-html="errors.first('last_name')"></div>
                                                </fieldset>
                                                <fieldset
                                                    class="form-group position-relative has-icon-left text-center">
                                                    <input id="first_name" v-model="form.first_name" type="text"
                                                           class="form-control round"
                                                           :class="{'validated-error': errors.has(form.first_name)}"
                                                           name="first_name" value="old('first_name')" required
                                                           autocomplete="first_name" autofocus
                                                           :placeholder="trans('app.register.first_name')">
                                                    <div class="form-control-position">
                                                        <i class="ft-user"></i>
                                                    </div>
                                                    <div class="text-danger" v-if="errors.has('first_name')"
                                                         v-html="errors.first('first_name')"></div>
                                                </fieldset>
                                                <fieldset
                                                    class="form-group position-relative has-icon-left text-center">
                                                    <input id="email" v-model="form.email" type="email"
                                                           class="form-control round"
                                                           :class="{'validated-error': errors.has(form.email)}"
                                                           name="email" value="old('email')" required
                                                           autocomplete="email"
                                                           :placeholder="trans('app.register.email')">
                                                    <div class="form-control-position">
                                                        <i class="ft-mail"></i>
                                                    </div>
                                                    <div class="text-danger" v-if="errors.has('email')"
                                                         v-html="errors.first('email')"></div>
                                                </fieldset>
                                                <phone-field
                                                    class="position-relative has-icon-left text-center"
                                                    v-model="form.phone"
                                                    name="phone"
                                                    :placeholder="trans('app.register.phone')"
                                                ></phone-field>
                                                <fieldset
                                                    class="form-group position-relative has-icon-left text-center">
                                                    <input id="password" v-model="form.password" type="password"
                                                           class="form-control round"
                                                           :class="{'validated-error': errors.has(form.password)}"
                                                           name="password" required autocomplete="new-password"
                                                           :placeholder="trans('app.register.password')">
                                                    <div class="form-control-position">
                                                        <i class="ft-lock"></i>
                                                    </div>
                                                    <div class="text-danger" v-if="errors.has('password')"
                                                         v-html="errors.first('password')"></div>
                                                </fieldset>
                                                <fieldset
                                                    class="form-group position-relative has-icon-left text-center">
                                                    <input id="password-confirm" v-model="form.password_confirmation"
                                                           type="password" class="form-control round"
                                                           name="password_confirmation" required
                                                           autocomplete="new-password"
                                                           :placeholder="trans('app.register.confirm_password')">
                                                    <div class="form-control-position">
                                                        <i class="ft-lock"></i>
                                                    </div>
                                                </fieldset>
                                                <div class="tab-pane active" id="tab1" aria-expanded="true"
                                                     aria-labelledby="base-tab1"></div>
                                                <div role="tabpanel" class="tab-pane" id="tab2"
                                                     aria-labelledby="base-tab2">
                                                    <fieldset
                                                        class="form-group position-relative has-icon-left text-center">
                                                        <input id="inn" v-model="form.inn" type="text"
                                                               class="form-control round"
                                                               :class="{'validated-error': errors.has(form.inn)}"
                                                               name="inn" value="old('inn')"
                                                               required autocomplete="inn"
                                                               :placeholder="trans('app.register.inn')">
                                                        <div class="form-control-position">
                                                            <i class="fa fa-list-alt"></i>
                                                        </div>
                                                        <div class="text-danger" v-if="errors.has('inn')"
                                                             v-html="errors.first('inn')"></div>
                                                    </fieldset>
                                                    <div class="form-group text-center pt-2">
                                                        <button type="submit"
                                                                @click="getData"
                                                                class="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                                                                :disabled="!form.inn"
                                                        >
                                                            {{ trans('app.labels.search') }}
                                                        </button>
                                                    </div>
                                                    <div v-if="form.entity">
                                                        <fieldset v-if="form.entity.manual_input"
                                                                  class="form-group position-relative has-icon-left text-center">
                                                            <div class="text-danger" v-html="trans('app.register.not_found')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.full_name"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.full_name"
                                                                   :class="{'validated-error': errors.has(form.entity.full_name)}"
                                                                   name="name" value="old('full_name')" required
                                                                   autocomplete="full_name" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.full_name')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-building" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.full_name')"
                                                                 v-html="errors.first('form.entity.full_name')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.legal_address"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.legal_address"
                                                                   :class="{'validated-error': errors.has(form.entity.legal_address)}"
                                                                   name="name" value="old('legal_address')" required
                                                                   autocomplete="legal_address" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.legal_address')">
                                                            <div class="form-control-position">
                                                                <!--<i class="fa fa-building" aria-hidden="true"></i>-->
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.legal_address')"
                                                                 v-html="errors.first('form.entity.legal_address')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.postal_address"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.postal_address"
                                                                   :class="{'validated-error': errors.has(form.entity.postal_address)}"
                                                                   name="name" value="old('postal_address')" required
                                                                   autocomplete="postal_address" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.postal_address')">
                                                            <div class="form-control-position">
                                                                <!--<i class="fa fa-building" aria-hidden="true"></i>-->
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.postal_address')"
                                                                 v-html="errors.first('form.entity.postal_address')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.kpp"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.kpp"
                                                                   :class="{'validated-error': errors.has(form.entity.kpp)}"
                                                                   name="name" value="old('kpp')" required
                                                                   autocomplete="kpp" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.kpp')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.kpp')"
                                                                 v-html="errors.first('form.entity.kpp')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.bik"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.bik"
                                                                   :class="{'validated-error': errors.has(form.entity.bik)}"
                                                                   name="name" value="old('bik')" required
                                                                   autocomplete="bik" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.bik')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.bik')"
                                                                 v-html="errors.first('form.entity.bik')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.rs"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.rs"
                                                                   :class="{'validated-error': errors.has(form.entity.rs)}"
                                                                   name="name" value="old('rs')" required
                                                                   autocomplete="rs" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.rs')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.rs')"
                                                                 v-html="errors.first('form.entity.rs')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.ks"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.ks"
                                                                   :class="{'validated-error': errors.has(form.entity.ks)}"
                                                                   name="name" value="old('ks')" required
                                                                   autocomplete="ks" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.ks')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.ks')"
                                                                 v-html="errors.first('form.entity.ks')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.okpo"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.okpo"
                                                                   :class="{'validated-error': errors.has(form.entity.okpo)}"
                                                                   name="name" value="old('okpo')" required
                                                                   autocomplete="okpo" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.okpo')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.okpo')"
                                                                 v-html="errors.first('form.entity.okpo')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.okato"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.okato"
                                                                   :class="{'validated-error': errors.has(form.entity.okato)}"
                                                                   name="name" value="old('okato')" required
                                                                   autocomplete="okato" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.okato')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.okato')"
                                                                 v-html="errors.first('form.entity.okato')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.okved"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.okved"
                                                                   :class="{'validated-error': errors.has(form.entity.okved)}"
                                                                   name="name" value="old('okved')" required
                                                                   autocomplete="okved" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.okved')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.okved')"
                                                                 v-html="errors.first('form.entity.okved')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.ogrn"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.ogrn"
                                                                   :class="{'validated-error': errors.has(form.entity.ogrn)}"
                                                                   name="name" value="old('ogrn')" required
                                                                   autocomplete="ogrn" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.ogrn')">
                                                            <div class="form-control-position">
                                                                <i class="fa fa-list-alt" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.ogrn')"
                                                                 v-html="errors.first('form.entity.ogrn')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.director"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.director"
                                                                   :class="{'validated-error': errors.has(form.entity.director)}"
                                                                   name="name" value="old('director')" required
                                                                   autocomplete="director" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.director')">
                                                            <div class="form-control-position">
                                                                <i class="ft-user" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.director')"
                                                                 v-html="errors.first('form.entity.director')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.city_phone"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.city_phone"
                                                                   :class="{'validated-error': errors.has(form.entity.city_phone)}"
                                                                   name="name" value="old('city_phone')" required
                                                                   autocomplete="city_phone" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.phone')">
                                                            <div class="form-control-position">
                                                                <i class="ft-phone" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.city_phone')"
                                                                 v-html="errors.first('form.entity.city_phone')"></div>
                                                        </fieldset>
                                                        <fieldset
                                                            class="form-group position-relative has-icon-left text-center">
                                                            <input id="form.entity.site"
                                                                   type="text"
                                                                   class="form-control round"
                                                                   v-model="form.entity.site"
                                                                   :class="{'validated-error': errors.has(form.entity.site)}"
                                                                   name="name" value="old('site')" required
                                                                   autocomplete="site" autofocus
                                                                   :placeholder="trans('crud.entity_profile.columns.site')">
                                                            <div class="form-control-position">
                                                                <!--<i class="fa fa-building" aria-hidden="true"></i>-->
                                                            </div>
                                                            <div class="text-danger" v-if="errors.has('form.entity.site')"
                                                                 v-html="errors.first('form.entity.site')"></div>
                                                        </fieldset>

                                                    </div>
                                                </div>
                                                <div class="mb-1 form-group d-inline-flex">
                                                    <toggle-button id="offer" v-model="form.offer"
                                                                   :sync="true"></toggle-button>
                                                    <label class="font-size-xsmall pl-1"
                                                        v-html="trans('app.register.offer')"></label>
                                                </div>
                                                <div class="text-danger" v-if="errors.has('offer')"
                                                     v-html="errors.first('offer')"></div>
                                                <div class="form-group text-center pt-2">
                                                    <button type="submit"
                                                            @click="store"
                                                            class="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                                                            :disabled="!form.offer"
                                                    >
                                                        {{ trans('app.labels.continue') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="card-subtitle text-muted text-right font-small-3 mx-2 my-1">
                                            <span>
                                                {{ trans('app.register.already_a_member') }}
                                                <a href="/login" class="card-link">
                                                    {{ trans('app.login.title') }}
                                                </a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MixinsCreate from '../../vendor/codersstudio/crud/mixins/create.js'

    export default {
        name: "Register",
        data() {
            return {
                form: {
                    entity: null,
                    offer: false,
                },
                link: 'register',
                mode: 1,
            }
        },
        mixins: [MixinsCreate],
        methods: {
            getData() {
                if (this.form.inn != null) {
                    axios.get('/register/company', {
                        params: Object.assign({
                            inn: this.form.inn,
                        })
                    })
                        .then(response => {
                            this.form.entity = response.data;
                        })
                }
            },
            setMode(mode) {
                this.mode = mode;
            },
            preStore() {
                if (this.mode == 1) {
                    delete this.form.inn;
                } else {
                    this.form.inn = this.form.inn ?? '';
                }
            }
        }
    }
</script>

