<template>
    <div>
        <div class="spinner-front" :class="{'show' : isLoading}">
            <div class="spinner-border text-primary"></div>
            <br>
             {{ this.text }}
        </div>
        <div class="spinner-back" :class="{'show' : isLoading}"></div>
    </div>
</template>

<script>
    export default {
        name: "spinner",
        props:{
            text:{
                default: ''
            }
        },
        computed: {
            isLoading: {
                get: function () {
                    return this.$store.getters['spinner/getIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('spinner/setIsLoading', newValue);
                }
            }
        }
    }
</script>

<style scoped>

</style>
