import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('country-index', {
    data() {
        return {
            link: '/admin/countries',
        }
    },
    mixins: [MixinsIndex],
});
