import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'

Vue.component('shipment-status-create', {
    data() {
        return {
            link: '/admin/shipment_statuses',
        }
    },
    mixins: [MixinsCreate]
});
