import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('shipment-status-index', {
    data() {
        return {
            link: '/admin/shipment_statuses',
        }
    },
    mixins: [MixinsIndex]
});
