<template>
    <div class="card-body py-5">
        <div class="row justify-content-center">
            <div class="col-xl-8">
                <h1 class="text-center mb-5">
                    {{ trans('calculator.params') }}
                </h1>
                <div class="row">
                    <div class="col-xl-6">
                        <!-- Document section -->
                        <div class="card tariff__container">
                            <div class="card-header">
                                <h4 class="card-title">{{ trans('calculator.documents_and_parcels') }}</h4>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
                                    <form class="form">
                                        <div class="form-body">
                                            <h4 class="form-section">
                                                <div class="d-flex justify-content-between align-content-center">
                                                    <div>
                                                        <i class="fas fa-file"></i>
                                                        {{ trans('calculator.documents') }}
                                                    </div>
                                                    <div>
                                                        <div class="form-group ">
                                                            <toggle-button v-model="isDocumentTypeSelected"
                                                                           @change="onChangeTypeDocumentEventHandler"
                                                                           :sync="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>

                                            <div class="row">
                                                <div class="col-lg">
                                                    <div class="form-group">
                                                        <label>{{ trans('calculator.weight') }}</label>
                                                        <div class="form-inline">
                                                            <number decimal-places="3"
                                                                    :disabled="!isDocumentTypeSelected"
                                                                    v-model="document.weight"
                                                                    v-bind:class="{'is-invalid':(validationErrors.document && validationErrors.document.weight)}"
                                                                    class="form-control"
                                                            ></number>

                                                            <div v-if="validationErrors.document"
                                                                 class="invalid-feedback">{{
                                                                validationErrors.document.weight }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg">
                                                    <div class="form-group">
                                                        <label for="">{{ trans('calculator.type') }}</label>
                                                        <div class="custom-control custom-radio">
                                                            <b-form-radio :disabled="!isDocumentTypeSelected"
                                                                          v-model="document.type" name="document-type"
                                                                          value="envelope">
                                                                {{ trans('calculator.envelope')}}
                                                            </b-form-radio>
                                                        </div>
                                                        <div class="custom-control custom-radio">
                                                            <b-form-radio :disabled="!isDocumentTypeSelected"
                                                                          v-model="document.type" name="document-type"
                                                                          value="package">
                                                                {{ trans('calculator.package')}}
                                                            </b-form-radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">{{ trans('calculator.description') }}</label>
                                                <textarea :disabled="!isDocumentTypeSelected"
                                                          v-model="document.description"
                                                          v-bind:class="{'is-invalid':(validationErrors.document && validationErrors.document.description)}"
                                                          class="form-control"></textarea>

                                                <div class="form-field-hint">
                                                    {{ trans('calculator.description_required') }}
                                                </div>

                                                <div v-if="validationErrors.document"
                                                     class="invalid-feedback">{{
                                                        validationErrors.document.description }}
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isDocumentTypeSelected"
                                                                   v-model="with_return.doc" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.with_return')
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isDocumentTypeSelected"
                                                                   v-model="personal_delivery.doc" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.personal_delivery')
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Document section-->
                    </div>
                    <div class="col-xl-6">
                        <!-- Cargo section-->
                        <div class="card tariff__container">
                            <div class="card-header">
                                <h4 class="card-title" id="basic-layout-form">{{ trans('calculator.cargo') }}</h4>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
                                    <form class="form">
                                        <div class="form-body">
                                            <h4 class="form-section">
                                                <div class="d-flex justify-content-between align-content-center">
                                                    <div>
                                                        <i class="fas fa-box"></i>
                                                        {{ trans('calculator.cargo') }}
                                                    </div>
                                                    <div>
                                                        <div class="form-group ">
                                                            <toggle-button v-model="isCargoesTypeSelected"
                                                                           @change="onChangeTypeCargoesEventHandler"
                                                                           :sync="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>

                                            <div class="form-group">
                                                <label for="">{{ trans('calculator.quantity') }}</label>
                                                <div class="form-inline">
                                                    <input :disabled="!isCargoesTypeSelected" v-model="cargoesQuantity"
                                                           type="number" min="1" step="1" class="form-control">
                                                </div>
                                            </div>
                                            <div v-for="(cargo, index) in packages">
                                                <hr class="border-3">
                                                <hr class="border-3">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.width')
                                                                }}</label>
                                                            <input v-model="cargo.width" type="number"
                                                                   min="0"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].width)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].width }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.height')
                                                                }}</label>
                                                            <input v-model="cargo.height" type="number"
                                                                   min="0"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].height)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].height }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.depth')
                                                                }}</label>
                                                            <input v-model="cargo.length" type="number"
                                                                   min="0"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].length)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].length }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col weight_kilogram">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{
                                                                trans('calculator.weight_kilogram')
                                                                }}</label>
                                                            <number decimal-places="3"
                                                                    v-model="cargo.weight" type="number"
                                                                    class="form-control"
                                                                    :disabled="!isCargoesTypeSelected"
                                                                    v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].weight)}"
                                                                    :placeholder="trans('calculator.kilogram')"
                                                                    :min="0"
                                                            ></number>
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].weight }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">{{ trans('calculator.description') }}</label>
                                                    <textarea :disabled="!isCargoesTypeSelected"
                                                              v-model="cargo.description"
                                                              v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].description)}"
                                                              class="form-control"></textarea>

                                                    <div class="form-field-hint">
                                                        {{ trans('calculator.description_required') }}
                                                    </div>

                                                    <div
                                                        v-if="validationErrors.packages && validationErrors.packages[index]"
                                                        class="invalid-feedback">{{
                                                            validationErrors.packages[index].description }}
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">{{ trans('calculator.cargo_type') }}</label>
                                                    <select v-model="cargo.cargo_type_id"
                                                            :disabled="!isCargoesTypeSelected"
                                                            v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].cargo_type_id)}"
                                                            class="form-control">
                                                        <option v-for="cargoType in cargoTypes" :value="cargoType.id">{{
                                                            cargoType.title }}
                                                        </option>
                                                    </select>
                                                    <div
                                                        v-if="validationErrors.packages && validationErrors.packages[index]"
                                                        class="invalid-feedback">{{ validationErrors.packages[index].cargo_type_id
                                                        }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-auto col-lg-12">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.fragile" :sync="true"/>
                                                                <label class="" for="fragile">{{
                                                                    trans('calculator.fragile')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto col-lg-12">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.box" :sync="true"/>
                                                                <label class="" for="box">{{
                                                                    trans('calculator.box')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto col-lg-12">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.wrap"
                                                                               :sync="true"/>
                                                                <label class="" for="wrap">{{
                                                                    trans('calculator.wrap')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto col-lg-12">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.dangerous" :sync="true"/>
                                                                <label class="" for="dangerous">{{
                                                                    trans('calculator.dangerous')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto col-lg-12">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.scotch" :sync="true"/>
                                                                <label class="" for="scotch">{{
                                                                    trans('calculator.scotch')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- TODO сделать проверку, что выбран не один груз и тогда показать полоску --}}
                                                {{-- <hr v-if="cargo.count > 1" class="my-3"> -->
                                            </div>
                                            <hr>
                                            <div class="form-group mb-0">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isCargoesTypeSelected"
                                                                   v-model="with_return.cargo" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.with_return')
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isCargoesTypeSelected"
                                                                   v-model="personal_delivery.cargo" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.personal_delivery')
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Cargo section-->
                    </div>
                    <div class="m-auto text-center">
                        <a class="btn btn-bg-gradient-x-purple-blue btn-lg" @click="onCalculate">
                            {{ trans('calculator.form') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Number from "../../fields/Number";
    export default {
        name: "SelectCargoes",
        mounted: function() {
            this.value.packages = [this.document];
            Vue.nextTick(() => {
                if (!_.isEmpty(this.order)) {
                    console.log('this is order', this.order);
                    if (this.order.order_type.name === 'documents') {
                        console.log('this is documents');
                        this.isDocumentTypeSelected = true;
                        this.isCargoesTypeSelected = false;
                        let document = this.order.documents;
                        this.setDocument(document);
                    } else if (this.order.order_type.name === 'cargoes') {
                        console.log('this is cargoes');
                        this.isDocumentTypeSelected = false;
                        this.isCargoesTypeSelected = true;

                        if (this.order.cargoes.length > 0) {
                            let packages = [];
                            this.order.cargoes.map((cargo) => {
                                packages.push({
                                    'weight': cargo.weight,
                                    'width': cargo.width,
                                    'height': cargo.height,
                                    'length': cargo.length,
                                    'quantity': 1,
                                    'description': cargo.description,
                                    // 'type': cargo.cargo_type_id,
                                    'cargo_type_id': cargo.cargo_type_id,
                                    'fragile': Boolean(cargo.fragile),
                                    'box': Boolean(cargo.box),
                                    'wrap': Boolean(cargo.wrap),
                                    'dangerous': Boolean(cargo.dangerous),
                                    'scotch': Boolean(cargo.scotch)
                                });
                            });
                            this.packages = packages;
                            this.cargoesQuantity = this.order.cargoes.length;
                        }
                    }
                }
            });


        },
        components: {Number},
        watch: {
            value(val) { //Для v-model в родитель
                this.$emit('input', val);
            },
            cargoesQuantity(val) {
                val = parseInt(val);
                if (val < 1) val = 1;
                let packages = [];
                for (let n = 0; n < val; n++) {
                    if (!this.packages[n]) {
                        packages.push({
                            'weight': 1,
                            'width': 10,
                            'height': 10,
                            'length': 10,
                            'quantity': 1,
                            'description': null,
                            'type': 1,
                            'fragile': false,
                            'box': false,
                            'wrap': false,
                            'dangerous': false,
                            'scotch': false
                        });
                    } else {
                        packages.push(this.packages[n]);
                    }
                }
                this.packages = packages;
                return val;
            },
            document: {
                handler(val) {
                    if (this.isDocumentTypeSelected) {
                        this.value.packages = [val];
                    }
                },
                deep: true
            },
            packages: {
                handler(val) {
                    if (this.isCargoesTypeSelected) {
                        this.value.packages = JSON.parse(JSON.stringify(val));
                    }
                },
                deep: true
            },
            step(newValue, oldValue) { // проверка шага
                console.log(`Updating from ${oldValue} to ${newValue}`);
                //let step = newValue;
                if(oldValue === 0) this.validationErrors = {};
                //if(oldValue === 1 && newValue === 2 && !this.validate()) step = oldValue;
                //this.step = step;
            },
            with_return: {
                handler() {
                    if (this.isDocumentTypeSelected) {
                        this.value.with_return = this.with_return.doc;
                    } else {
                        this.value.with_return = this.with_return.cargo;
                    }
                },
                deep: true
            },
            personal_delivery: {
                handler() {
                    if (this.isDocumentTypeSelected) {
                        this.value.personal_delivery = this.personal_delivery.doc;
                    } else {
                        this.value.personal_delivery = this.personal_delivery.cargo;
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                validationErrors: {},
                value: {
                    type: 'documents', //document Or cargo
                    packages: [],
                    with_return: false,
                    personal_delivery: false
                },
                isDocumentTypeSelected: true,
                isCargoesTypeSelected: false,
                document: {
                    'type': 'envelope',
                    'weight': 0.499,
                    'width': 10,
                    'height': 10,
                    'length': 10,
                    'quantity': 1,
                    'description': null
                },
                cargoesQuantity: 1,
                packages: [
                    {
                        'weight': 1,
                        'width': 10,
                        'height': 10,
                        'length': 10,
                        'quantity': 1,
                        'description': null,
                        'cargo_type_id': 1,
                        'fragile': false,
                        'box': false,
                        'wrap': false,
                        'dangerous': false,
                        'scotch': false
                    }
                ],
                with_return: {
                    doc: false,
                    cargo: false,
                },
                personal_delivery: {
                    doc: false,
                    cargo: false,
                }
            }
        },
        props: {
            cargoTypes: {
                default: () => []
            },
        },
        methods: {
            onCalculate(){
                this.$emit('on-calculate');
            },
            setDocument: function (document){
                console.log("document = ", document);
                if(document!==null) {
                    this.$set(this.document, 'weight', document.weight);
                    this.$set(this.document, 'width', document.width);
                    this.$set(this.document, 'height', document.height);
                    this.$set(this.document, 'length', document.length);
                    this.$set(this.document, 'quantity', 1);
                    this.$set(this.document, 'description', document.description);
                    this.$set(this.document, 'type', document.type);
                }
            },
            onChangeTypeDocumentEventHandler: function (event) {
                this.isCargoesTypeSelected = !event.value;
                if (this.isDocumentTypeSelected) {
                    this.value.type = "documents";
                    //this.value.packages = [];
                    this.value.packages = [this.document];
                    this.value.with_return = this.with_return.doc;
                    this.value.personal_delivery = this.personal_delivery.doc;
                    console.log('DOC',this.value);
                } else {
                    this.value.type = "cargoes";
                    this.value.packages = this.packages;
                    this.value.with_return = this.with_return.cargo;
                    this.value.personal_delivery = this.personal_delivery.cargo;
                }
                this.cargoes = JSON.parse(JSON.stringify(this.value));
            },
            onChangeTypeCargoesEventHandler: function (event) {
                this.isDocumentTypeSelected = !event.value;
                if (this.isDocumentTypeSelected) {
                    this.value.type = "documents";
                    this.value.packages = [this.document];
                    this.value.with_return = this.with_return.doc;
                    this.value.personal_delivery = this.personal_delivery.doc;
                } else {
                    this.value.type = "cargoes";
                    this.value.packages = this.packages;
                    this.value.with_return = this.with_return.cargo;
                    this.value.personal_delivery = this.personal_delivery.cargo;
                }
                this.cargoes = JSON.parse(JSON.stringify(this.value));
            },
            validate: function () {
                let errors = {};
                let result = true;
                console.log("this.value", this.value);
                if (this.isDocumentTypeSelected) {
                    errors.document = {};
                    if (!this.document.weight) {
                        errors.document.weight = this.trans("calculator.validation.required");
                        result = false;
                    }
                    if (!this.document.description) {
                        errors.document.description = this.trans("calculator.validation.required");
                        result = false;
                    }
                } else {
                    errors.packages = [];
                    this.packages.forEach((cargo, index) => {
                        errors.packages.push({});

                        cargo.weight = parseFloat(cargo.weight);
                        if (!cargo.weight) {
                            errors.packages[index].weight = this.trans("calculator.validation.required");
                        }

                        if (!cargo.description) {
                            errors.packages[index].description = this.trans("calculator.validation.required");
                        }

                        cargo.width = parseFloat(cargo.width);
                        if (!cargo.width) {
                            errors.packages[index].width = this.trans("calculator.validation.required");
                        }

                        cargo.height = parseFloat(cargo.height);
                        if (!cargo.height) {
                            errors.packages[index].height = this.trans("calculator.validation.required");
                        }

                        cargo.length = parseFloat(cargo.length);
                        if (!cargo.length) {
                            errors.packages[index].length = this.trans("calculator.validation.required");
                        }

                        cargo.quantity = parseInt(cargo.quantity);
                        if (!cargo.quantity) {
                            errors.packages[index].quantity = this.trans("calculator.validation.required");
                        }

                        cargo.cargo_type_id = parseInt(cargo.cargo_type_id);
                        if (!cargo.cargo_type_id) {
                            errors.packages[index].cargo_type_id = this.trans("calculator.validation.required");
                        }

                        if (_.size(errors.packages[index])) result = false;
                    });
                }

                this.validationErrors = errors;
                if(result) this.cargoes = JSON.parse(JSON.stringify(this.value));

                return result;
            }
        },
        computed: {
            step: {
                // Текущая страница калькулятора может
                // 0 - это заглавная форма,
                // 1- выбор типа отправления,
                // 2 - выбор тарифа,
                // 3 - Оформление заявки
                get: function () {
                    return this.$store.getters['calculator/getStep'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setStep', newValue);
                }
            },
            cargoes: {
                get: function () {
                    return this.$store.getters['calculator/getCargoes'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCargoes', newValue);
                }
            },
            order: {
                get: function () {
                    return this.$store.getters['calculator/getOrder'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setOrder', newValue);
                }
            },
        }

    }
</script>

<style scoped>
    .form-field-hint {
        font-size: 90%;
        margin-top: 5px;
        text-transform: none;
        font-style: italic;
        color: #6967CE;
    }
</style>
