import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'
import AddressAutocomplete from "../../addressAutocomplete/Index";

Vue.component('address-create', {
    components: {AddressAutocomplete},
    data() {
        return {
            refresh: false,
            country: {},
            city: {},
            street: {},
            house: {},
            link: '/admin/addresses',
        }
    },
    watch: {
        refresh(val) {
            if (Boolean(val)) {
                this.$set(this, 'city', {});
                this.$set(this, 'street', {});
                this.$set(this, 'house', {});
                Vue.nextTick(() => {
                    this.$set(this, 'refresh', false);
                })
            }
        },
        country(val, oldVal) {
            let name = val && val.name ? val.name : '';
            let oldName = oldVal && oldVal.name ? oldVal.name : '';
            let name_without_type = val && val.name_without_type ? val.name_without_type : '';

            if (!name || !oldName || (name !== oldName)) {
                this.$set(this, 'refresh', true);
            }

            this.$set(this.form, 'country_code', name);
            this.$set(this.form, 'country_name', name_without_type);
        },
        city(val) {
            let name = val.name ? val.name : '';
            let name_with_type = val.name_with_type ? val.name_with_type : '';
            let fias_id = val.fias_id ? val.fias_id : '';
            let region_name = val.region_name ? val.region_name : '';
            let city_postal_code = val.postal_code ? val.postal_code : '';

            this.$set(this.form, 'city_name', name);
            this.$set(this.form, 'city_name_with_type', name_with_type);
            this.$set(this.form, 'city_fias_id', fias_id);
            this.$set(this.form, 'city_postal_code', city_postal_code);
            this.$set(this.form, 'region_name', region_name);
        },
        street(val) {
            let fias_id = val.fias_id ? val.fias_id : '';
            let name_with_type = val.name_with_type ? val.name_with_type : '';

            this.$set(this.form, 'street_fias_id', fias_id);
            this.$set(this.form, 'street', name_with_type);
        },
        house(val) {
            let name_with_type = val.name_with_type ? val.name_with_type : '';
            let postal_code = val.postal_code ? val.postal_code : '';

            this.$set(this.form, 'house', name_with_type);
            this.$set(this.form, 'postal_code', postal_code);
        }
    },
    mixins: [MixinsCreate]
});
