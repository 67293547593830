import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('user-index', {
    data() {
        return {
            link: '/admin/users',
        }
    },
    mixins: [MixinsIndex],
    methods:{
        title() {
            return window.location.search.match(/.role_name=client*/);
        },
        clearFilters() {
            this.form = this.title() ? {role_name: 'client'} : {};
            this.getData(1, this.title() ? 'filter' : '');
        },
        approve(item) {
            axios.put(`/admin/users/${item.id}/approve`, item)
                .then(() => {
                    window.location.href = this.link + this.parameters;
                    this.systemMessage('success',{
                        'title':this.trans('crud.actions.info'),
                        'text':this.trans('crud.actions.success.edit')
                    });
                })
                .catch((error) => {
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    });
                });
        },
        togglePostpay(item){
            axios.put(this.link + '/' + item.id + '/postpay')
                .then(() => {
                    this.systemMessage('success',{
                        'title':this.trans('crud.actions.info'),
                        'text':this.trans('crud.actions.success.edit')
                    });
                })
                .catch((error) => {
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    });
                });
        }
    }
});
