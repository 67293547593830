import Vue from 'vue';
import Vuex from 'vuex';
import calculator from '../components/calculator/store/calculator';
import spinner from '../components/spinner/store/spinner';
import user from '../../../vendor/codersstudio/acl/resources/js/store/modules/user'; //TODO брать опубликованный


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
export const strict = false;

export default new Vuex.Store({
	modules: {
		calculator,
        spinner,
        user
	},
	strict: debug,
    mutations: {
        setLoading: (state, data) => {
            state.isLoading = data;
        }
    }

});
