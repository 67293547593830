<template>
    <div>
        <div class="accordion" id="accordionTweaks">
            <div v-for="service in courierServices" class="card mb-1 border-primary">
                <div class="card-header py-0" :id="'heading' + service.id">
                    <h5 class="card-title mb-0">
                        <div class="custom-control custom-checkbox d-inline-block">
                            <input
                                v-model="is_show_service[service.id]"
                                @click="toogleServiceTariffs(service)"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'service-checkbox-' + service.id"
                            >
                            <label class="custom-control-label" :for="'service-checkbox-' + service.id"></label>
                        </div>
                        <button class="btn btn-link pl-0" type="button" data-toggle="collapse" :data-target="'#collapse' + service.id" aria-expanded="false" :aria-controls="'collapse' + service.id">
                            {{ service.title }}
                        </button>
                    </h5>
                </div>

                <div :id="'collapse' + service.id" class="collapse" :class="{show: _.head(courierServices).id === service.id}" :aria-labelledby="'heading' + service.id" data-parent="#accordionTweaks">
                    <div class="text-danger card-body py-0">

                        <table class="table table-roles">
                            <tr v-for="tariff in service.tariffs">
                                <td>
                                    <label class="col-form-label">{{ tariff.name }}</label>
                                </td>
                                <td>
                                    <div class="custom-control custom-checkbox ">
                                        <input
                                            v-model="is_show_tariff[tariff.id]"
                                            @input="toogleTariff($event, service, tariff)"
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="'tariff-checkbox-' + tariff.id"
                                        >
                                        <label class="custom-control-label" :for="'tariff-checkbox-' + tariff.id"></label>
                                    </div>
                                </td>
                                <td>
                                    <button @click="resetModal(tariff)" data-toggle="modal" :data-target="'#' + modal_id" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i></button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" :id="modal_id" tabindex="-1" role="dialog" :aria-labelledby="modal_id + 'Label'" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="modal_id + 'Label'">{{ trans('crud.user.tweaks.title') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="discountInput">{{ trans('crud.user.tweaks.columns.discount') }}</label>
                                <div class="input-group">
                                    <input v-model="form.discount" type="text" class="form-control" id="discountInput">
                                    <div class="input-group-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="text-danger" v-if="errors.has('discount')" v-html="errors.first('discount')"></div>
                            </div>
                            <div class="form-group">
                                <label for="extraChargeInput">{{ trans('crud.user.tweaks.columns.extra_charge') }}</label>
                                <div class="input-group">
                                    <input
                                        v-model="form.extra_charge" type="text" class="form-control" id="extraChargeInput">
                                    <div class="input-group-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <div class="text-danger" v-if="errors.has('extra_charge')" v-html="errors.first('extra_charge')"></div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button @click="submit" type="button" class="btn btn-primary">{{ trans('crud.buttons.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserServiceTweak",
        props: ['courierServices', 'userTariffs', 'user'],
        data() {
            return {
                is_show_tariff: [],
                is_show_service: [],
                form: {
                    tariff_id: null,
                    discount: null,
                    extra_charge: null
                },
                is_mounted: false,
                modal_id: _.uniqueId('modal')
            };
        },
        watch: {
            is_show_tariff: {
                handler: function(newValue, oldValue) {
                    if (this.is_mounted) {
                        this.setIsShowTariff(newValue);
                    }
                },
                deep: true
            }
        },
        methods: {
            setIsShowTariff(value) {
                axios.put(`/admin/users/${this.user.id}/tariffs/isshow`, {
                    is_show_tariff: value
                });
            },
            toogleServiceTariffs(service) {
                let serviceTariffIds = _.map(service.tariffs, 'id');
                let enabled = this.is_show_service[service.id];

                this.is_show_tariff.map((item, index) => {
                    if (_.includes(serviceTariffIds, index)) {
                        this.$set(this.is_show_tariff, index, !enabled);
                    }
                });
            },
            toogleTariff(event, service, tariff) {
                this.$nextTick(() => {
                    this.checkServiceVisibility(service);
                });
            },
            resetModal(tariff) {
                this.setIsShowTariff(this.is_show_tariff);
                let userTariff = _.find(this.userTariffs, ['id', tariff.id]);
                if (this.form.tariff_id !== tariff.id) {
                    if (userTariff !== undefined) {
                        this.$set(this.form, 'discount', userTariff.pivot.discount);
                        this.$set(this.form, 'extra_charge', userTariff.pivot.extra_charge);
                        this.$set(this.form, 'tariff_id', tariff.id);
                    } else {
                        this.$set(this.form, 'discount', 0);
                        this.$set(this.form, 'extra_charge', 0);
                        this.$set(this.form, 'tariff_id', tariff.id);
                    }
                }
            },
            submit() {
                this.errors.clear();
                this.checkFormData();
                axios.put(`/admin/users/${this.user.id}/tariffs`, this.form)
                    .then(() => {
                        $('#' + this.modal_id).modal('hide');
                    })
                    .catch((error) => {
                        _.forEach(error.response.data.errors, (item, index) => {
                            this.errors.add({
                                field: index,
                                msg: _.head(item)
                            });
                        });
                    });
                let userTariff = _.find(this.userTariffs, ['id', this.form.tariff_id]);
                if (userTariff !== undefined) {
                    this.$set(userTariff.pivot, 'discount', this.form.discount);
                    this.$set(userTariff.pivot, 'extra_charge', this.form.extra_charge);
                    this.resetModal(userTariff);
                }
            },
            checkFormData() {
                this.$set(this.form, 'discount', !_.isEmpty(this.form.discount) ? this.form.discount : 0);
                this.$set(this.form, 'extra_charge', !_.isEmpty(this.form.extra_charge) ? this.form.extra_charge : 0);
            },
            initData() {
                // init service level tweaks
                this.courierServices.map(service => {
                    this.$set(this.is_show_service, service.id, Boolean(service.enabled));
                    if (Boolean(service.enabled)) {
                        service.tariffs.map(tariff => {
                            this.$set(this.is_show_tariff, tariff.id, Boolean(tariff.is_show));
                        });
                    }
                });
                // init user level tweaks
                let personalTariffs = _.map(this.userTariffs, 'pivot');
                personalTariffs.map((pivot) => {
                    this.$set(this.is_show_tariff, pivot.tariff_id, Boolean(pivot.is_show));
                });
                this.checkServicesVisibility();
            },
            checkServicesVisibility() {
                this.courierServices.map(service => {
                    this.checkServiceVisibility(service);
                });
            },
            checkServiceVisibility(service) {
                let personalTariffs = _.map(this.userTariffs, 'pivot');
                let serviceTariffIds = _.map(service.tariffs, 'id');
                let personalTariffsIds =_.map(personalTariffs, 'tariff_id');
                let intersected = _.intersection(serviceTariffIds, personalTariffsIds);
                if (_.difference(serviceTariffIds, personalTariffsIds).length === 0) {
                    intersected = personalTariffs.filter((pivot) => {
                        if (_.includes(intersected, pivot.tariff_id)) {
                            return true;
                        }
                    });
                    intersected = intersected.map((personalTariff) => {
                        this.$set(personalTariff, 'is_show', Number(this.is_show_tariff[personalTariff.tariff_id]));
                        // if (_.has(this.is_show_tariff, personalTariff.tariff_id)) {
                        // }
                        return personalTariff;
                    });
                    intersected = _.map(intersected, 'is_show');

                    if (_.every(intersected, (item) => {
                        return Boolean(item);
                    })) {
                        this.$set(this.is_show_service, service.id, true);
                    } else if (_.every(intersected, (item) => {
                        return !Boolean(item);
                    })) {
                        this.$set(this.is_show_service, service.id, false);
                    }
                }
            }
        },
        mounted() {
            this.initData();
            this.$nextTick(() => {
                this.is_mounted = true;
            })
        }
    }
</script>

<style scoped>

</style>
