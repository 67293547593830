import MixinsIndex from '../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('contact-index', {
    data() {
        return {
            link: '/contacts',
        }
    },
    mixins: [MixinsIndex],
    methods: {
        updateContact() {
            if (this.loading) {
                return;
            }
            this.errors.clear();
            this.link = window.location.pathname;
            this.loading = true;
            axios.put('/contacts/' + this.data.id, this.data)
                .then(response => {
                    window.location.href = this.link;
                    this.systemMessage('success',{
                        'title':this.trans('crud.actions.info'),
                        'text':this.trans('crud.actions.success.edit')
                    });
                })
                .catch(error => {
                    this.systemMessage('error',{
                        'title':this.trans('crud.actions.warning'),
                        'text':this.trans('crud.actions.fail.info')
                    });
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateTrackingPoint(id) {

        },
    }
});
