<template>
    <div class="card-body py-5">
        <div class="row justify-content-center">
            <div class="col-custom1600-10 col-custom1920-8">

                <div class="mb-3" v-if="hasRole('administrator') || hasRole('manager')">
                    <multiselect-field
                        display-column="label"
                        :required="true"
                        v-model="client"
                        name="user_id"
                        :options="clients"
                        :label="trans('app.calculator.client')"
                    ></multiselect-field>
                </div>

                <div class="card tariff__card mb-2">
                    <div class="card-header">
                        <h4 class="card-title">{{ trans('calculator.delivery_service') }}</h4>
                    </div>
                    <div v-if="tariff" class="card-body">
                        <tariff-card :tariff-element="tariff"></tariff-card>
                        <div class="text-xs-left text-md-right">
                            <a class="btn btn-outline-primary btn-sm" @click="step=2">
                                {{ trans('app.labels.change') }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="card offer-form__card" v-if="!user">
                    <div class="row">
                        <div class="col-lg-12 px-3 pt-2 pb-2">
                            <text-field
                                :required="true"
                                v-model="data.email"
                                :label="trans('calculator.email')"
                                type="email"
                                name="email"
                            ></text-field>
                        </div>
                    </div>
                </div>

                <Countdown v-if="pickup_date.pickup_time_to"
                           :deadline="pickup_date.pickup_time_to.format('YYYY-MM-DD HH:mm:ss')"></Countdown>

                <div class="card offer-form__card" v-if="billcode_active">
                    <div class="card-header">
                        <h4 class="card-title">{{ trans('calculator.billcode.title') }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 px-3 pt-2">
                            <text-field
                                :required="billcode_active > 0 && billcode_required > 0"
                                v-model="data.billcode"
                                :label="trans('calculator.billcode.title')"
                                type="text"
                                name="billcode"
                            ></text-field>
                        </div>
                    </div>
                </div>

                <!-- Contact form -->
                <div class="card offer-form__card">
                    <div class="card-header">
                        <h4 class="card-title">{{ trans('calculator.sender.title') }}</h4>
                    </div>
                    <template v-if="step===3">
                        <address-form
                            v-model="data.from"
                            prefix="from."
                        ></address-form>
                    </template>
                </div>
                <!-- Contact form -->

                <!-- Contact form -->
                <div class="card offer-form__card">
                    <div class="card-header">
                        <h4 class="card-title">{{ trans('calculator.recipient.title') }}</h4>
                    </div>
                    <template v-if="step===3">
                        <address-form
                            v-model="data.to"
                            prefix="to."
                        ></address-form>
                    </template>
                </div>
                <!-- Contact form -->

                <div class="card border_default">
                    <div class="card-header">
                        <h4 class="card-title" id="basic-layout-form">{{ trans('calculator.pickup_time') }}</h4>
                    </div>
                    <div class="card-body">
                        <!--TODO validation errors-->
                        <pickup-date-time
                            v-model="pickup_date"
                            :validation-errors="[]"
                            :available-days="tariff.attributes.availableDays"
                            :courier_name="tariff.attributes.courier_name"
                        ></pickup-date-time>
                        <div class="text-center small text-muted">
                            {{ trans('app.offers.working_days') }}
                        </div>
                    </div>
                </div>
                <div class="card border_default">
                    <div class="card-body pb-1">
                        <text-field
                            v-show="Boolean(data.insurance)"
                            v-model="insurance_estimated_cost"
                            type="number"
                            name="insurance_estimated_cost"
                            :required="Boolean(data.insurance)"
                            :label="trans('crud.order.columns.insurance_estimated_cost') + ', ' + trans('app.rub')"
                            :placeholder="trans('crud.order.columns.insurance_estimated_cost')"
                        ></text-field>
                        <!--<text-field
                            v-show="Boolean(data.insurance)"
                            :disabled="true"
                            :value="data.insurance_price"
                            type="number"
                            name="insurance_price"
                            :label="trans('crud.order.columns.insurance_price') + ', ' + trans('app.rub')"
                            :placeholder="trans('crud.order.columns.insurance_price')"
                        ></text-field>
                        <div class="form-group">
                            <toggle-button v-model="data.high_responsibility" :sync="true"/>
                            <label class="">
                                {{ trans('crud.order.columns.high_responsibility') }} + {{ high_responsibility_price }}
                                {{ trans('app.rub') }}
                            </label>
                        </div>
                        <div class="form-group">
                            <toggle-button v-model="data.insurance" :sync="true"/>
                            <label class="">{{ trans('app.offers.insurance') }}</label>
                        </div>-->
                        <div class="form-group">
                            <toggle-button v-model="data.send_notifications" :sync="true"/>
                            <label class="">{{ trans('app.offers.send_notifications') }}</label>
                        </div>
                        <div class="form-group mb-0">
                            <toggle-button v-model="agree" :sync="true"/>
                            <label class="font-weight-bold"
                                   v-html="trans('app.register.offer')"
                                   style="max-width: 80%"></label>
                        </div>
                    </div>
                </div>
                <div class="row text-center mb-2">
                    <div v-if="tariff.attributes.price > 0" class="col text-center mb-1">
                        <button class="btn btn-primary" @click="createOrder(true)" :disabled="!agree">
                            {{ trans('app.offers.pay_now') }}
                        </button>
                    </div>
                    <div class="col text-center mb-1"
                         v-if="tariff.attributes.price < 0 || user && user.is_postpay
                         || (user && (user.roles[0].name === 'admin' || (user && user.roles[0].name === 'manager')))" >
                       <!-- v-if="tariff.attributes.price < 0
                        || user && user.roles[0].name !== 'client_individual'
                        || (user && user.roles[0].name === 'client_entity' && user.approved === 1)">-->
                        <!--v-if="user.roles[0].name !== 'client_individual' || (user.roles[0].name === 'client_entity')"-->
                        <button class="btn btn-info btn-pay_later" @click="createOrder(false)" :disabled="!agree">
                            {{ trans('app.offers.pay_later') }}
                        </button>
                    </div>
                </div>
                <div class="text-center small text-muted">
                    {{ trans('app.offers.warning') }}
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import TariffCard from "./partials/TariffCard";
    import AddressForm from "./partials/AddressForm";
    import PickupDateTime from "./partials/PickupDateTime";
    import Countdown from '../../countdown/Index';
    import moment from "moment";

    export default {
        name: "CreateOrder",
        components: {
            TariffCard,
            AddressForm,
            PickupDateTime,
            Countdown
        },
        props: {
            clients: {
                default: () => []
            },
            embedded: {
                default: false
            },
            insurance_coefficient: {
                default: 0.01
            },
            high_responsibility_price: {
                default: 700
            },
            billcode_active: {
                default: 0
            },
            billcode_required: {
                default: 0
            },
        },
        data() {
            return {
                insurance_estimated_cost: 7500,
                agree: false,
                pickup_date: {},
                client: {},
                isWait: true,
                data: {
                    "email": '',
                    "pay_now": false,
                    "courier_service_id": null,
                    "user_id": null,
                    'with_return': false,
                    'personal_delivery': false,
                    from: {
                        "country_name": "",
                        "region_name": "",
                        "city_id": "",
                        "city_fias_id": "",
                        "city_name": "",
                        "street": "",
                        "house": "",
                        "company_name": "",
                        "first_name": "",
                        "last_name": "",
                        "phone": "",
                        "email": "",
                        "comment": "",
                        "save": false
                    },
                    to: {
                        "country_name": "",
                        "region_name": "",
                        "city_id": "",
                        "city_fias_id": "",
                        "city_name": "",
                        "street": "",
                        "house": "",
                        "company_name": "",
                        "first_name": "",
                        "last_name": "",
                        "phone": "",
                        "email": "",
                        "comment": "",
                        "save": false
                    },
                    "package_type": 'documents',
                    'insurance_price': 75,
                    'insurance_estimated_cost': 7500,
                    "cargoes": [],
                    "pickup_date": "",
                    "pickup_time_from": "",
                    "pickup_time_to": "",
                    "send_notifications": false,
                    "subscribed": false,
                    "insurance": false,
                    "tariff_response_id": null,
                    "billcode": '',
                }
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
            createOrder: function (payNow) {
                if (!this.agree || this.insurance_estimated_cost.length === 0) return;

                // check frontend errors
                this.errors.clear(null);
                if (this.errors.count('frontend')) {
                    _.forEach(this.errors.collect(), ((item, index) => {
                        this.errors.add({
                            field: index,
                            msg: this.errors.first(index, 'frontend')
                        });
                    }));
                    return;
                }

                this.isLoading = true;
                this.data.pay_now = payNow;
                this.data.package_type = this.cargoes.type;
                this.data.with_return = this.cargoes.with_return;
                this.data.personal_delivery = this.cargoes.personal_delivery;
                this.data.cargoes = this.cargoes.packages;
                this.data.tariff_response_id = this.tariff.attributes.tariff_response_id;
                this.data.subscribed = this.data.send_notifications;

                let url = '';
                if (this.embedded) url = '?embedded=1';

                axios.post('/orders', this.data)
                    .then(response => {
                        let order_id = response.data.data.id;
                        if (payNow) {
                            axios.post('/payments', {
                                order_id
                            }).then(response => {
                                let redirect_url = response.data.redirect_url ? response.data.redirect_url : false;
                                if (redirect_url) {
                                    window.location.href = redirect_url + url;
                                }
                            });
                        } else {
                            window.location.href = '/orders/' + order_id + '/created' + url;
                        }
                    })
                    .catch(error => {
                        _.forEach(error.response.data.errors, (item, index) => {
                            if (_.isInteger(index)) {
                                this.systemMessage(
                                    "error",
                                    {
                                        text: item.detail
                                    }
                                );
                            } else {
                                let element = document.getElementsByName(index)[0];
                                if (element) {
                                    element.scrollIntoView({block: "center", behavior: "smooth"});
                                }
                                this.errors.add({
                                    field: index,
                                    msg: _.head(item)
                                });
                                this.systemMessage(
                                    "error",
                                    {
                                        text: _.head(item)
                                    }
                                );
                            }
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
            initData() {
                let data = this.data;

                data.from.country_name = this.cityFrom.country_name;
                data.from.country_code = this.cityFrom.country_code;
                data.from.region_name = this.cityFrom.region_name;
                data.from.city_id = this.tariff.from_city_id;
                data.from.city_name_with_type = this.cityFrom.city_name_with_type;
                data.from.street_fias_id = this.cityFrom.street_fias_id;
                data.from.city_fias_id = this.cityFrom.city_fias_id;
                data.from.city_name = this.cityFrom.city_name;
                data.from.postal_code = this.cityFrom.postal_code;
                data.from.comment = (data.from.comment)?data.from.comment:this.cityFrom.comment;

                data.to.country_name = this.cityTo.country_name;
                data.to.country_code = this.cityTo.country_code;
                data.to.region_name = this.cityTo.region_name;
                data.to.city_id = this.tariff.to_city_id;
                data.to.street_fias_id = this.cityTo.street_fias_id;
                data.to.city_name_with_type = this.cityTo.city_name_with_type;
                data.to.city_fias_id = this.cityTo.city_fias_id;
                data.to.city_name = this.cityTo.city_name;
                data.to.postal_code = this.cityTo.postal_code;
                data.to.comment = (data.to.comment)?data.to.comment:this.cityTo.comment;

                this.$set(this.data, 'user_id', _.isEmpty(this.order) ? this.data.user_id : this.order.user_id);
                this.client = _.get(this.order, 'user', this.client);

                this.data = data;
            },
            showMsgBox() {
                this.boxTwo = ''
                this.$bvModal.msgBoxOk(this.trans('calculator.recalc_message'), {
                    title: this.trans('calculator.calculator'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                    .then(value => {
                       this.setNewValuesForCity();
                    })
                    .catch(err => {
                        this.setNewValuesForCity();
                    })
            },
            setNewValuesForCity(){
                let newValue = this.data;
                if(newValue.from.city_name && newValue.from.city_name !== this.cityFrom.city_name){
                    let city =  Object.assign({}, this.cityFrom);
                    city.city_name = newValue.from.city_name;
                    city.city_name_with_type = newValue.from.city_name;
                    city.city_label =  newValue.from.postal_code + ' ' + newValue.from.city_name;
                    city.city_fias_id =  newValue.from.city_fias_id;
                    city.postal_code = newValue.from.postal_code;
                    this.cityFrom = city;
                }

                if(newValue.to.city_name && newValue.to.city_name !== this.cityTo.city_name){
                    let city =  Object.assign({}, this.cityTo);
                    city.city_name = newValue.to.city_name;
                    city.city_name_with_type = newValue.to.city_name_with_type;
                    city.city_label =  newValue.to.postal_code + ' ' + newValue.to.city_name;
                    city.city_fias_id =  newValue.to.city_fias_id;
                    city.postal_code = newValue.to.postal_code;
                    this.cityTo = city;
                }
            }
        },
        watch: {
            dataForWatcher: {
                handler: function(newValue, oldValue) {
                    if (this.errors.count('frontend')) {
                        _.forEach(this.errors.collect(), ((item, index) => {
                            let newVal = _.get(newValue, index),
                                oldVal = _.get(oldValue, index);
                            if(newVal !== oldVal) {
                                console.log('index newVal !== oldVal', index, newVal, oldVal);
                                this.errors.remove(index);
                            }

                        }));
                    }

                    if(newValue.from.city_name &&
                        (newValue.from.city_name !== this.cityFrom.city_name || newValue.to.city_name !== this.cityTo.city_name)){
                        this.showMsgBox();
                    }
                },
                deep: true
            },
            insurance_estimated_cost(newValue) {

                this.errors.items.forEach((value, index) => {
                    if (value.field === 'insurance_estimated_cost') {
                        this.errors.items.splice(index, 1);
                    }
                });

                if (newValue > 0) {
                    this.$set(this.data, 'insurance_estimated_cost', newValue);
                    let iprice = parseInt(newValue * this.insurance_coefficient);
                    this.$set(
                        this.data,
                        'insurance_price',
                        (iprice > 75) ? iprice : 75
                    );
                }else if(newValue.length > 0){
                    this.$set(
                        this.data,
                        'insurance',
                        false
                    );
                    this.insurance_estimated_cost = 7500;
                }else{
                    this.errors.add({
                        field: 'insurance_estimated_cost',
                        msg: this.trans('calculator.validation.required')
                    });
                }
            },
            pickup_date(val) {
                this.data.pickup_date = val.pickup_date.format('YYYY-MM-DD');
                this.data.pickup_time_from = val.pickup_time_from.format('HH:mm');
                this.data.pickup_time_to = val.pickup_time_to.format('HH:mm');
            },
            step(newValue, oldValue) { // проверка шага
                if (newValue === 3 && oldValue === 2) {
                    this.initData();
                }
            },
            client(val) {
                this.$set(this.data, 'user_id', _.get(val, 'id', null));
            },
            tariff(val){
                let newVal = JSON.parse(JSON.stringify(this.pickup_date));
                console.log('tariff', val);
                newVal.pickup_date = moment(val.attributes.pickup_date);
                newVal.pickup_time_from = moment(val.attributes.pickup_date);
                newVal.pickup_time_to = moment(val.attributes.pickup_date);
                this.pickup_date = newVal;
            }
        },
        computed: {
            dataForWatcher() {
                return _.cloneDeep(this.data);
            },
            user: {
                get() {
                    return this.$store.getters['user/getUser'];
                },
                set(newValue) {
                    this.$store.dispatch('user/setUser', newValue);
                }
            },
            step: {
                // Текущая страница калькулятора может
                // 0 - это заглавная форма,
                // 1- выбор типа отправления,
                // 2 - выбор тарифа,
                // 3 - Оформление заявки
                get: function () {
                    return this.$store.getters['calculator/getStep'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setStep', newValue);
                }
            },
            tariff: {
                get: function () {
                    return this.$store.getters['calculator/getTariff'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setTariff', newValue);
                }
            },
            cityFrom: {
                get: function () {
                    return this.$store.getters['calculator/getCityFrom'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityFrom', newValue);
                }
            },
            cityTo: {
                get: function () {
                    return this.$store.getters['calculator/getCityTo'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityTo', newValue);
                }
            },
            cargoes: {
                get: function () {
                    return this.$store.getters['calculator/getCargoes'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCargoes', newValue);
                }
            },
            isLoading: {
                get: function () {
                    return this.$store.getters['spinner/getIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('spinner/setIsLoading', newValue);
                }
            },
            order: {
                get: function () {
                    return this.$store.getters['calculator/getOrder'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setOrder', newValue);
                }
            },
        }
    }
</script>

<style scoped>
    .form-group label {
        vertical-align: top;
    }

    .tooltip {
        opacity: 1 !important;
    }
</style>
