import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'
import Multiselect from 'vue-multiselect'
import lodash from 'lodash';

Object.defineProperty(Vue.prototype, '_', { value: lodash });
Vue.component('city-edit', {
    mixins: [MixinsEdit],
    components: {
        Multiselect
    },
    data() {
        return {
            link: '/admin/cities',
            city: {},
            locations: [],
            isLoadingCity: false,
            multiselectValues: {}
        }
    },
    methods: {
        asyncFind: _.debounce(function (service, query) {
            if (query.length >= 3) {
                this.isLoadingCity = true
                axios.get('/admin/courier_services/' + service + '/searchCity?query=' + query, {
                }).then(response => {
                    this.locations = response.data;
                }).catch(error => {
                    console.error(error);
                    //this.systemMessage("error");
                }).finally(() => {
                    this.isLoadingCity = false
                });
            }
        }, 1000),
        clearLocations: function () {
            this.locations = [];
        },
        locationInput: function (service, event) {
            this.form[service] = event?.cityId;
        }
    },
    mounted() {
        let services = this.data.courier_services;
        services.map((service) => {
            this.$set(this.form, service.slug, service.pivot.cached_city_id);
        });
    }
});
