<template>
    <div class="card-body py-12">
        <div class="row justify-content-center">
            <div class="col-xl-12">
                <h3 class="text-center mb-12">
                    {{ trans('calculator.params') }}
                </h3>
                <div class="row">
                    <div class="col-xl-12">
                        <!-- Document section -->
                        <div class="card tariff__container">
                            <div class="card-header">
                                <h4 class="card-title">{{ trans('calculator.documents_and_parcels') }}</h4>
                                <a class="heading-elements-toggle">
                                    <i class="la la-ellipsis-v font-medium-3"></i>
                                </a>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
                                    <form class="form">
                                        <div class="form-body">
                                            <h4 class="form-section">
                                                <div class="d-flex justify-content-between align-content-center">
                                                    <div>
                                                        <i class="fas fa-file"></i>
                                                        {{ trans('calculator.documents') }}
                                                    </div>
                                                    <div>
                                                        <div class="form-group ">
                                                            <toggle-button v-model="isDocumentTypeSelected"
                                                                           @change="onChangeTypeDocumentEventHandler"
                                                                           :sync="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>

                                            <div class="row">
                                                <div class="col-lg">
                                                    <div class="form-group">
                                                        <label>{{ trans('calculator.weight') }}</label>
                                                        <div class="form-inline">
                                                            <number decimal-places="3"
                                                                    :disabled="!isDocumentTypeSelected"
                                                                    v-model="document.weight"
                                                                    v-bind:class="{'is-invalid':(validationErrors.document && validationErrors.document.weight)}"
                                                                    class="form-control"
                                                            ></number>

                                                            <div v-if="validationErrors.document"
                                                                 class="invalid-feedback">{{
                                                                validationErrors.document.weight }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg">
                                                    <div class="form-group">
                                                        <label for="">{{ trans('calculator.type') }}</label>
                                                        <div class="custom-control custom-radio">
                                                            <b-form-radio :disabled="!isDocumentTypeSelected"
                                                                          v-model="document.type" name="document-type"
                                                                          value="envelope">
                                                                {{ trans('calculator.envelope')}}
                                                            </b-form-radio>
                                                        </div>
                                                        <div class="custom-control custom-radio">
                                                            <b-form-radio :disabled="!isDocumentTypeSelected"
                                                                          v-model="document.type" name="document-type"
                                                                          value="package">
                                                                {{ trans('calculator.package')}}
                                                            </b-form-radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="">{{ trans('calculator.description') }}</label>
                                                <textarea :disabled="!isDocumentTypeSelected"
                                                          v-model="document.description"
                                                          class="form-control"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isDocumentTypeSelected"
                                                                   v-model="value.with_return" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.with_return')
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isDocumentTypeSelected"
                                                                   v-model="value.personal_delivery" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.personal_delivery')
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Document section-->
                    </div>
                    <div class="col-xl-12">
                        <!-- Cargo section-->
                        <div class="card tariff__container">
                            <div class="card-header">
                                <h4 class="card-title" id="basic-layout-form">{{ trans('calculator.cargo') }}</h4>
                                <a class="heading-elements-toggle">
                                    <i class="la la-ellipsis-v font-medium-3"></i>
                                </a>
                                <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                    </ul>
                                </div>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body">
                                    <form class="form">
                                        <div class="form-body">
                                            <h4 class="form-section">
                                                <div class="d-flex justify-content-between align-content-center">
                                                    <div>
                                                        <i class="fas fa-box"></i>
                                                        {{ trans('calculator.cargo') }}
                                                    </div>
                                                    <div>
                                                        <div class="form-group ">
                                                            <toggle-button v-model="isCargoesTypeSelected"
                                                                           @change="onChangeTypeCargoesEventHandler"
                                                                           :sync="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h4>

                                            <div class="form-group">
                                                <label for="">{{ trans('calculator.quantity') }}</label>
                                                <div class="form-inline">
                                                    <input :disabled="!isCargoesTypeSelected" v-model="cargoesQuantity"
                                                           type="number" min="1" step="1" class="form-control">
                                                </div>
                                            </div>
                                            <div v-for="(cargo, index) in packages">
                                                <hr class="border-3">
                                                <hr class="border-3">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.width')
                                                                }}</label>
                                                            <input v-model="cargo.width" type="number"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].width)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].width }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.height')
                                                                }}</label>
                                                            <input v-model="cargo.height" type="number"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].height)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].height }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{ trans('calculator.depth')
                                                                }}</label>
                                                            <input v-model="cargo.length" type="number"
                                                                   class="form-control"
                                                                   :disabled="!isCargoesTypeSelected"
                                                                   v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].length)}"
                                                                   :placeholder="trans('calculator.centimeter')">
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].length }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col weight_kilogram">
                                                        <div class="form-group">
                                                            <label for="" class="nowrap">{{
                                                                trans('calculator.weight_kilogram')
                                                                }}</label>
                                                            <number decimal-places="3"
                                                                    v-model="cargo.weight" type="number"
                                                                    class="form-control"
                                                                    :disabled="!isCargoesTypeSelected"
                                                                    v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].weight)}"
                                                                    :placeholder="trans('calculator.kilogram')"
                                                            ></number>
                                                            <div
                                                                v-if="validationErrors.packages && validationErrors.packages[index]"
                                                                class="invalid-feedback">{{
                                                                validationErrors.packages[index].weight }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">{{ trans('calculator.description') }}</label>
                                                    <textarea :disabled="!isCargoesTypeSelected"
                                                              v-model="cargo.description"
                                                              class="form-control"></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">{{ trans('calculator.cargo_type') }}</label>
                                                    <select v-model="cargo.cargo_type_id"
                                                            :disabled="!isCargoesTypeSelected"
                                                            v-bind:class="{'is-invalid':(validationErrors.packages && validationErrors.packages[index] && validationErrors.packages[index].cargo_type_id)}"
                                                            class="form-control">
                                                        <option v-for="cargoType in cargoTypes" :value="cargoType.id">{{
                                                            cargoType.title }}
                                                        </option>
                                                    </select>
                                                    <div
                                                        v-if="validationErrors.packages && validationErrors.packages[index]"
                                                        class="invalid-feedback">{{ validationErrors.packages[index].cargo_type_id
                                                        }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.fragile" :sync="true"/>
                                                                <label class="" for="fragile">{{
                                                                    trans('calculator.fragile')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.box" :sync="true"/>
                                                                <label class="" for="box">{{
                                                                    trans('calculator.box')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.wrap"
                                                                               :sync="true"/>
                                                                <label class="" for="wrap">{{
                                                                    trans('calculator.wrap')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.dangerous" :sync="true"/>
                                                                <label class="" for="dangerous">{{
                                                                    trans('calculator.dangerous')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="nowrap">
                                                                <toggle-button :disabled="!isCargoesTypeSelected"
                                                                               v-model="cargo.scotch" :sync="true"/>
                                                                <label class="" for="scotch">{{
                                                                    trans('calculator.scotch')
                                                                    }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- TODO сделать проверку, что выбран не один груз и тогда показать полоску --}}
                                                {{-- <hr v-if="cargo.count > 1" class="my-3"> -->
                                            </div>
                                            <hr>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isCargoesTypeSelected"
                                                                   v-model="value.with_return" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.with_return')
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="nowrap">
                                                    <toggle-button :disabled="!isCargoesTypeSelected"
                                                                   v-model="value.personal_delivery" :sync="true"/>
                                                    <label class="" >{{
                                                        trans('calculator.personal_delivery')
                                                        }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Cargo section-->
                    </div>
                    <div class="m-auto text-center">
                        <a class="btn btn-bg-gradient-x-purple-blue btn-lg" @click="calculate()">
                            {{ trans('calculator.form') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import SelectCargoes from "../../../calculator/components/SelectCargoes";

    export default {
        name: "OrderSelectCargoes",
        extends: SelectCargoes,
        props: {
            form: {
                default: () => []
            },
        },
        methods: {
           calculate: function(){
               this.step = 2;
      //         this.step=2;
           }
        },
        mounted: function(){
            if (this.form.order_type.name !== 'documents') {
                this.isDocumentTypeSelected = false;
                this.isCargoesTypeSelected = true;
                if(this.form.packages) this.packages = this.form.packages;
            }
           this.setDocument(this.form.documents);
        }
    }
</script>

<style scoped>

</style>
