import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('report-index', {
    data() {
        return {
            form: {
                results: {},
                sender:{},
                recipient:{}
            },
            link: '/admin/report',
        }
    },
    mixins: [MixinsIndex],
    methods: {
        showAddress(item) {
            window.location.replace("/admin/addresses/" + item);
        }
    }
});
