// initial state
// shape: [{ id, quantity }]
const state = {
    isLoading: false
};

// getters
const getters = {
    getIsLoading: (state, getters, rootState) => {
        return state.isLoading;
    }
};

// actions
const actions = {
    setIsLoading({commit, state}, isLoading) {
        commit('setIsLoading', isLoading);
    }
};

// mutations
const mutations = {
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
