import MixinsIndex from '../../../crud/mixins/index.js'

Vue.component('role-index', {
    data() {
        return {
            link: '/admin/roles',
        }
    },
    mixins: [MixinsIndex]
});
