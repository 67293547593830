<template>
    <div>
        <div class="row">
            <div class="col-12">

                <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
                    <li class="nav-item col-12 col-md-auto">
                        <a class="nav-link active" id="client-tab" data-toggle="tab" href="#client" role="tab"
                           aria-controls="client" aria-selected="true">{{ trans('crud.role.settings_client') }}</a>
                    </li>
                    <li class="nav-item col-12 col-md-auto">
                        <a class="nav-link" id="admin-tab" data-toggle="tab" href="#admin" role="tab"
                           aria-controls="admin" aria-selected="false">{{ trans('crud.role.settings_admin') }}</a>
                    </li>
                </ul>
                <div class="tab-content" id="roles">
                    <div class="tab-pane fade show active" id="client" role="tabpanel" aria-labelledby="client-tab">
                        <div v-if="name !== 'admin'" v-for="(permission, name) in permissions">
                            <div class="card mb-3">
                                <div class="card-header py-0">
                                    <h5 class="text-bold-600">{{ trans('crud.' + name + '.title') }}</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row" :key="name">
                                        <div class="form-group col-lg-6 col-xl-3">
                                            <div v-if="permission.view" class="">
                                                <label class="switch" :for="'view-' + permission.view">
                                                    <input v-model="permission_ids" type="checkbox"
                                                           class="" :value="permission.view"
                                                           :id="'view-' + permission.view">
                                                    <span>{{trans('crud.buttons.view')}}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6 col-xl-3">
                                            <div v-if="permission.create" class="">
                                                <label class="switch" :for="'view-' + permission.create">
                                                    <input v-model="permission_ids" type="checkbox"
                                                           class="" :value="permission.create"
                                                           :id="'view-' + permission.create">
                                                    <span>{{trans('crud.buttons.create')}}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6 col-xl-3">
                                            <div v-if="permission.edit" class="">
                                                <label class="switch" :for="'view-' + permission.edit">
                                                    <input v-model="permission_ids" type="checkbox"
                                                           class="" :value="permission.edit"
                                                           :id="'view-' + permission.edit">
                                                    <span>{{trans('crud.buttons.edit')}}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-6 col-xl-3">
                                            <div v-if="permission.delete" class="">
                                                <label class="switch" :for="'view-' + permission.delete">
                                                    <input v-model="permission_ids" type="checkbox"
                                                           class="" :value="permission.delete"
                                                           :id="'view-' + permission.delete">
                                                    <span>{{trans('crud.buttons.delete')}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="admin" role="tabpanel" aria-labelledby="admin-tab">
                        <div v-if="name === 'admin'" v-for="(permission, name) in permissions">
                            <div v-for="(admin, adminName) in permission">
                                <div class="card mb-3 ">
                                    <div class="card-header py-0">
                                        <h5 class="text-bold-600">{{ trans('crud.' + adminName + '.title') }}</h5>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="row" :key="adminName">
<!--                                            <div class="form-group col-lg-6 col-xl-3">-->
<!--                                                <div v-if="admin.view" class="">-->
<!--                                                    <label class="switch" :for="'view-' + admin.view">-->
<!--                                                        <input v-model="permission_ids" type="checkbox"-->
<!--                                                               class="" :value="admin.view"-->
<!--                                                               :id="'view-' + admin.view">-->
<!--                                                        <span>{{trans('crud.buttons.view')}}</span>-->
<!--                                                    </label>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <div class="col-lg-6 col-xl-3 ">-->
<!--                                                <div v-if="admin.view" class="form-group">-->
<!--                                                    <toggle-button v-model="'view-' + admin.view"-->
<!--                                                                   v-bind:id="'view-' + admin.view"-->
<!--                                                                   :sync="true">-->
<!--                                                    </toggle-button>-->
<!--                                                    <label class="" :for="'view-' + admin.view">-->
<!--                                                        {{trans('crud.buttons.view')}}-->
<!--                                                    </label>-->
<!--                                                </div>-->
<!--                                            </div>-->

                                            <div class="form-group col-lg-6 col-xl-3">
                                                <div v-if="admin.view" class="">
                                                    <label class="switch" :for="'view-' + admin.view">
                                                        <input v-model="permission_ids" type="checkbox"
                                                               class="" :value="admin.view"
                                                               :id="'view-' + admin.view">
                                                        <span>{{trans('crud.buttons.view')}}</span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-group col-lg-6 col-xl-3">
                                                <div v-if="admin.create" class="">
                                                    <label class="switch" :for="'view-' + admin.create">
                                                        <input v-model="permission_ids" type="checkbox"
                                                               class="" :value="admin.create"
                                                               :id="'view-' + admin.create">
                                                        <span>{{trans('crud.buttons.create')}}</span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-group col-lg-6 col-xl-3">
                                                <div v-if="admin.edit" class="">
                                                    <label class="switch" :for="'view-' + admin.edit">
                                                        <input v-model="permission_ids" type="checkbox"
                                                               class="" :value="admin.edit"
                                                               :id="'view-' + admin.edit">
                                                        <span>{{trans('crud.buttons.edit')}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class=" col-lg-6 col-xl-3">
                                                <div v-if="admin.delete" class="">
                                                    <label class="switch" :for="'view-' + admin.delete">
                                                        <input v-model="permission_ids" type="checkbox"
                                                               class="" :value="admin.delete"
                                                               :id="'view-' + admin.delete">
                                                        <span>{{trans('crud.buttons.delete')}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-actions text-center text-md-right">
                    <button class="btn btn-danger mb-1 mr-0 mr-lg-1" @click="cancel">
                        <i v-if="loading" class="fas fa-pulse fa-spinner"></i>
                        <i class="ft-x"></i>
                        {{trans('crud.buttons.cancel')}}
                    </button>
                    <button class="btn btn-primary mb-1"  @click="update">
                        <i v-if="loading" class="fas fa-pulse fa-spinner"></i>
                        <i class="la la-check-square-o"></i>
                        {{trans('crud.buttons.save')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "role-edit-checkbox",
        data() {
            return {
                form: this.data,
                permission_ids: this.data.permission_ids,
                loading: false,
                mediaCollections: {}
            }
        },
        props: {
            data: {
                default: () => {
                    return {}
                }
            },
            permissions: Object,
        },
        methods: {
            update() {
                if (this.loading) {
                    return;
                }
                this.getMedia();
                this.loading = true
                axios.put('/admin/roles/' + this.data.id, Object.assign(
                    this.form,
                    {
                        permission_ids: this.permission_ids
                    }
                ))
                    .then(response => {
                        window.location.href = '/admin/roles';
                        this.systemMessage();
                    })
                    .catch(error => {
                        this.systemMessage('error');
                        _.forEach(error.response.data.errors, (item, index) => {
                            this.errors.add({
                                field: index,
                                msg: _.head(item)
                            });
                        })
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            cancel() {
                window.location.href = '/admin/roles';
            },
            getMedia() {
                _.forEach(this.$refs, (item, index) => {
                    if (item.$refs.dropzone) {
                        this.$set(this.mediaCollections, index, item.$refs.dropzone.getAcceptedFiles())
                    }
                });
                this.form = Object.assign({
                    'mediaCollections': this.mediaCollections
                }, this.form)
            }
        }
    };
</script>
