import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'

Vue.component('courier_service-edit', {
    data() {
        return {
            link: '/admin/courier_services',
        }
    },
    mixins: [MixinsEdit]
});
