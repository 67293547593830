<template>
    <div class="form-group">
        <!-- Button trigger modal -->
        <button type="button" class="btn px-0 py-0" data-toggle="modal" :data-target="'#cb-modal'+id+uniqueId">
            {{ trans('calculator.cashback.title') }} <i class="fa fa-question-circle"></i>
        </button>
        <!-- Modal -->
        <div class="modal fade text-left" :id="'cb-modal'+id+uniqueId" tabindex="-1" role="dialog"
             :aria-labelledby="'cb-modal'+id+uniqueId" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title text-center"><i class="la la-arrow-right"></i>{{
                            trans('calculator.cashback.interested_in_cashback') }}
                        </h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form>
                        <div class="modal-body">
                            <h4>{{ trans('calculator.cashback.leave_the_mail') }}</h4>
                            <fieldset class="form-group floating-label-form-group mt-2 mb-1">
                                <phone-field
                                    :required="true"
                                    v-model="phone"
                                    :name="uniqueId + 'cashback-phone'"
                                    :label="trans('calculator.cashback.mail')"
                                    :placeholder="trans('calculator.cashback.mail')"
                                ></phone-field>
                                <div class="text-danger" v-if="errors.has('email')" v-html="errors.first('email')"></div>
                            </fieldset>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-info" href="#" @click="sendCashback">{{ trans('calculator.cashback.get_more')}}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CashbackModal",
        props: {
            id: {
                default: () => null
            }
        },
        data() {
            return {
                uniqueId :  _.uniqueId('_'),
                phone: ''
            }
        },
        methods: {
            sendCashback() {
                axios.post('/cashback/send',{ 'phone': this.phone}).then(response => {
                    this.systemMessage('success',{
                        'title':this.trans('crud.actions.info'),
                        'text':this.trans('crud.actions.success.info')
                    });
                    $('#cb-modal'+this.id+this.uniqueId).modal('hide');
                }).catch(error => {
                    this.systemMessage('error',{
                        'title':this.trans('crud.actions.warning'),
                        'text':this.trans('crud.actions.fail.info')
                    });
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
