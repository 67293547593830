import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('address-index', {
    data() {
        return {
            link: '/admin/addresses',
        }
    },
    mixins: [MixinsIndex]
});
