import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'

Vue.component('user-create', {
    data() {
        return {
            form: {
                admin_profile:{}
            },
            link: '/admin/users',
        }
    },
    mixins: [MixinsCreate]
});
