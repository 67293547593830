import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('order-index', {
    data() {
        return {
            orderColumn: 'created_at',
            orderDirection: 'desc',
            modal_order_id: null,
            form: {
                sender: {},
                recipient: {},
            },
            link: '/admin/orders',
            payUrl: '/payments'
        }
    },
    mixins: [MixinsIndex],
    methods: {
        printInvoice(order) {
            window.location.replace('/admin/orders/' + order + '/invoice');
        },
        resetApi(order) {
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.resetapi_message'),
                {
                    okTitle: this.trans('crud.confirmation.yes'),
                    cancelTitle: this.trans('crud.confirmation.cancel'),
                })
                .then(value => {
                    if (value) {
                        this.isLoading = true;
                        axios.post(this.link + '/' + order.id + '/resetapi').then(response => {
                            this.getData();
                            this.systemMessage('success', {
                                'title': this.trans('crud.actions.info'),
                                'text': this.trans('crud.actions.success.info')
                            });
                        });
                    }
                });
        },
        showAddress(item) {
            window.location.replace("/admin/addresses/" + item);
        },
        pay(order_id) {
            window.location.href = this.payUrl;
        },
        setManager(id) {
            axios.put(this.link + '/' + id + '/manager').then(response => {
                this.getData();
                this.systemMessage('success', {
                    'title': this.trans('crud.actions.info'),
                    'text': this.trans('notification.order.manager_set')
                });
            });
        },
        cancel(id) {
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.cancel_order_message'),
                {
                    okTitle: this.trans('crud.confirmation.yes_cancel_order'),
                    cancelTitle: this.trans('crud.confirmation.cancel_cancel_order'),
                })
                .then(value => {
                    if (value) {
                        axios.post(this.link + '/' + id + '/cancel')
                            .then(response => {
                                this.getData();
                                this.systemMessage('success', {
                                    'title': this.trans('crud.actions.info'),
                                    'text': response.data.data.message
                                });
                            })
                            .catch(error => {
                                this.systemMessage('error', {
                                    'title': this.trans('crud.actions.warning'),
                                    'text': error.response.data.errors[0].detail
                                });
                            });
                    }
                })
                .catch(err => {

                });
        },
        copyOrder(order_id) {
            window.location.href = `/?order_id=${order_id}`;
        },
        exportReport() {
            let parameters = '?';
            _.forEach(this.form, (value, key) => {
                if (value) {
                    parameters += key + '=' + value + '&';
                }
            });

            let url = this.link + '/export' + parameters;
            window.open(url, '_blank');
        }
    }
});
