<template>
    <div>
        <div class="form-group">
            <label :for="id" class="" v-html="trans('calculator.dostavista.enter_street_name')">

                <span v-if="required" class="text-danger">*</span>
            </label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="form-group">
                        <!-- Button trigger modal -->
                        <button type="button" class="px-0 py-0" data-toggle="modal" data-keyboard="false"
                                data-target="#modalMapsDostavista" @click="selectedPoint = {'index':index}">
                            <div class="dv-order-point-address__map"><img
                                src="/images/dostavista/colored-map.png"></div>
                        </button>
                    </div>
                </div>
                <address-autocomplete
                    :id="id"
                    :placeholder="placeholder"
                    class="form-control multiselect-input"
                    :class="{'validated-error': errors.has(name)}"
                    :disabled="disabled"
                    :readonly="readonly"
                    :search-by="searchBy"
                    type="address"
                    :refresh="refresh"
                    v-model="currentValue"
                    @input="onChange"
                ></address-autocomplete>
            </div>
            <div class="text-danger" v-if="errors.has(prefix+'home') || errors.has(prefix+'street')" v-html="errors.first(prefix+'home') ? errors.first(prefix+'home') : errors.first(prefix+'street')"></div>
        </div>
    </div>
</template>

<script>
    import BaseField from "../../fields/mixins/BaseField";
    import AddressAutocomplete from "../../addressAutocomplete/Index";

    export default {
        name: "AddressDostavista",
        data(){
            return {
                currentValue: ''
            };
        },
        computed:{
            selectedPoint: {
                get: function () {
                    return this.$store.getters['calculator/getSelectedPoint'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setSelectedPoint', newValue);
                }
            }
        },
        mounted() {
            if(!_.isEmpty(this.value)) {
                this.currentValue = this.value;
            }
        },
        methods: {
            onChange(newValue, results){
                console.log('addressDostavista', newValue, results);
                this.$emit('input', newValue);
            }
        },
        watch: {
            value(val){
                this.currentValue = val;
            }
        },
        components: {AddressAutocomplete},
        mixins: [BaseField],
        props: ['searchBy', 'type', 'refresh', 'name', 'value', 'index', 'prefix']
    }
</script>

<style scoped>

</style>
<style scoped>
    .form-control {
        height: 43px !important;
    }
</style>
