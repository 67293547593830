<template>
    <span>
        <div v-if="!success" class="text-center d-block mx-auto mb-4">
            <h5 class="mx-auto mb-2">{{ trans('crud.order.created.mail_title') }}</h5>
                <div class="row">
                    <div class="text-right col-lg-3 col-md-12 mb-1 offset-lg-3">
                        <input type="email" v-model="mail" class="form-control" :placeholder="trans('crud.order.created.mail_placeholder')">
                    </div>
                <div class="col-lg-6 col-md-12 text-lg-left">
                    <button @click="sendMail"
                        class="btn btn-danger">{{ trans("crud.order.created.mail_subscribe") }}</button>
                </div>
            </div>
        </div>
        <div v-else class="text-center w-75 d-block mx-auto">
            <h5 class="mx-auto mb-2">{{ trans('crud.order.created.mail_success') }}</h5>
        </div>
    </span>
</template>
<script>

    export default {
        name: "OrderMail",
        data: () => ({
            mail: '',
            success: false
        }),
        props: {
            orderId: {
                type: Number,
                required: true
            },
        },
        methods: {
            sendMail() {
                this.isLoading = true;
                axios.post('/admin/orders/' + this.orderId + '/email', {
                    email: this.mail
                })
                    .then(response => {
                        this.success = true;
                    })
                    .catch(error => {
                        _.forEach(error.response.data.errors, (item, index) => {
                            if (_.isInteger(index)) {
                                this.systemMessage(
                                    "error",
                                    {
                                        text: item.detail
                                    }
                                );
                            } else {
                                this.errors.add({
                                    field: index,
                                    msg: _.head(item)
                                });
                                this.systemMessage(
                                    "error",
                                    {
                                        text: _.head(item)
                                    }
                                );
                            }
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        mounted: function () {

        },
        computed: {
            isLoading: {
                get: function () {
                    return this.$store.getters['spinner/getIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('spinner/setIsLoading', newValue);
                }
            }
        }
    }
</script>

<style scoped>

</style>
