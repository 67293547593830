import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('errorlog-index', {
    data() {
        return {
            link: '/admin/errorlogs',
            orderDirection: 'desc',
        }
    },
    mixins: [MixinsIndex]
});
