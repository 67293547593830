var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('vue-slider',{staticClass:"pt-lg-2",attrs:{"data":_vm.getTimefunction (_vm.time_start, _vm.time_end),"tooltip":"none","process":_vm.process,"fixed":true},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var start = ref.start;
var end = ref.end;
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process",style:(style)},[_c('div',{class:[
          'merge-tooltip',
          'vue-slider-dot-tooltip-inner',
          'vue-slider-dot-tooltip-inner-top' ]},[_vm._v("\n                    "+_vm._s(_vm.time[index])+" - "+_vm._s(_vm.time[index + 1])+"\n                ")])])]}}]),model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }