import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'
import UserServiceTweak from './components/UserServiceTweak.vue';


Vue.component('user-edit', {
    components: {
        UserServiceTweak
    },
    data() {
        return {
            form: {
                admin_profile: {},
                password: '',
                password_confirmation: ''
            },
            subscribed: false,
            billcode_active: false,
            billcode_required: false,
            company_orders_show: false,
            link: '/admin/users'
        }
    },
    methods: {
        generatePassword() {
            let newPass = this.makePassword();

            this.form.password = newPass;
            this.form.password_confirmation = newPass;

            console.log("newPass", newPass);
        },
        makePassword(length = 8) {
            let result = '',
                characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
                charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    },
    mounted() {
        this.subscribed = !!this.form.subscribed;
        this.billcode_active = !!this.form.billcode_active;
        this.billcode_required = !!this.form.billcode_required;
        this.company_orders_show = !!this.form.company_orders_show;
    },
    watch: {
        subscribed(newValue, oldValue) {
            this.form.subscribed = newValue;
        },
        billcode_active(newValue, oldValue) {
            this.form.billcode_active = newValue;
        },
        billcode_required(newValue, oldValue) {
            this.form.billcode_required = newValue;
        },
        company_orders_show(newValue, oldValue) {
            this.form.company_orders_show = newValue;
        },
    },
    mixins: [MixinsEdit]
});
