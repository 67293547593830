// initial state
// shape: [{ id, quantity }]
const state = {
    selectedPoint: 0,
    dostavistaFias: '',
    step: 0,
    cityFrom: {},
    cityTo: {},
    addressBook: {},
    tariffsIsLoading: false,
    points: {},
    cargoes: {
        type: 'documents',
        packages: [],
        with_return: false,
        personal_delivery: false
    },
    tariff: {
        id: null,
        type: null,
        attributes: {
            courier_before: null,
            courier_name: null,
            days_max: null,
            days_min: null,
            office_before: null,
            order_before: null,
            price: null,
            tariff_id: null,
            tariff_name: null
        }
    },
    tariffs: [
        {
            id: null,
            companyName: null,
            type: "tariffs",
            attributes: {
                days_max: null,
                days_min: null,
                price: null,
                tariff_id: null,
                tariff_name: null
            }
        }],
    addressFrom: {},
    addressTo: {},
    order: null
};

// getters
const getters = {
    getTariffsIsLoading: (state, getters, rootState) => {
        return state.tariffsIsLoading;
    },
    getSelectedPoint: (state, getters, rootState) => {
        return state.selectedPoint;
    },
    getStep: (state, getters, rootState) => {
        return state.step;
    },
    getAddressBook: (state, getters, rootState) => {
        return state.addressBook;
    },
    getCityFrom: (state, getters, rootState) => {
        return state.cityFrom;
    },
    getCityTo: (state, getters, rootState) => {
        return state.cityTo;
    },
    getCargoes: (state, getters, rootState) => {
        return state.cargoes;
    },
    getTariff: (state, getters, rootState) => {
        return state.tariff;
    },
    getTariffs: (state, getters, rootState) => {
        return state.tariffs;
    },
    getAddressFrom: (state, getters, rootState) => {
        return state.addressFrom;
    },
    getAddressTo: (state, getters, rootState) => {
        return state.addressTo;
    },
    getOrder: (state, getters, rootState) => {
        return state.order;
    },
    getDostavistaFrom: (state, getters, rootState) => {
        return state.points['1'] ? state.points['1'] : {};
    },
    getDostavistaTo: (state, getters, rootState) => {
        return state.points['2'] ? state.points['2'] : {};
    },
    getDostavistaExtraPoints: (state, getters, rootState) => {
        var points = {};
        _.forEach(state.points, function(val, index){
            if (parseInt(index) > 2) {
                points[index] = val;
            }
        });
        return points;
    }

};

// actions
const actions = {
    checkTariff({commit, state}, payload) {
        let qs = require('qs');
        axios.get(
            '/courierservice/'+state.tariff.attributes.courier_name+'/tariff?' + qs.stringify(payload, {encodeValuesOnly: true})
        ).then(response => {
            if (response.data.data && response.data.data[0]) {
                commit('setTariff', response.data.data[0]);
            }
        }).catch(error => {
            console.log(error.response.data);
        });
    },
    destroyExtraPoint({commit, state},payload){
        commit('destroyExtraPoint', payload);
    },
    createExtraPoint({commit, state}){
        commit('createExtraPoint');
    },
    setTariffsIsLoading({commit, state}, tariffsIsLoading) {
        commit('setTariffsIsLoading', tariffsIsLoading);
    },
    setStep({commit, state}, step) {
        commit('setStep', step);
    },
    setAddressBook({commit, state}, addressBook) {
        commit('setAddressBook', addressBook);
    },
    setCityFrom({commit, state}, cityFrom) {
        commit('setCityFrom', cityFrom);
    },
    setCityFromPostalCode({commit, state}, cityFromPostalCode) {
        commit('setCityFromPostalCode', cityFromPostalCode);
    },
    setCityTo({commit, state}, cityTo) {
        commit('setCityTo', cityTo);
    },
    setCityToPostalCode({commit, state}, cityToPostalCode) {
        commit('setCityToPostalCode', cityToPostalCode);
    },
    setCargoes({commit, state}, cargoes) {
        commit('setCargoes', cargoes);
    },
    setTariff({commit, state}, tariff) {
        commit('setTariff', tariff);
    },
    setTariffs({commit, state}, tariffs) {
        commit('setTariffs', tariffs);
    },
    setAddressFrom({commit, state}, addressFrom) {
        commit('setAddressFrom', addressFrom);
    },
    setAddressTo({commit, state}, addressTo) {
        commit('setAddressTo', addressTo);
    },
    setOrder({commit, state}, order) {
        commit('setOrder', order);
    },
    setSelectedPoint({commit, state}, payload) {
        commit('setSelectedPoint', payload.index);
    },
    setDostavistaFias({commit, state}, payload) {
        commit('setDostavistaFias', payload.fias);
    },
    setDostavistaPoint({commit, state}, payload) {
        commit('patchDostavistaPoint', payload);
    },
    setDostavistaPointPhone({commit, state}, payload) {
        commit('patchDostavistaPointPhone', payload);
    },
    setDostavistaPointDateTime({commit, state}, payload) {
        commit('patchDostavistaPointDateTime', payload);
    },
    setDostavistaPointComment({commit, state}, payload) {
        commit('patchDostavistaPointComment', payload);
    },
    setDostavistaPointFirstName({commit, state}, payload) {
        commit('patchDostavistaPointFirstName', payload);
    },
    setDostavistaPointLastName({commit, state}, payload) {
        commit('patchDostavistaPointLastName', payload);
    },

};

// mutations
const mutations = {
    patchDostavistaPoint(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.address = payload.addressObject.address;
        obj.address.lat = payload.position.lat;
        obj.address.lng = payload.position.lng;
        Vue.set(state.points, payload.index, obj);
    },
    patchDostavistaPointPhone(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.phone = payload.phone;
        Vue.set(state.points, payload.index, obj);
    },
    patchDostavistaPointDateTime(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.dateTime = payload.dateTime;
        Vue.set(state.points, payload.index, obj);
    },
    patchDostavistaPointComment(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.comment = payload.comment;
        Vue.set(state.points, payload.index, obj);
    },
    patchDostavistaPointFirstName(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.first_name = payload.first_name;
        Vue.set(state.points, payload.index, obj);
    },
    patchDostavistaPointLastName(state, payload) {
        let obj = state.points[payload.index] ? state.points[payload.index] : {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':''};
        obj.last_name = payload.last_name;
        Vue.set(state.points, payload.index, obj);
    },
    destroyExtraPoint(state, payload) {
        _.forEach(state.points, function(o,index){
            if (parseInt(index) > parseInt(payload.index)) {
                Vue.set(state.points,parseInt(index)-1,state.points[index]);
            }
        });
        Vue.delete(state.points, _.size(state.points));
    },
    createExtraPoint(state) {
        var uid = _.uniqueId();
        let point = {'address':{}, 'dateTime':{}, 'phone':'', 'comment':'', 'first_name':'','last_name':'', 'uid': uid };
        let index = (_.size(state.points)?_.size(state.points):0) + 1;
        Vue.set(state.points, index, point);
    },
    setDostavistaFias(state, fias) {
        state.dostavistaFias = fias;
    },
    setSelectedPoint(state, index) {
        state.selectedPoint = index;
    },
    setTariffsIsLoading(state, tariffsIsLoading) {
        state.tariffsIsLoading = tariffsIsLoading;
    },
    setStep(state, step) {
        state.step = step;
    },
    setCityFrom(state, cityFrom) {
        state.cityFrom = cityFrom;
    },
    setAddressBook(state, addressBook) {
        state.addressBook = addressBook;
    },
    setCityFromPostalCode(state, cityFromPostalCode) {
        state.cityFrom.postal_code = cityFromPostalCode;
    },
    setCityTo(state, cityTo) {
        state.cityTo = cityTo;
    },
    setCityToPostalCode(state, cityToPostalCode) {
        state.cityTo.postal_code = cityToPostalCode;
    },
    setCargoes(state, cargoes) {
        console.log("cargoes===", cargoes);
        state.cargoes.type = cargoes.type;
        state.cargoes.with_return = cargoes.with_return;
        state.cargoes.personal_delivery = cargoes.personal_delivery;
        if (cargoes.packages) {
            cargoes.packages.map((element, index) => { //fix for Error: [vuex] do not mutate vuex store state outside mutation handlers.
                state.cargoes.packages[index] = element;
                console.log('element', element);
            });
        } else {
            cargoes.packages = [];
        }
    },
    setTariff(state, tariff) {
        state.tariff = tariff;
    },
    setTariffs(state, tariffs) {
        tariffs.map((element, index) => { //fix for Error: [vuex] do not mutate vuex store state outside mutation handlers.
            state.tariffs[index] = element;
        });
    },
    setAddressFrom(state, addressFrom) {
        if (addressFrom) {
            Object.keys(addressFrom).map(function(key, index) {
                state.addressFrom[key] = addressFrom[key];
            });
        }
    },
    setAddressTo(state, addressTo) {
        if (addressTo) {
            Object.keys(addressTo).map(function(key, index) {
                state.addressTo[key] = addressTo[key];
            });
        }
    },
    setOrder(state, order) {
        state.order = order;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
