
Vue.component('user-show', {
    data() {
        return {
            data: {
                user_answers: null
            },
        }
    },
    mounted() {
        this.data = this.$attrs.user;
        this.data.user_answers = this.$attrs.user_answers;
    },
});
