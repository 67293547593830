import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('cargo-type-index', {
    data() {
        return {
            link: '/admin/cargo_types',
        }
    },
    mixins: [MixinsIndex]
});
