<template>
<div>
    <!-- Modal -->
    <div class="modal modal-book fade text-left" :id="id" tabindex="-1" role="dialog" aria-labelledby="basicModalLabel1" aria-hidden="true">
        <div class="modal-dialog width-lg-90vh max-width-lg-90vh" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="basicModalLabel1">{{ trans('crud.address_book.title') }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="mb-1 mx-2" v-if="hasRole('administrator') || hasRole('manager')">
                    <multiselect-field
                        display-column="label"
                        :required="false"
                        v-model="client"
                        name="user_id"
                        :options="clients"
                        :label="trans('app.calculator.client')"
                    ></multiselect-field>
                </div>
                <div class="modal-header fixed">
                    <div class="row-all-width">
                        <div class="col-8">
                            <div class="form-group">
                                <input @keyup.enter="getData" v-model="query" class="form-control" type="text" placeholder="" id="contactinput5">
                            </div>
                        </div>
                        <div class="col-4 pr-0">
                            <a @click="getData" class="form-control btn btn-success btn-search col-12" href="#">
                                {{ trans('crud.address_book.search') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-body overflow-auto">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>{{ trans('crud.address_book.columns.name') }}</th>
                                <th>{{ trans('crud.address_book.columns.phone') }}</th>
                                <th>{{ trans('crud.address_book.columns.address') }}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="address in addresses" :key="address.id">
                                <td>
                                    {{
                                        address.company_name ?
                                        address.company_name :
                                        (address.last_name ? address.last_name : '') + (address.first_name ? address.first_name : '') }}
                                </td>
                                <td>
                                    {{ address.phone }}
                                </td>
                                <td>
                                    {{ getAddressString(address) }}
                                </td>
                                <td class="">
                                    <button @click="addressChosen(address)" type="button" class="btn grey btn-info" data-dismiss="modal">
                                        {{ trans('crud.address_book.choose') }}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "AddressBook",
        props: ['prefix', 'clients', 'id'],
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                componentId: null,
                rows: 0,
                addresses: [],
                query: '',
                client: {}
            };
        },
        watch: {
            currentPage(val) {
                this.getData();
            }
        },
        methods: {
            getData() {
                axios.get('/admin/addresses', {
                    params: {
                        'contact': this.query,
                        'user_id': _.get(this, 'client.id', null),
                        'per_page': this.perPage,
                        'page': this.currentPage
                    }
                }).then(response => {
                    this.addresses = response.data.data.data;
                    this.rows = response.data.data.total;
                });
            },
            addressChosen(address) {
                this.$emit('input', address);
                let addressBook = address;
                addressBook.prefix = this.prefix;
                this.addressBook = addressBook;
                if (this.prefix.includes('from')) {
                    this.addressFrom = this.mapAddress(address);
                } else if (this.prefix.includes('to')) {
                    this.addressTo = this.mapAddress(address);
                }
            },
            mapAddress(address) {
                return {
                    city_fias_id: _.get(address, 'city.city_fias_id', ''),
                    // city_id: _.get(address, 'city_id', ''),
                    city_name: _.get(address, 'city_name', ''),
                    city_name_with_type: _.get(address, 'city.city_name_with_type', ''),
                    comment: _.get(address, 'comment', ''),
                    company_name: _.get(address, 'company_name', ''),
                    country_code: _.get(address, 'country_code', ''),
                    country_name: _.get(address, 'country_name', ''),
                    email: _.get(address, 'email', ''),
                    first_name: _.get(address, 'first_name', ''),
                    house: _.get(address, 'house', ''),
                    flat: _.get(address, 'flat', ''),
                    last_name: _.get(address, 'last_name', ''),
                    phone: _.get(address, 'phone', ''),
                    postal_code: _.get(address, 'postal_code', _.get(address, 'city.postal_code', '')),
                    region_name: _.get(address, 'region_name', ''),
                    save: false,
                    street: _.get(address, 'street', ''),
                    street_fias_id: _.get(address, 'street_fias_id', '')
                }
            },
            getAddressString(address) {
                let addressArr = [];
                if (address) {
                    if (address.city) {
                        address.city.city_name_with_type ? addressArr.push(address.city.city_name_with_type) : addressArr.push('');
                    } else {
                        address.city_name ? addressArr.push(address.city_name) : addressArr.push('');
                    }
                    address.street ? addressArr.push(address.street) : addressArr.push('');
                    address.house ? addressArr.push(address.house) : addressArr.push('');
                    address.flat ? addressArr.push('кв.' + address.flat) : addressArr.push('');
                    address.postal_code ? addressArr.push('индекс' + address.postal_code) : addressArr.push('');
                }
                return addressArr.join(' ');

            }
        },
        computed: {
            addressFrom: {
                get() {
                    return this.$store.getters['calculator/getAddressFrom'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressFrom', newValue);
                }
            },
            addressTo: {
                get() {
                    return this.$store.getters['calculator/getAddressTo'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressTo', newValue);
                }
            },
            addressBook: {
                get() {
                    return this.$store.getters['calculator/getAddressBook'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressBook', newValue);
                }
            },
        },
        mounted() {
            //this.componentId = _.uniqueId('address_book_modal');
            this.getData();
        }
    }
</script>

<style scoped>

</style>
