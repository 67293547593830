var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.dostavista_order &&
            _vm.data.dostavista_order.dostavista_points &&
            _vm.data.dostavista_order.dostavista_points[0] &&
            _vm.data.dostavista_order.dostavista_points[0].latitude &&
            _vm.data.dostavista_order.dostavista_points[0].longitude)?_c('div',{staticClass:"height-550"},[_c('div',{staticClass:"full-height"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{
            lat: parseFloat(_vm.data.dostavista_order.dostavista_points[0].latitude),
            lng: parseFloat(_vm.data.dostavista_order.dostavista_points[0].longitude)
            },"zoom":14,"options":{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}},[_vm._l((_vm.data.dostavista_order.dostavista_points),function(point,index){return _c('GmapMarker',{key:index,attrs:{"position":{lat:parseFloat(point.latitude),lng:parseFloat(point.longitude)},"clickable":false,"draggable":false,"icon":{url: (point.courier_visit_datetime ? _vm.greenPointUrl : _vm.redPointUrl), labelOrigin:{x:30,y:75}},"label":{text: point.street+' '+point.house}}})}),_vm._v(" "),(_vm.data.dostavista_order.courier_latitude && _vm.data.dostavista_order.courier_longitude)?_c('GmapMarker',{attrs:{"position":{lat:parseFloat(_vm.data.dostavista_order.courier_latitude),lng:parseFloat(_vm.data.dostavista_order.courier_longitude)},"clickable":false,"draggable":false,"icon":{url: _vm.courierPointUrl, labelOrigin:{x:15,y:35}},"label":{text: _vm.data.dostavista_order.courier_name}}}):_vm._e()],2)],1)]):_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.textCourierSearch))])])}
var staticRenderFns = []

export { render, staticRenderFns }