export default {
    name: "BaseField",
    inheritAttrs: false,
    inject: ['$validator'], // inherit validation scope from parent
    props: {
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        value: {},
    },
    data() {
        return {
            id: null,
        }
    },
    mounted() {
        this.id = _.uniqueId('form_field');
    }
}
