<template>
    <li class="notification dropdown dropdown-notification nav-item">
        <a class="nav-link nav-link-label dropdown-toggle" href="#" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
            <i class="ficon ft-bell" id="notification-navbar-link" :class="messages.length ? 'bell-shake' : ''"></i>
            <span v-if=" messages.length > 0" class="badge badge-pill badge-sm badge-danger badge-up badge-glow">{{ messages.length}}</span>
        </a>
        <ul v-if="messages.length > 0" class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <div class="arrow_box_right">
                <li class="dropdown-menu-header">
                    <h6 class="dropdown-header m-0"><span class="grey darken-2">{{ trans("notification.notifications") }}</span></h6>
                </li>
                <li class="scrollable-container media-list w-100 ps">
                    <div class="notifications-wrapper">
                    <a class="" v-for="message in messages" :key="message.id"
                       @click="setRead(message.id, message.links.self)">
                        <div class="media">
                            <div class="media-left align-self-center">
                                <i class="fa fa-envelope danger font-medium-4"></i>
                            </div>
                            <div class="media-body">
                                <h6 class="media-heading danger">{{message.attributes.params.title}}</h6>
                                <p class="notification-text font-small-3 text-muted text-bold-600">
                                    {{message.attributes.text}}
                                </p>
                                <small>
                                    <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">3:30 PM
                                    </time>
                                </small>
                            </div>
                        </div>
                    </a>
                    </div>
                </li>
                <li class="dropdown-menu-footer notification-footer">
                    <a href="#" class=" dropdown-item danger text-right pr-1 notification-read-all"
                       @click="setReadAll()">
                        {{ trans("codersstudio/notifications.notification.read_all") }}
                    </a>
                </li>
            </div>
        </ul>
        <ul v-else class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <div class="arrow_box_right">
                <li class="dropdown-menu-header" style="border-bottom: 0;">
                    <h6 class="dropdown-header m-0">
                        <span class="grey darken-2">{{ trans("notification.notifications_empty") }}</span>
                    </h6>
                </li>
            </div>
        </ul>
    </li>
</template>
<script>

export default {
  name: 'csnotifications',
  props : [
    'id'
  ],
  components: {

  },
  data: function() {
    return {
      messages: [],
    };
  },
  updated: function () {

  },
  mounted: function() {
      this.getData();

      Echo.private('App.User.' + this.id)
      .notification((notification) => {
          this.getData();

          //Добавляем сюда уведомление и прочее что нужно

      });
  },
  methods: {
    getData: function(){
      axios.get("/users/"+this.id+"/notifications")
      .then((response) => {
        this.messages = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setReadAll: function(){

    },
    setRead: function(messageId, link){
      axios.patch("/users/"+this.id+"/notifications/"+messageId)
      .then((response) => {
         document.location.href = link;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    setReadAll: function(){
	    axios.patch("/users/"+this.id+"/notifications/read-all")
	    .then((response) => {
				this.getData();
	    }).catch(function (error) {
	      console.log(error);
	    });
	  }

  },
};
</script>

<style lang="scss">
  @import './../../css/notifications.css';
</style>
