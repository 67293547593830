<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <multiselect
            :id="id"
            v-bind:value="value"
            v-on:input="(val) => {$emit('input', val)}"
            :placeholder="placeholder"
            class="form-control multiselect-field"
            :class="{'validated-error': errors.has(name)}"
            :disabled="disabled"
            :readonly="readonly"

            :options="options"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :label="displayColumn"
            track-by="id"
            :internalSearch="true"
            :show-labels="true"
            deselectLabel= "x"
            selectLabel= ""
            selectedLabel=""
            data-placement="top"
        ></multiselect>
        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";
    import Multiselect from 'vue-multiselect';

    export default {
        name: "MultiselectField",
        components: {
            Multiselect
        },
        mixins: [BaseField],
        props: {
            options: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            displayColumn: {
                type: String,
                default: "name"
            }
        }
    }
</script>

<style scoped>
    .multiselect-field {
        padding: 0;
    }
</style>
