<template>
    <div class="col-12">
        <vue-slider v-model="time" :data="getTimefunction (time_start, time_end)" tooltip="none" :process="process"
                    :fixed="true" class="pt-lg-2">
            <template v-slot:process="{ start, end, style, index }">
                <div class="vue-slider-process" :style="style">
                    <div :class="[
              'merge-tooltip',
              'vue-slider-dot-tooltip-inner',
              'vue-slider-dot-tooltip-inner-top',
            ]">
                        {{ time[index] }} - {{ time[index + 1] }}
                    </div>
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>

    import VueSlider from 'vue-slider-component';
    import moment from "moment";
    import 'vue-slider-component/theme/antd.css';

    export default {
        name: "time-range-slider",
        components: {
            VueSlider,
        },
        watch: {
            time: {
                handler(val) {
                    this.$emit("input", {
                        pickup_time_from: val[0],
                        pickup_time_to: val[1]
                    });
                },
                deep: true
            }
        },
        data: function () {
            return {
                time: [this.time_start, moment(this.time_start, "HH:mm").add(4, 'hours').format('HH:mm')],
                process: val => [
                    [val[0], val[1]]
                ],
            };
        },
        props: {
            time_start: {
                default: '8:00'
            },
            time_end: {
                default: '19:00'
            },
        },
        computed: {},
        mounted() {

        },
        methods: {
            getTimefunction(timeStart, timeEnd) {
                let timeStop = '00:00';
                var time_from = moment(timeStart, "HH:mm:ss").format("HH:mm");
                var time_to = moment(timeEnd, "HH:mm:ss").format("HH:mm");
                var time_stop = moment(timeStop, "HH:mm:ss").format("HH:mm");
                var diff = moment(time_to, "HH:mm").diff(moment(time_from, "HH:mm"));
                var duration = moment.duration(diff);
                let arr = [];
                for (var i = 0; i < (duration.hours() * 2); i++) {
                    arr.push(moment(time_from, "HH:mm").add(30 * i, 'minutes').format('HH:mm'));
                }
                return arr;
            },
        }
    };

</script>
<style scoped>

</style>
<style scoped>
    .merge-tooltip {
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, -15px);
    }
</style>
