import * as moment from 'moment';
import AddressAutocomplete from "../../addressAutocomplete/Index";

Vue.component('order-show', {
    components: {AddressAutocomplete},
    data() {
        return {
            form: this.data,
            loading: false,
            mediaCollections: {},
            link: null,
            tracking_point: {},
            synchronized: this.data.shipment_status_id !== 1 ? '(' + this.trans('crud.order.synchronized').toUpperCase() + ')' : "",
            rewardComplete: true,
            minIncVal: 300,
            rewardIncreaseValue: 0,
        }
    },
    props: {
        data: {
            default: () => {
                return {}
            }
        },
        tracking_statuses: {
            default: () => {
                return {}
            }
        },
        courier_service_tsm_id: {
            default: () => {
                return {}
            }
        }
    },
    mounted() {
        this.rewardIncreaseValue = this.minIncVal;
    },
    watch: {
        tracking_point: function (newTrackingPoint, oldTrackingPoint) {
        },
        rewardIncreaseValue: function (val) {
            if (val >= this.minIncVal) {
                this.rewardComplete = true;
            } else {
                this.rewardComplete = false;
            }
        }
    },
    computed: {
        dateTime: function () {
            return moment(this.tracking_point.date,"YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
        },
        latestTariff() {
            return this.data.tariffs[this.data.tariffs.length - 1] ? this.data.tariffs[this.data.tariffs.length - 1] : null;
        },
        parcelType(){
            return this.trans('crud.documents.' + this.data.order_type.name);
        },
        documentType(){
            return this.trans('app.calculator.' + this.data.documents.type);
        }
    },
    methods: {
        saveRewardIncrease() {
            axios.post(this.data.id+'/reward',{'value' : this.rewardIncreaseValue}).then(response => {
                this.systemMessage('success', {
                    'title': this.trans('crud.actions.info'),
                    'text': this.trans('crud.actions.success.info')
                });
                window.location.href = window.location.href;
            });
        },
        add_note() {
            return this.tracking_point = {};
        },
        async createTrackingPoint() {
            try {
                if (this.loading) {
                    return;
                }
                this.errors.clear();
                this.loading = true;
                this.link = window.location.pathname;
                if (!_.get(_.last(this.data.tracking_numbers), 'id', '')) {
                    try {
                        let tracking_number = {
                            order_id: this.data.id,
                            courier_service_id: this.courier_service_tsm_id,
                            tracking_number: this.courier_service_tsm_id + this.data.id + this.tracking_point.date,
                        };
                        let result_number = await axios.post('/admin/tracking_numbers', tracking_number);
                        this.data.tracking_numbers.push(result_number.data.data);
                    } catch(e) {
                        console.log(e); // TypeError: failed to fetch
                    }
                }
                try {
                    this.link = window.location.pathname;
                    let tracking_point = {
                        tracking_number_id: _.get(_.last(this.data.tracking_numbers), 'id', ''),
                        tracking_status_id: this.tracking_point.tracking_status_id,
                        date: this.tracking_point.date,
                        city: this.tracking_point.city,
                        description: this.tracking_point.description
                    };
                    await axios.post('/admin/tracking_points', tracking_point);
                    window.location.href = this.link;
                    this.systemMessage('success', {
                        'title': this.trans('crud.actions.info'),
                        'text': this.trans('crud.actions.success.create')
                    });
                } catch (error) {
                    this.systemMessage('error', {
                        'title': this.trans('crud.actions.warning'),
                        'text': this.trans('crud.actions.fail.create')
                    });
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    })
                }
                this.loading = false;
            } catch(e) {
                console.log(e); // TypeError: failed to fetch
            }
        },
        editTrackingPoint(id) {
            let tracking_points = this.data.tracking_numbers[this.data.tracking_numbers.length - 1].tracking_points;
            this.tracking_point = _.find(tracking_points, {'id': id});
            this.tracking_point.date =  moment(this.tracking_point.date).format("YYYY-MM-DD") + 'T' + this.getTime(this.tracking_point.date);
        },
        updateTrackingPoint(id) {
            if (this.loading) {
                return;
            }
            this.errors.clear();
            this.link = window.location.pathname;
            this.loading = true;
            axios.put('/admin/tracking_points/' + id, this.tracking_point)
                .then(response => {
                    window.location.href = this.link;
                    this.systemMessage('success',{
                        'title':this.trans('crud.actions.info'),
                        'text':this.trans('crud.actions.success.edit')
                    });
                })
                .catch(error => {
                    this.systemMessage('error',{
                        'title':this.trans('crud.actions.warning'),
                        'text':this.trans('crud.actions.fail.info')
                    });
                    _.forEach(error.response.data.errors, (item, index) => {
                        this.errors.add({
                            field: index,
                            msg: _.head(item)
                        });
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        destroyTrackingPoint(id) {
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.message'),
                {
                    okTitle: this.trans('crud.confirmation.yes'),
                    cancelTitle: this.trans('crud.confirmation.cancel'),
                })
                .then(value => {
                    if (value) {
                        this.link = window.location.pathname;
                        axios.delete('/admin/tracking_points/' + id)
                            .then(response => {
                                this.systemMessage('success', {
                                    'title': this.trans('crud.actions.info'),
                                    'text': this.trans('crud.actions.success.delete')
                                });
                                window.location.href = this.link
                            })
                            .catch(error => {
                                this.systemMessage('error', {
                                    'title': this.trans('crud.actions.warning'),
                                    'text': this.trans('crud.actions.fail.delete')
                                });
                            });
                    }
                })
                .catch(err => {

                });
        },
        destroy(id) {
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.message'),
                {
                    okTitle: this.trans('crud.confirmation.yes'),
                    cancelTitle: this.trans('crud.confirmation.cancel'),
                })
                .then(value => {
                    if (value) {
                        axios.delete('/admin/orders/' + id)
                            .then(response => {
                                this.systemMessage('success', {
                                    'title': this.trans('crud.actions.info'),
                                    'text': this.trans('crud.actions.success.delete')
                                });
                                window.location.href = '/admin/orders/'
                            })
                            .catch(error => {
                                this.systemMessage('error', {
                                    'title': this.trans('crud.actions.warning'),
                                    'text': this.trans('crud.actions.fail.delete')
                                });
                            });
                    }
                })
                .catch(err => {

                });
        },
        clearErrors(id) {
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.message'),
                {
                    okTitle: this.trans('crud.confirmation.yes'),
                    cancelTitle: this.trans('crud.confirmation.cancel'),
                })
                .then(value => {
                    if (value) {
                        axios.post('/admin/orders/' + id + '/clear_errors')
                            .then(response => {
                                this.systemMessage('success', {
                                    'title': this.trans('crud.actions.info'),
                                    'text': this.trans('crud.actions.success.delete')
                                });
                                window.location.reload();
                            })
                            .catch(error => {
                                this.systemMessage('error', {
                                    'title': this.trans('crud.actions.warning'),
                                    'text': this.trans('crud.actions.fail.delete')
                                });
                            });
                    }
                })
                .catch(err => {

                });
        },
        printInvoice(order) {
            window.location.replace('/admin/orders/' + order + '/invoice');
        },
        getTime(data) {
            return moment(data).format("HH:mm");
        },
        getDate(data) {
            return moment(data).format("DD.MM.YYYY");
        }
    }
});
