<template>
    <div class="card-body">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-10 col-sm-8 col-xl-12 col-custom1920-8 col-custom2200-7">
                <div class="row mb-3 mt-5 mx-auto col-xl-12">
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 px-12 mb-1">
                        <button class="btn btn-outline-info btn-block" v-bind:class="{'active':(orderType==='speed')}"
                                @click="orderType='speed'">
                            {{ trans('calculator.sort_by_speed') }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 px-12 mb-1">
                        <button class="btn btn-outline-info btn-block" v-bind:class="{'active':(orderType==='price')}"
                                @click="orderType='price'">
                            {{ trans('calculator.sort_by_price') }}
                        </button>
                    </div>
                    <div class="row align-items-center mx-auto col-12">
                        <div class="col-xl-3 mb-1">
                            <div class="datepicker-block">
                                <div class="small text-muted datepicker-title">
                                    {{ trans('calculator.datetime') }}
                                </div>
                                <div class="input-group">
                                    <div
                                        @click="showPicker"
                                        class="form-control datepicker-input"
                                        v-show="!dateTime"> {{ trans('calculator.today') }}
                                    </div>
                                    <date-picker v-show="dateTime" v-model="dateTime"
                                                 :config="dateTimeConfig"
                                                 class="form-control datepicker-input"
                                                 ref="datepicker"
                                                 @input="selectNewDate"
                                    ></date-picker>
                                    <div class="input-group-prepend datepicker-icon">
                                        <i class="fas fa-calendar"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 text-center mb-1">
                            <div class="small text-muted">
                                {{ trans('calculator.sender.city') }}
                            </div>
                            <h4 v-if="cityFrom" class="font-weight-bold">
                                {{ cityFrom.city_name }}
                            </h4>
                        </div>
                        <div class="col-xl-3 text-center mb-1">
                            <!-- <i class="la la-archive"></i>-->
                            <div class="small text-muted" v-if="cargoes && cargoes.type === 'documents'">
                                {{ trans('calculator.documents') }}
                            </div>
                            <div class="small text-muted" v-else>
                                {{ trans('calculator.cargo') }}
                            </div>
                            <div>
                                {{ trans('calculator.weight') }}: {{ getWeight() }} кг
                            </div>
                        </div>
                        <div class="col-xl-3 text-center mb-1">
                            <div class="small text-muted">
                                {{ trans('calculator.recipient.city') }}
                            </div>
                            <h4 v-if="cityTo" class="font-weight-bold">
                                {{ cityTo.city_name }}
                            </h4>
                        </div>
                    </div>

                </div>
                <!--                     Tariffs -->
                <template v-for="tariffElement in tariffsWithLimit">
                    <tariff-card :tariff-element="tariffElement"></tariff-card>
                </template>
                <!-- Tariffs -->
                <div v-if="showLimit < tariffsList.length" class="mt-3 text-center">
                    <a class="btn btn-info" @click="showMore()">
                        {{ trans('app.labels.more') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TariffCard from "./partials/TariffCard";
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from "moment";

const ITEMS_ON_PAGE = 10;
export default {
    name: "SelectTariff",
    components: {
        TariffCard,
        datePicker
    },
    data: function () {
        return {
            dateTime: '',
            dateTimeConfig: {
                format: 'dddd DD/MM/YY',
                daysOfWeekDisabled: [],
                locale: 'ru',
                minDate: moment()
            },
            value: [],
            loads: [],
            validateErrors: {},
            tariffsList: [],
            fullTariffsList: [],
            showLimit: ITEMS_ON_PAGE,
            tariffsWithLimit: [],
            orderType: "speed" //price speed
        }
    },
    props: {
        courierServices: {
            default: () => []
        }
    },
    watch: {
        value(val) { //Для v-model в родитель
            this.$emit('input', val);
        },
        step(newValue, oldValue) { // проверка шага
            if (newValue === 2 && oldValue !== 3) {
                this.getTariffs();
            } else if (newValue === 1) {
                this.value = [];
                this.loads = [];
                this.validateErrors = {};
                this.tariffsList = [];
                this.showLimit = ITEMS_ON_PAGE;
                this.tariffsWithLimit = [];
                this.orderType = "speed";
            }
        },
        showLimit(val) {
            this.getTariffsWithShowLimit(val);
        },
        orderType(val) {
            this.orderTariffs(val);
            this.getTariffsWithShowLimit(this.showLimit);
        },
        dateTime(val) {
            console.log('val', val);
            this.orderTariffs('speed', val);
            this.getTariffsWithShowLimit(this.showLimit);
        }
    },
    mounted: function () {
        //this.getTariffs();
    },
    methods: {
        selectNewDate: function () {
            this.orderTariffs();
        },
        showPicker: function () {
            this.dateTime = moment();
            this.$refs.datepicker.dp.show();
        },
        parseRelativeDate: function (relativeDate) {
            switch (relativeDate) {
                case 'today':
                    return moment()
                case 'yesterday':
                    return moment().subtract(1, 'day');
                default:
                    return moment()
                        .subtract(Number(relativeDate.replace("days ago", "").trim()), 'days');
            }
        },
        addWeekdays: function (date, days) {
            date = moment(date); // use a clone
            while (days > 0) {
                date = date.add(1, 'days');
                // decrease "days" only if it's a weekday.
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
            return date;
        },
        orderTariffs: function (type = 'speed', newDate = '') {
            moment.locale('ru');
            let date = (newDate) ? moment(newDate, 'dddd DD/MM/YY', 'ru') : moment(this.dateTime, 'dddd DD/MM/YY', 'ru'),
                result = _.map(this.fullTariffsList, tariff => {
                    let isOk = !_.isEmpty(_.find(tariff.attributes.availableDays, day => {
                        return day.dayOfWeek === date.day();
                    }));
                    let tariffObj = JSON.parse(JSON.stringify(tariff));
                    tariffObj.attributes.pickup_date = date.format('YYYY-MM-DD');
                    if(_.isEmpty(this.dateTime)) tariffObj.attributes.pickup_date = moment(tariffObj.attributes.closest_date).format('YYYY-MM-DD');

                    if (isOk) {
                        let text = (date === moment()) ? this.trans('calculator.today') : newDate;
                        tariffObj.attributes.pickup_day_definition = text;
                        let businessDays = tariffObj.attributes.days_max;

                        if (businessDays > 0) {
                            tariffObj.attributes.delivery_day_definition = this.addWeekdays(date, businessDays).format('dddd DD/MM/YY');
                        }
                    }

                    return tariffObj;
                });

            if (type === "price") {
                result = _.sortBy(result, o => parseFloat(o.attributes.price));
            } else if (type === "speed") {
                result = _.sortBy(result, o => {
                    let ordering = parseInt(o.attributes.days_max);
                    if (ordering < 0) {
                        if (o.attributes.delivery_time_definition.length) {
                            ordering = ordering + ordering / o.attributes.delivery_time_definition[1];
                        }
                    } else {
                        if (o.attributes.delivery_time_definition.length) {
                            ordering = ordering - ordering / o.attributes.delivery_time_definition[1];
                        }
                    }
                    return ordering;
                });
            }

            this.tariffsList = result;
        },
        showMore: function () {
            this.showLimit += ITEMS_ON_PAGE;
        },
        getWeight: function () {
            let totalWeight = 0;
            if (this.cargoes.packages) {
                this.cargoes.packages.forEach((element) => {
                    if (element.weight) totalWeight += element.weight;
                });
            }
            return totalWeight;
        },
        getTariffsWithShowLimit: function (limitParam) {
            let result = [];
            let tariffsList = this.tariffsList;
            let size = tariffsList.length;
            let limit = limitParam;
            if (limit >= size) limit = size;
            for (let i = 0; i < limit; i++) {
                result[i] = tariffsList[i];
            }
            this.tariffsWithLimit = result;
        },
        getTariffs: function () { //Перебор всех служб и получение тарифов
            this.tariffs = [];
            this.tariffsList = [];
            this.fullTariffsList = [];
            this.courierServices.map((value, key) => {
                this.getTariff(value.slug);
            });
        },
        getTariff: function (companyName) {
            this.isLoading = true;
            this.loads.push(companyName);
            axios.get(
                '/courierservice/' + companyName + '/tariff?' + this.getRequestData()
            ).then(response => {
                // handle success
                response.data.data.map(element => {
                    this.tariffsList.push(element);
                    this.fullTariffsList.push(element);
                });
                this.orderTariffs(this.orderType);
                this.$store.dispatch('calculator/setTariffs', this.tariffsList);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loads.pop();
                this.isLoading = false;
                this.getTariffsWithShowLimit(this.showLimit);

                this.tariffsIsLoading = _.size(this.loads) !== 0;
            });
        },
        apiParamsSerializer: function (obj) {
            let qs = require('qs');
            let result = qs.stringify(obj, {encodeValuesOnly: true});
            return result;
        },
        getRequestData: function () {
            let packages = this.cargoes.packages;
            let data = {
                'from': {
                    'country_name': this.cityFrom.country_name,
                    'country_code': this.cityFrom.country_code,
                    'region_name': this.cityFrom.region_name,
                    'city_name': this.cityFrom.city_name,
                    'city_name_with_type': this.cityFrom.city_name_with_type,
                    'city_fias_id': this.cityFrom.city_fias_id, //Москва
                },
                'to': {
                    'country_name': this.cityTo.country_name,
                    'country_code': this.cityTo.country_code,
                    'region_name': this.cityTo.region_name,
                    'city_name': this.cityTo.city_name,
                    'city_name_with_type': this.cityTo.city_name_with_type,
                    'city_fias_id': this.cityTo.city_fias_id, //Москва
                },
                'package_type': this.cargoes.type,
                'with_return': this.cargoes.with_return,
                'personal_delivery': this.cargoes.personal_delivery,
            };

            if (this.cityFrom.postal_code) data.from.postal_code = this.cityFrom.postal_code;
            if (this.cityTo.postal_code) data.to.postal_code = this.cityTo.postal_code;

            /* packages.forEach(function (element) {
                 delete element.description;
             });*/
            data.cargoes = packages;
            return this.apiParamsSerializer(data);
        },
        validate: function () { //Вызывваем из родительского компонента перед отправкой данных
            let errors = {};
        },
    },
    computed: {
        step: {
            // Текущая страница калькулятора может
            // 0 - это заглавная форма,
            // 1- выбор типа отправления,
            // 2 - выбор тарифа,
            // 3 - Оформление заявки
            get: function () {
                return this.$store.getters['calculator/getStep'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setStep', newValue);
            }
        },
        cityFrom: {
            get: function () {
                return this.$store.getters['calculator/getCityFrom'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityFrom', newValue);
                this.checkFirstStep();
            }
        },
        cityTo: {
            get: function () {
                return this.$store.getters['calculator/getCityTo'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityTo', newValue);
                this.checkFirstStep();
            }
        },
        cargoes: {
            get: function () {
                return this.$store.getters['calculator/getCargoes'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCargoes', newValue);
            }
        },
        tariff: {
            get: function () {
                return this.$store.getters['calculator/getTariff'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setTariff', newValue);
            }
        },
        isLoading: {
            get: function () {
                return this.$store.getters['spinner/getIsLoading'];
            },
            set: function (newValue) {
                this.$store.dispatch('spinner/setIsLoading', newValue);
            }
        },
        tariffsIsLoading: {
            get: function () {
                return this.$store.getters['calculator/getTariffsIsLoading'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setTariffsIsLoading', newValue);
            }
        }
    }
}
</script>

<style scoped>
.datepicker-title {
    position: relative;
    left: 20px;
    top: 3px;
}

.datepicker-block {
    border: 1px solid;
    border-radius: 5px;
    margin-left: 3px;
}

.datepicker-block:focus {
    border-color: #6967ce;
}

.datepicker-icon button {
    border: 0px;
}

.datepicker-icon i {
    font-size: 27px;
    position: absolute;
    top: -1px;
    right: 10px;
    color: #6967ce;
    z-index: 1;
}

.input-group-prepend {
    margin-right: 5px;
}

.datepicker-input {
    border: 0px;
    min-width: 200px;
    margin: 1px !important;
}
</style>
