import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'
import CityTimeRestrictionsMixin from './cityTimeRestricrionsMixin.js'

Vue.component('tariff-create', {
    data() {
        return {
            link: '/admin/tariffs',
            weekdays: {
                1 : {
                    open_time: '',
                    close_time: ''
                },
                2 : {
                    open_time: '',
                    close_time: ''
                },
                3 : {
                    open_time: '',
                    close_time: ''
                },
                4 : {
                    open_time: '',
                    close_time: ''
                },
                5 : {
                    open_time: '',
                    close_time: ''
                },
                6 : {
                    open_time: '',
                    close_time: ''
                },
                7 : {
                    open_time: '',
                    close_time: ''
                },
            }
        }
    },
    mixins: [MixinsCreate, CityTimeRestrictionsMixin],
    methods: {
        preStore() {
            this.form.weekdays = this.weekdays
        }
    }
});
