require('../../../components/admin/entity_profiles/create.js');
require('../../../components/admin/entity_profiles/edit.js');
require('../../../components/admin/entity_profiles/index.js');
require('../../../components/admin/order_logs/index.js');

require('../../../components/admin/country/create.js');
require('../../../components/admin/country/edit.js');
require('../../../components/admin/country/index.js');

require('../../../components/admin/city/create.js');
require('../../../components/admin/city/edit.js');
require('../../../components/admin/city/index.js');

require('../../../components/admin/setting/create.js');
require('../../../components/admin/setting/edit.js');
require('../../../components/admin/setting/index.js');

require('../../../components/admin/errorlog/create.js');
require('../../../components/admin/errorlog/edit.js');
require('../../../components/admin/errorlog/index.js');

require('../../../components/admin/report/index.js');

require('../../../components/admin/tariff/create.js');
require('../../../components/admin/tariff/edit.js');
require('../../../components/admin/tariff/index.js');

require('../../../components/admin/order/create.js');
require('../../../components/admin/order/edit.js');
require('../../../components/admin/order/index.js');
require('../../../components/admin/order/show.js');

require('../../../components/admin/address/create.js');
require('../../../components/admin/address/edit.js');
require('../../../components/admin/address/index.js');

require('../../../components/admin/courier_service/create.js');
require('../../../components/admin/courier_service/edit.js');
require('../../../components/admin/courier_service/index.js');

require('../../../components/admin/shipment_status/create.js');
require('../../../components/admin/shipment_status/edit.js');
require('../../../components/admin/shipment_status/index.js');

require('../../../components/admin/cargo_type/create.js');
require('../../../components/admin/cargo_type/edit.js');
require('../../../components/admin/cargo_type/index.js');

require('../../../components/admin/user/create.js');
require('../../../components/admin/user/edit.js');
require('../../../components/admin/user/index.js');
require('../../../components/admin/user/show.js');

require('../../../components/register/Index.vue');

import BootstrapVue from 'bootstrap-vue';
import lodash from 'lodash';
import '@fortawesome/fontawesome-free/js/fontawesome';
import Notifications from 'vue-notification';
import VeeValidate from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { VueEditor } from "vue2-editor/dist/vue2-editor.common";
import VueTelInput from 'vue-tel-input';

Vue.component('order-mail', require('../../../components/admin/order/components/OrderMail.vue').default);

Object.defineProperty(Vue.prototype, '_', { value: lodash });
Vue.component('file-upload', require('./components/FileUpload.vue').default);
Vue.component('import', require('./components/Import.vue').default);
Vue.component('vue-dropzone', vue2Dropzone);
Object.defineProperty(Vue.prototype, '_', { value: lodash });
require('./index');
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(VueTelInput);
Vue.use(VeeValidate, {
    // errorBagName: 'vErrors'
});
Vue.use(flatPickr);
VueEditor.methods.emitImageInfo = function emitImageInfo($event) {
    var resetUploader = function resetUploader() {
        var uploader = document.getElementById("file-upload");
        uploader.value = "";
    };

    var file = $event.target.files[0];
    var Editor = this.quill;
    var range = Editor.getSelection();
    var cursorLocation = range.index;
    this.$emit("imageadded", file, Editor, cursorLocation, resetUploader);
};
Vue.component('vue-editor', VueEditor);
// Form dostavista
Vue.component('card-dostavista', require('../../../components/dostavista/Index.vue').default);
Vue.component('address-dostavista', require('../../../components/dostavista/components/AddressDostavista.vue').default);
Vue.component('where-dostavista', require('../../../components/dostavista/components/WhereDostavistaCard.vue').default);
// Form fields
Vue.component('phone-field', require('../../../components/fields/PhoneField.vue').default);
Vue.component('text-field', require('../../../components/fields/TextField.vue').default);
Vue.component('date-field', require('../../../components/fields/DateField.vue').default);
Vue.component('city-field', require('../../../components/fields/CityField.vue').default);
Vue.component('address-field', require('../../../components/fields/AddressField.vue').default);
Vue.component('select-field', require('../../../components/fields/SelectField.vue').default);
Vue.component('multiselect-field', require('../../../components/fields/MultiselectField.vue').default);

Vue.component('poll', require('../../../components/fields/Poll.vue').default);
Vue.component('multiselect-vue', require('../../../components/fields/Multiselect.vue').default);
Vue.component('gmap-courier-tracking', require('../../../components/dostavista/components/GmapCourierTracking.vue').default);
const mixin = {
    methods: {
        trans: function (key) {
            key = key.replace(/::/, '.');
            return _.get(window.trans, key, key);
        },
        systemMessage(type = 'success', options = {}) {
            options = Object.assign(
                {
                    group: 'system',
                    type: type,
                    title: type == 'success' ? this.trans('crud.success') : this.trans('crud.error'),
                    text: type == 'success' ? this.trans('crud.action_completed') : this.trans('crud.action_failed')
                },
                options
            );
            this.$notify(options);
        },
        hasRole(name) {
            let result;
            let user = this.$store.state.user.user;

            if (user && user.roles) {
                result = _.find(user.roles, function (o) {
                    return o.name === name
                });
                return !_.isUndefined(result);
            } else {
                return false;
            }
        },
    },
};
Vue.mixin(mixin);
