import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('setting-index', {
    data() {
        return {
            link: '/admin/settings',
        }
    },
    methods: {
        booleanToText(value = null) {
            if (value == 0) {
                return this.trans('app.false')
            }
            else if (value == 1) {
                return this.trans('app.true')
            }
        }
    },
    mixins: [MixinsIndex]
});
