<template>
    <div class="">
        <div class="card-header card-header-mobile text-bold-900">
            <div class="card-title d-lg-none">{{ index }} {{ where }}</div>
            <div class="heading-elements cursor-pointer" v-if="index>=3">
                <i class="ft-x" @click="selfDestruct"></i>
            </div>
        </div>
        <div class="card-body px-1 pt-lg-0">
            <address-dostavista :prefix="prefix"
                                v-model="address"
                                :index="parseInt(index)-1"></address-dostavista>
            <div class="row">
                <div class="col-lg-6">
                    <text-field
                        :required="true"
                        v-model="last_name"
                        :name="prefix+'last_name'"
                        :label="trans('app.contacts.columns.last_name')"
                        :placeholder="trans('app.contacts.columns.last_name')"
                    ></text-field>
                </div>
                <div class="col-lg-6">
                    <text-field
                        :required="true"
                        v-model="first_name"
                        :name="prefix+'first_name'"
                        :label="trans('app.contacts.columns.first_name')"
                        :placeholder="trans('app.contacts.columns.first_name')"
                    ></text-field>
                </div>
            </div>
            <div>
                <phone-field :name="prefix+'phone'" :required="true"
                             :placeholder="trans('calculator.dostavista.phone')" v-on:input="(payload) => { this.setPhone(payload)}"></phone-field>
            </div>
            <div class="px-1" v-if="index == 1">
                <pickup-date-time :discount="discount" v-on:input="(payload) => { this.setDateTime(payload)}"></pickup-date-time>
            </div>
            <textarea
                rows="5"
                class="form-control"
                name="comments"
                :placeholder="trans('calculator.dostavista.extra_data')"
                title
                v-model="comment"
            ></textarea>
        </div>
    </div>
</template>

<script>
    import PickupDateTime from "./PickupDateTime";
    import AddressDostavista from "./AddressDostavista";

    export default {
        name: "where-dostavista",
        inject: ['$validator'], // inherit validation scope from parent
        components: {
            PickupDateTime,
            AddressDostavista
        },
        mounted() {
            // this.initTimes();
            // this.initConfig();
            this.setComment(' ');
            if(!_.isEmpty(this.addressString.address)) this.address = {
                id:1,
                name: val.address,
                label: this.addressString.address,
                name_with_type: this.addressString.address
            };//this.addressString.address;
        },
        data: function () {
            return {
                address: '',
                addressObject : {
                    address : {
                        name:''
                    },
                    phone: '',
                    dateTime: {},
                    comment: '',
                    first_name: '',
                    last_name: '',
                },
                comment:'',
                first_name: '',
                last_name: '',
            };
        },
        props: {
            addressString: {
                default: () => {
                    return {};
                }
            },
            geocoder: {
                default:null
            },
            placeholder: {
                default: ""
            },
            value: {
                default: ""
            },
            index: "",
            where: '',
            discount: "",
            prefix:{
                default: ''
            },
        },
        watch: {
            address(val){
                this.addressObject.address = val;
            },
            addressString: {
                handler: function(val) {
                    this.address = {
                        id:1,
                        name: val.address,
                        label: val.address,
                        name_with_type: val.address
                    };//val.address;
                },
                deep: true
            },
            addressObject: {
                handler: function(val) {
                    let ao = val;
                    if (!this.addressString.position) {
                       // Vue.set(this.addressString, 'address', ao.address.name_with_type);
                        console.log('AO',ao.address.name, ao.address);
                        if (this.geocoder && ao.address.name) {
                            this.geocoder.geocode({'address': ao.address.name}, (results, status) => {
                                if (status === 'OK') {
                                    if (results[0]) {
                                        let position = {
                                            'lat': results[0]['geometry']['location'].lat(),
                                            'lng': results[0]['geometry']['location'].lng()
                                        };
                                        this.$store.dispatch('calculator/setDostavistaPoint', {
                                            'addressObject': ao,
                                            'index': this.index,
                                            'position': position
                                        });
                                    } else {
                                        console.log('not found');
                                    }
                                } else {
                                    console.log('Geocoder failed due to: ' + status);
                                }

                            });
                        }
                    } else {
                        this.$store.dispatch('calculator/setDostavistaPoint', {
                            'addressObject': ao,
                            'index': this.index,
                            'position': this.addressString.position
                        });
                    }
                },
                deep: true
            },
            comment: function(val) {
                this.setComment(val);
            },
            first_name: function(val) {
                this.setFirstName(val);
            },
            last_name: function(val) {
                this.setLastName(val);
            }

        },
        methods: {
            selfDestruct: function() {
                this.$store.dispatch('calculator/destroyExtraPoint', {'index': this.index});
            },
            setPhone: function(payload) {
                this.$store.dispatch('calculator/setDostavistaPointPhone', {
                    'phone': payload,
                    'index': this.index,
                });
            },
            setDateTime: function(payload) {
                this.$store.dispatch('calculator/setDostavistaPointDateTime', {
                    'dateTime': payload,
                    'index': this.index,
                });
            },
            setComment: function(payload) {
                this.$store.dispatch('calculator/setDostavistaPointComment', {
                    'comment': payload,
                    'index': this.index,
                });
            },
            setFirstName: function(payload) {
                this.$store.dispatch('calculator/setDostavistaPointFirstName', {
                    'first_name': payload,
                    'index': this.index,
                });
            },
            setLastName: function(payload) {
                this.$store.dispatch('calculator/setDostavistaPointLastName', {
                    'last_name': payload,
                    'index': this.index,
                });
            },
        }
    };
</script>

<style scoped>
    .multiselect.form-control.multiselect {
        padding: 0;
    }

    @media (max-width: 768px) {
        .card-header-mobile {
            background-color: #82B1FF !important;
            color: #FFFFFF !important;
        }
    }
</style>
