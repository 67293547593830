import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'
import CityTimeRestrictionsMixin from './cityTimeRestricrionsMixin.js'
let moment = require('moment');


Vue.component('tariff-edit', {
    data() {
        return {
            link: '/admin/tariffs',
            weekdays: {
                1 : {
                    open_time: '',
                    close_time: ''
                },
                2 : {
                    open_time: '',
                    close_time: ''
                },
                3 : {
                    open_time: '',
                    close_time: ''
                },
                4 : {
                    open_time: '',
                    close_time: ''
                },
                5 : {
                    open_time: '',
                    close_time: ''
                },
                6 : {
                    open_time: '',
                    close_time: ''
                },
                7 : {
                    open_time: '',
                    close_time: ''
                },
            }
        }
    },
    mixins: [MixinsEdit, CityTimeRestrictionsMixin],
    mounted() {
        var vm = this;
        if (this.form.weekdays) {
            _.forEach(this.form.weekdays, function (val) {
                vm.weekdays[val.id]['open_time'] = val.pivot.open_time;
                vm.weekdays[val.id]['close_time'] = val.pivot.close_time;
            })
        }
        this.form.courier_before = moment(this.form.courier_before, 'HH:mm:ss').format('HH:mm');
        this.form.office_before = moment(this.form.office_before, 'HH:mm:ss').format('HH:mm');
        this.form.order_before = moment(this.form.order_before, 'HH:mm:ss').format('HH:mm');
    },
    methods: {
        preUpdate() {
            this.form.weekdays = this.weekdays
        }
    }
});
