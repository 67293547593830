<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <input
            :id="id"
            :name="name"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
            type="type"
            :placeholder="placeholder"
            class="form-control"
            :class="{'validated-error': errors.has(name)}"
            :disabled="disabled"
            :readonly="readonly"
        />
        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";
    export default {
        name: "TextField",
        mixins: [BaseField],
    }
</script>

<style scoped>

</style>
