import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('order-log-index', {
    props: ['orderId'],
    data() {
        return {
            link: `/admin/orders/${this.orderId}/logs`,
            orderDirection: 'desc',
        }
    },
    mixins: [MixinsIndex]
});
