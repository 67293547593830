import MixinsCreate from '../../../crud/mixins/create.js'

Vue.component('role-create', {
    data() {
        return {
            link: '/admin/roles',
        }
    },
    mixins: [MixinsCreate]
});
