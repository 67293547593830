<template>
    <div class="card tariff__card">
        <div class="card-body">
            <div class="row">
                <div v-if="tariffElement.attributes.pickup_day_definition" class="mx-xs-auto col-12 col-xl-2 text-center mb-1 col-lg-4 mx-xl-auto">
                     <h5 class="font-weight-bold">
                        {{ trans('calculator.take_away') }}
                    </h5>
                    <div class="text2">
                        <div>
                            {{ tariffElement.attributes.pickup_day_definition }}
                        </div>
                        <div>
                            {{ tariffElement.attributes.pickup_time_definition }}
                        </div>
                    </div>
                </div>
                <div class="mx-xs-auto col-12 col-xl-2 text-center mb-1 col-lg-4 mx-xl-auto">
                    <h5 class="font-weight-bold">
                        {{ trans('calculator.on_my_way') }}
                    </h5>
                    <div class="text2">
                        <template
                            v-if="tariffElement.attributes.days_max > 1 && tariffElement.attributes.days_max > tariffElement.attributes.days_min">
                            {{ tariffElement.attributes.days_min }}-{{ tariffElement.attributes.days_max }}
                            <template
                                v-if="tariffElement.attributes.days_max > 1 && tariffElement.attributes.days_max < 5">
                                {{ trans('calculator.of_day') }}
                            </template>
                            <template v-else>
                                {{ trans('calculator.days') }}:
                            </template>
                        </template>
                        <template v-else-if="tariffElement.attributes.days_min === 0">
                            1 {{ trans('calculator.day') }}
                        </template>
                        <template v-else-if="tariffElement.attributes.days_min < 0">
                            {{ trans('calculator.day_not_set') }} <br>
                            <a href="tel:+74950218699">{{ trans('calculator.day_not_set_phone') }}</a>
                        </template>
                        <template v-else-if="tariffElement.attributes.days_min === tariffElement.attributes.days_max">
                            {{ tariffElement.attributes.days_min }}
                            <template v-if="tariffElement.attributes.days_min === 1">
                                {{ trans('calculator.day') }}
                            </template>
                            <template v-else-if="tariffElement.attributes.days_min > 1 && tariffElement.attributes.days_min < 5">
                                {{ trans('calculator.of_day') }}
                            </template>
                            <template v-else>
                                {{ trans('calculator.days') }}
                            </template>
                        </template>
                    </div>
                </div>
                <div v-if="tariffElement.attributes.delivery_day_definition" class="mx-xs-auto col-12 col-xl-2 text-center mb-1 col-lg-4 mx-xl-auto">
                    <h5 class="font-weight-bold">
                        {{ trans('calculator.will_deliver') }}
                    </h5>
                    <div class="text2">
                        <div>
                           {{ tariffElement.attributes.delivery_day_definition }}
                        </div>
                        <div class="">
                            <span class="font-weight-bold" v-if="tariffElement.attributes.delivery_time_definition && tariffElement.attributes.delivery_time_definition.length && tariffElement.attributes.days_min > 0">{{ trans('calculator.before') }} {{ tariffElement.attributes.delivery_time_definition[0] }}</span>
                        </div>

                    </div>
                </div>
                <div class="mx-xs-auto col-12 col-xl-2 text-center mb-1 col-lg-4 mx-xl-auto">
                    <h5 class="font-weight-bold">
                        {{ trans('calculator.who_will_do') }}
                    </h5>
                    <div class="text3">
                        <div class="d-flex justify-content-center align-content-center">
                            <img class="img-courier_name"
                                :src="(tariffElement.attributes.courier_name)?'/images/courierservices/'+tariffElement.attributes.courier_name +'.png':''"
                                alt="">
                        </div>
                        <div>
                            {{ trans('calculator.tariff') }} {{ tariffElement.attributes.tariff_name }}<span
                            v-if="tariffElement.attributes.restrict_mkad">{{ trans('calculator.restrict_mkad') }}</span>
                        </div>
                        <div>
                            <div>
                                <span class="">{{ trans('calculator.post_up') }}</span>
                                {{ tariffElement.attributes.order_before }}
                            </div>
                        </div>
                        <div v-if="tariffElement.attributes.comment">
                            <template>
                                <div class="text-center pt-1">
                                    <div>
                                        <i class="fa fa-info-circle"
                                           triggers="focus"
                                           :id="'tooltip-button-' + tariffElement.id">
                                        </i>
                                    </div>
                                    <b-tooltip :show.sync="show"
                                               :target="'tooltip-button-' + tariffElement.id"
                                               placement="top"
                                    >
                                        {{ tariffElement.attributes.comment}}
                                    </b-tooltip>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="mx-xs-auto col-12 col-xl-2 text-center mb-1 col-lg-4">
                    <h5 class="font-weight-bold">
                        {{ trans('calculator.price') }}
                    </h5>
                    <a v-if="tariffElement.attributes.price" class="btn btn-info col-12 col-lg-auto btn-small" @click="selectTariff(tariffElement)">
                          <span v-if="tariffElement.attributes.price == -1">
                              {{ trans('calculator.other_price')}}
                          </span>
                        <span v-else>
                          {{ tariffElement.attributes.price.toFixed(0) }}
                          &#8381;
                          </span>
                    </a>
                    <div class="pt-2" style="display: none">
                        <cashback-modal :id="tariffElement.id"></cashback-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CashbackModal from "./CashbackModal.vue";
    export default {
        name: "TariffCard",
        data: function () {
            return {
                show: false
            };
        },
        components: {
            CashbackModal
        },
        props: {
            tariffElement: {
                default: {
                    id: null,
                    type: null,
                    attributes: {
                        courier_before: null,
                        courier_name: null,
                        days_max: null,
                        days_min: null,
                        office_before: null,
                        order_before: null,
                        price: null,
                        tariff_id: null,
                        tariff_name: null
                    }
                }
            }
        },
        methods: {
            selectTariff: function (tariff) {
                this.tariff = tariff;
                this.step = 3;
            }
        },
        computed: {
            step: {
                // Текущая страница калькулятора может
                // 0 - это заглавная форма,
                // 1- выбор типа отправления,
                // 2 - выбор тарифа,
                // 3 - Оформление заявки
                get: function () {
                    return this.$store.getters['calculator/getStep'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setStep', newValue);
                }
            },
            tariff: {
                get: function () {
                    return this.$store.getters['calculator/getTariff'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setTariff', newValue);
                }
            }
        }
    }
</script>

<style scoped>
    .tooltip{
        opacity: 1 !important;
    }
</style>
