<template>
<div>
    <!-- Button trigger modal -->
    <button type="button" class="btn-xs add_book" data-toggle="modal" :data-target="'#' + addressBookId">
        <i class="fa fa-user "></i>
    </button>
</div>
</template>

<script>
    export default {
        name: "AddressBookButton",
        props: ['addressBookId'],
        data() {
            return {
            };
        },
    }
</script>

<style scoped>

</style>
