import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'
let moment = require('moment');

Vue.component('tariff-index', {
    data() {
        return {
            link: '/admin/tariffs',
        }
    },
    updated() {
        _.forEach(this.data.data, (value, key) => {
            value.courier_before = moment(value.courier_before, 'HH:mm:ss').format('HH:mm');
            value.office_before = moment(value.office_before, 'HH:mm:ss').format('HH:mm');
            value.order_before = moment(value.order_before, 'HH:mm:ss').format('HH:mm');
        });
    },
    mixins: [MixinsIndex]
});
