<template>
    <div class="search-tariffs__col mb-1">
        <div class="input-group">
            <div class="input-group-prepend-country input-group-prepend ">
                <span class="">
                   <multiselect
                       class="multiselect-country form-control"
                       v-model="country"
                       :options="countries"
                       :hide-selected="true"
                       :multiple="false"
                       :close-on-select="true"
                       :clear-on-select="false"
                       :preserve-search="true"
                       placeholder=""
                       open-direction="bottom"
                       label="name"
                       track-by="iso_code"
                       :preselect-first="true"
                       @search-change="getCountries"
                       :internalSearch="false"
                       @select="selectCountry"
                       :show-labels="true"
                       deselectLabel="x"
                       selectLabel=""
                       selectedLabel=""
                       data-trigger="!hover"
                       data-toggle="tooltip"
                       title=""
                       data-placement="top"
                       data-original-title="Если нужной страны нет в списке, начните набирать..."
                   >
                   <template slot="selection" slot-scope="{ values, search, isOpen }" class="input-group">
                    <div class="input-group-prepend-city input-group-prepend">
                        <span class="multiselect__single input-group-text"
                              v-if="values.length && !isOpen">{{ values.length }} options selected</span>
                    </div>
                            </template>
                            <template slot="singleLabel" slot-scope="props">
                               <i :class="'flag-icon flag-icon-' + props.option.iso_code.toLowerCase()"></i>
                               {{ props.option.iso_code }}
                           </template>
                           <template slot="option" slot-scope="props">
                               <div class="option__desc">
                                   <span class="option__title">
                                    <i :class="'flag-icon flag-icon-' + props.option.iso_code.toLowerCase()"></i>
                                    {{ props.option.name }}
                                </span>
                            </div>
                        </template>
                        <template slot="noOptions">
                            {{ trans('calculator.not_found') }}
                        </template>
                        <template slot="noResult">
                           {{ trans('calculator.not_found') }}
                       </template>
                   </multiselect>
               </span>
            </div>

            <multiselect
                class="multiselect-city form-control position-relative input-xl pl-0"
                v-model="city"
                ref="search"
                :options="cities"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                :placeholder="placeholderValue"
                open-direction="bottom"
                label="city_label"
                track-by="id"
                :internalSearch="false"
                :preselect-first="false"
                @search-change="getCities"
                @select="onSelect"
                :show-labels="true"
                deselectLabel="x"
                selectLabel=""
                selectedLabel=""
            >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span>
                </template>
                <template slot="singleLabel" slot-scope="props">
                    {{ props.option.city_name }}
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                   <span class="option__title">
                    {{ props.option.city_label + ", " + props.option.region_name }}
                </span>
                    </div>
                </template>
                <template slot="noOptions">
                    {{ trans('calculator.not_found') }}
                </template>
                <template slot="noResult">
                    {{ trans('calculator.not_found') }}
                </template>
            </multiselect>
            <div class="form-group" v-if="user">
                <address-book-button :address-book-id="addressBookId"></address-book-button>
            </div>
        </div>
        <b-modal v-if="city" :id="this.modal_id" title="" centered hide-footer>
            <template v-slot:modal-title>
                {{ trans('calculator.enter_post_code_city') }} {{ city.city_name }}
            </template>
            <div class="d-block text-center">
                <input type="text" class="form-group input-code-city input-xl col-12 mb-0" v-model="postalCode">
            </div>
            <b-button class="mt-3 btn btn-success btn-lg" block @click="$bvModal.hide(modal_id)">
                {{ trans("crud.buttons.save") }}
            </b-button>
        </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import AddressBookButton from "./AddressBookButton";

export default {
    name: "select-city",
    components: {
        AddressBookButton,
        Multiselect
    },
    props: {
        placeholder: {
            default: ""
        },
        value: {
            default: ""
        },
        defaultCities: {
            default: () => []
        },
        clients: {
            default: () => []
        },
        addressBookId: {
            default: 'addressBook'
        },
        prefix: {
            default: ""
        }
    },
    data: function () {
        return {
            modal_id: _.uniqueId(),
            addressBookChosen: false,
            city: this.value,
            postalCode: null,
            isCheckPostalCode: false,
            country: {
                "name": "RU",
                "iso_code": "RU"
            },
            countries: [
                {
                    "name": "Россия",
                    "iso_code": "RU"
                },
                {
                    "name": "США",
                    "iso_code": "US"
                }
            ], //default values
            cities: this.defaultCities,
            placeholderValue: this.placeholder,
            isLoading: false
        };
    },
    computed: {
        user: {
            // TODO delete after
            get() {
                return this.$store.getters['user/getUser'];
            },
            set(newValue) {
                this.$store.dispatch('user/setUser', newValue);
            }
        },
        addressFrom: {
            get() {
                return this.$store.getters['calculator/getAddressFrom'];
            },
            set(newValue) {
                this.$store.dispatch('calculator/setAddressFrom', newValue);
            }
        },
        cityFrom: {
            get: function () {
                return this.$store.getters['calculator/getCityFrom'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityFrom', newValue);
                this.checkStep(1);
            }
        },
        cityTo: {
            get: function () {
                return this.$store.getters['calculator/getCityTo'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityTo', newValue);
                this.checkStep(1);
            }
        },
        addressBook: {
            get() {
                return this.$store.getters['calculator/getAddressBook'];
            },
            set(newValue) {
                this.$store.dispatch('calculator/setAddressBook', newValue);
            }
        }
    },
    mounted() {
        this.getTopCities();
        this.getTopCountries();
        Vue.nextTick(() => {
            this.city = Object.assign({}, this.value); // important for watcher trigger
        });
        this.componentId = _.uniqueId('address_book_modal');
    },
    watch: {
        value(val) {
            this.city = val;
        },
        addressBook(address) {
            if (this.prefix === address.prefix) {
                this.addressBookChosen = true;
                let city = {
                    'city_name': address.city_name,
                    'country_name': address.country_name,
                    'country_code': address.country_code,
                    'region_name': address.region_name,
                    'postal_code': address.postal_code,
                };
                if (address.city) {
                    city.id = address.city.postal_code;
                    city.city_name_with_type = address.city.city_name_with_type;
                    city.city_fias_id = address.city.city_fias_id;
                    city.city_label = (address.city.postal_code ? address.city.postal_code : '') + ' ' + address.city.city_name;

                } else {
                    city.id = null; // TODO
                    city.city_name_with_type = address.city_name;  // TODO
                    city.city_fias_id = null;
                }

                let country = {
                    "name": address.country_name,
                    "iso_code": address.country_code ? address.country_code : address.country_name // TODO
                };

                this.cities = [];
                this.cities.push(city);
                this.city = city;

                this.countries = [];
                this.countries.push(country);
                this.country = country;

            }
        },
        city(val) { //Для v-model в родитель
            if (!_.every(Object.assign({}, val, {city_label: ''}), _.isEmpty) && !_.get(val, 'postal_code_found', false)) {
                console.log('val=', val);

                this.onSelect(val);
            }
            this.$emit('input', val);
        },
        postalCode(val) {
            if (!_.isEmpty(val)) {
                let cityValue = JSON.parse(JSON.stringify(this.city)); //Copy of object. Fix for issue with Vuex in v-model
                cityValue.postal_code = val;
                this.city = cityValue;
            }
        },
        country: {
            handler: function (val) {
                if (!this.addressBookChosen) {
                    this.city = null;
                } else {
                    this.addressBookChosen = false;
                }
            },
            deep: true
        }
    },
    methods: {
        onSelect(selectedOption, id) {

            let cityValue = JSON.parse(JSON.stringify(selectedOption));
            cityValue.postal_code_found = true;
            if (
                cityValue.country_code !== 'RU' &&
                _.get(cityValue, 'lat', false) &&
                _.get(cityValue, 'lng', false)
            ) {
                if (this.isCheckPostalCode) return;
                this.isCheckPostalCode = true;
                axios.get('/geocoding/postalcode', {
                    params: {
                        lat: cityValue.lat,
                        lng: cityValue.lng,
                        country_code: cityValue.country_code
                    }
                }).then((response) => {
                    let postal_code = response.data.data.attributes.postal_code;
                    if (postal_code) {
                        cityValue.postal_code = postal_code;
                        this.city = cityValue;
                    }
                }).catch(error => {
                    console.log(error);
                    this.systemMessage("error");
                }).finally(() => {
                    this.isCheckPostalCode = false;
                });
            }

            if (cityValue.city_fias_id && !cityValue.postal_code) {
                axios.get('/geocoding/addressbyid', {
                    params: {
                        'query': cityValue.city_fias_id
                    }
                }).then(response => {
                    // handle success
                    if (response.data.data) {
                        this.setCitiesData(response.data.data);
                        if (_.size(this.cities) === 1) {
                            let cityNewVal = this.cities[0];
                            cityNewVal.postal_code_found = true;
                            this.city = cityNewVal;
                        }
                        if (this.city && !this.city.postal_code) {
                            this.$bvModal.show(this.modal_id);
                            //Пока оставляем старый способ уточнения города Наприме для зарубежных
                            /* this.getCities(selectedOption.city_name, data => {
                                 if (_.size(data) === 1) {
                                     for (var i = 0; i < data.length; i++) {
                                         if (data[i].city_fias_id === selectedOption.city_fias_id) this.city = data[i];
                                     }
                                 }
                                 if (this.city && !this.city.postal_code) this.$bvModal.show('modal-post-code-city');
                             });*/
                        }


                    }
                    this.isLoading = false;
                }).catch(error => {
                    console.log(error);
                    this.systemMessage("error");
                    this.isLoading = false;
                });
            }
        },
        selectCountry(countryValue) {
            if (countryValue) {
                this.country = countryValue;
            } else {
                this.country = {
                    "name": "RU",
                    "iso_code": "RU"
                };
            }
            this.getTopCities();
        },
        setCitiesData(data) {
            this.cities = [];
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                let settlement = item.attributes.settlement;
                let settlement_type = item.attributes.settlement_type;
                let city_name = item.attributes.city;
                let area_type = item.attributes.area_type;
                let region_type = item.attributes.region_type;
                let city_district_fias_id = item.attributes.city_district_fias_id;

                /*if((item.attributes.street || area_type === 'р-н' || settlement_type === 'р-н' || region_type ==='обл' || region_type ==='край' || region_type ==='Респ' || city_district_fias_id)
                    && (!settlement || !city_name)) continue; //проверяем чтобы улица тоже не залетала
*/
                let name = ((settlement) ? settlement : city_name);
                let nameWithType = ((item.attributes.settlement) ? item.attributes.settlement_with_type : item.attributes.city_with_type);
                let fias_id = ((item.attributes.settlement_fias_id) ? item.attributes.settlement_fias_id : item.attributes.city_fias_id);
                let itemId = (item.id) ? item.id : item.attributes.settlement_fias_id;
                let postalCode = (item.attributes.postal_code) ? item.attributes.postal_code : "";
                let streetWithType = (item.attributes.street_with_type) ? item.attributes.street_with_type : "";
                let lat = _.get(item, "attributes.geo_lat", "");
                let lng = _.get(item, "attributes.geo_lon", "");

                this.cities.push({
                    'id': itemId,
                    'city_name': name,
                    'city_label': postalCode + " " + name + " " + streetWithType,
                    'country_name': item.attributes.country,
                    'country_code': item.attributes.country_iso_code,
                    'region_name': item.attributes.region_with_type,
                    'city_name_with_type': nameWithType,
                    'city_fias_id': fias_id,
                    'postal_code': item.attributes.postal_code,
                    'lat': lat,
                    'lng': lng,
                });
            }
        },
        getCities(query, callback) {
            if (_.size(query) < 1 || this.country === null) {
                return;
            }
            this.isLoading = true;
            axios.get('/geocoding/cities', {
                params: {
                    'query': query,
                    'country': this.country.iso_code
                }
            })
                .then(response => {
                    // handle success
                    if (response.data.data) {
                        this.setCitiesData(response.data.data);
                        if (callback) callback(this.cities);
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.systemMessage("error");
                    this.isLoading = false;
                });
        },
        getTopCities() {
            this.isLoading = true;
            axios.get('/geocoding/topcities', {
                params: {
                    'country': this.country.iso_code
                }
            }).then(response => {
                // handle success
                if (response.data.data) {
                    this.setCitiesData(response.data.data);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.systemMessage("error");
                this.isLoading = false;
            });
        },
        setCountriesData(data) {
            this.countries = [];
            for (let i = 0; i < data.length; i++) {
                let item = data[i];

                this.countries.push({
                    'name': item.attributes.country_name,
                    'iso_code': item.id,
                });
            }
        },
        getTopCountries() {
            this.isLoading = true;
            axios.get('/geocoding/topcountries', {
                params: {}
            }).then(response => {
                // handle success
                if (response.data.data) {
                    this.setCountriesData(response.data.data);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.systemMessage("error");
                this.isLoading = false;
            });
        },
        getCountries(query) {
            this.isLoading = true;
            axios.get('/geocoding/countries', {
                params: {
                    'query': query
                }
            })
                .then(response => {
                    // handle success
                    if (response.data.data) {
                        this.countries = response.data.data.map(function (item) {
                            return {
                                'name': item.attributes.country,
                                'iso_code': item.attributes.country_iso_code
                            };
                        });
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.systemMessage("error");
                    this.isLoading = false;
                });
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
    min-height: 40px;
    display: block;
    border-radius: 5px;
    border: 0px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
}

.multiselect__input {
    margin: 0;
    padding: 0;
}

.multiselect__select {
    top: auto;
    padding: 4px 0 0 12px;
}

.input-group-prepend-city, .input-group-prepend-country {
    width: 70px !important;
}

.tooltip-inner, .multiselect-country {
    width: 217px !important; /*опред инпут, который появляется вместо флага RU*/
    padding: 0.63rem 0rem !important;
}

.add_book {
    position: absolute;
    right: 3%;
    top: 32%;
    z-index: 1;
    font-size: 18px;
}

.multiselect__single {
    padding-top: 3px !important;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
}

.multiselect__placeholder {
    padding-top: 4px !important;
    margin-bottom: 0 !important;
    overflow: hidden;
    white-space: nowrap;
}

@media (min-width: 576px) {
    .multiselect-city .form-control, multiselect-country .form-control {
        padding-left: 0 !important; /*убрала отступы, влияющие на выдвижную строку под городом */
        padding-right: 0 !important;
    }

    .input-group-prepend-city, .input-group-prepend-country {
        width: 80px !important;
    }

    .tooltip-inner, .multiselect-country {
        width: 330px !important;
    }

    .multiselect__select {
        padding: 4px 0 0 12px; /*для стрелки*/
    }

    .search-tariffs__container {
        padding: 0 15px;
    }

    .add_book {
        top: 30%;
    }
}

@media (min-width: 1200px) {

    .tooltip-inner, .multiselect-country {
        width: 270px !important;
    }

    .multiselect__select {
        padding: 4px 0 0 12px; /*для стрелки*/
    }

    .search-tariffs__container {
        padding: 0 15px;
    }
}

@media (min-width: 1600px) {
    .tooltip-inner, .multiselect-country {
        width: 330px !important;
    }

}

.tooltip-inner {
    text-transform: uppercase;
    max-width: 350px;
}
</style>
