<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <div class="input-group">
            <div class="input-group-prepend-country input-group-prepend ">
                <span class="">
                   <multiselect
                       class="multiselect-country form-control"
                       v-model="country"
                       :options="countries"
                       :multiple="false"
                       :close-on-select="true"
                       :clear-on-select="false"
                       :preserve-search="true"
                       placeholder=""
                       open-direction="bottom"
                       label="name"
                       track-by="iso_code"
                       :preselect-first="true"
                       @search-change="getCountries"
                       :internalSearch="false"
                       @select="selectCountry"
                       :show-labels="true"
                       deselectLabel= "x"
                       selectLabel= ""
                       selectedLabel=""
                       data-trigger="!hover"
                       data-toggle="tooltip"
                       title=""
                       data-placement="top"
                       data-original-title="Если нужной страны нет в списке, начните набирать..."
                   >
                   <template slot="selection" slot-scope="{ values, search, isOpen }" class="input-group">
                    <div class="input-group-prepend-city input-group-prepend">
                        <span class="multiselect__single input-group-text" v-if="values.length && !isOpen">{{ values.length }} options selected</span>
                    </div>
                            </template>
                            <template slot="singleLabel" slot-scope="props">
                               <i :class="'flag-icon flag-icon-' + props.option.iso_code.toLowerCase()"></i>
                               {{ props.option.iso_code }}
                           </template>
                           <template slot="option" slot-scope="props">
                               <div class="option__desc">
                                   <span class="option__title">
                                    <i :class="'flag-icon flag-icon-' + props.option.iso_code.toLowerCase()"></i>
                                    {{ props.option.name }}
                                </span>
                            </div>
                        </template>
                        <template slot="noOptions" class="1">
                            {{ trans('calculator.not_found') }}
                        </template>
                        <template slot="noResult" class="2">
                           {{ trans('calculator.not_found') }}
                       </template>
                   </multiselect>
               </span>
            </div>

            <multiselect
                class="multiselect-city form-control position-relative input-xl pl-0"
                v-model="city"
                :options="cities"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                :placeholder="placeholderValue"
                open-direction="bottom"
                label="city_label"
                track-by="id"
                :internalSearch="false"
                :preselect-first="false"
                @search-change="getCities"
                @select="onSelect"
                :show-labels="true"
                deselectLabel= "x"
                selectLabel= ""
                selectedLabel=""
            >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span>
                </template>
                <template slot="singleLabel" slot-scope="props">
                    {{ props.option.city_name}}
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                   <span class="option__title">
                    {{ props.option.city_name_with_type + ", " + props.option.region_name }}
                </span>
                    </div>
                </template>
                <template slot="noOptions">
                    {{ trans('calculator.not_found') }}
                </template>
                <template slot="noResult">
                    {{ trans('calculator.not_found') }}
                </template>
            </multiselect>
        </div>

        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";
    import SelectCity from "../calculator/components/partials/SelectCity";

    export default {
        name: "CityField",
        mixins: [BaseField],
        extends: SelectCity,
    }
</script>

<style scoped>
    .form-control, .multiselect.multiselect-city.form-control {
        padding: 0 !important;
    }
</style>
