import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js';
import OrderSelectCargoes from './components/OrderSelectCargoes.vue';
import OrderSelectTariff from './components/OrderSelectTariff.vue';
import OrderTariffCard from '../../calculator/components/partials/TariffCard';
import PickupDateTime from '../../calculator/components/partials/PickupDateTime';
import CityField from "../../fields/CityField";
import AddressField from "../../fields/AddressField";

Vue.component('order-edit', {
    data() {
        return {
            link: '/admin/orders',
            cityFromLocal: {},
            cityToLocal: {},
            countryFromLocal: {
                fias_id:"",
                house:null,
                id:"RU",
                label:"RU Россия",
                name:"RU",
                name_with_type:"RU Россия",
                name_without_type:"Россия",
                postal_code:null,
                region_name:"",
                street:null
            },
            countryToLocal: {
                fias_id:"",
                house:null,
                id:"RU",
                label:"RU Россия",
                name:"RU",
                name_with_type:"RU Россия",
                name_without_type:"Россия",
                postal_code:null,
                region_name:"",
                street:null
            },
            streetFromLocal:{},
            streetToLocal:{},
            houseFromLocal:{},
            houseToLocal:{}
        }
    },
    props: {
        cargoTypes: {
            default: () => []
        }
    },
    mixins: [
        MixinsEdit
    ],
    components: {
        OrderSelectCargoes,
        OrderSelectTariff,
        OrderTariffCard,
        PickupDateTime,
        CityField,
        AddressField
    },
    created() {
        this.form.notice = !!+this.form.notice;
        this.form.high_responsibility = !!+this.form.high_responsibility;
        this.form.sms_notifications = !!+this.form.sms_notifications;
        this.form.personal_delivery = !!+this.form.personal_delivery;
        this.form.insurance = !!+this.form.insurance;
    },
    mounted() {
        this.step = 1;

        this.form.sender.region_code = 1; //TODO не пишется в БД вернуть после исправления //this.form.sender.region_code,
        this.form.recipient.region_code = 1; //TODO не пишется в БД вернуть после исправления //this.form.sender.region_code,

        this.$set(this.cityFromLocal, 'name', _.get(this.form.sender, 'city_name', ''));
        this.$set(this.cityFromLocal, 'name_with_type', _.get(this.form.sender, 'city_name_with_type', ''));
        this.$set(this.cityFromLocal, 'label', _.get(this.form.sender, 'postal_code', '') + ' ' + _.get(this.form.sender, 'city_name', ''));
        this.$set(this.cityFromLocal, 'fias_id', _.get(this.form.sender, 'city_fias_id', ''));
        this.$set(this.cityFromLocal, 'postal_code', _.get(this.form.sender, 'postal_code', ''));

        this.$set(this.cityToLocal, 'name', _.get(this.form.recipient, 'city_name', ''));
        this.$set(this.cityToLocal, 'name_with_type', _.get(this.form.recipient, 'city_name_with_type', ''));
        this.$set(this.cityToLocal, 'label', _.get(this.form.recipient, 'postal_code', '') + ' ' + _.get(this.form.recipient, 'city_name', ''));
        this.$set(this.cityToLocal, 'fias_id', _.get(this.form.recipient, 'city_fias_id', ''));
        this.$set(this.cityToLocal, 'postal_code', _.get(this.form.recipient, 'postal_code', ''));

        //---------------

        this.$set(this.streetFromLocal, 'name', _.get(this.form.sender, 'street', ''));
        this.$set(this.streetFromLocal, 'name_with_type', _.get(this.form.sender, 'street', ''));
        this.$set(this.streetFromLocal, 'label', _.get(this.form.sender, 'street', ''));
        this.$set(this.streetFromLocal, 'fias_id', _.get(this.form.sender, 'street_fias_id', ''));
        this.$set(this.streetFromLocal, 'postal_code', _.get(this.form.sender, 'postal_code', ''));

        this.$set(this.streetToLocal, 'name', _.get(this.form.recipient, 'street', ''));
        this.$set(this.streetToLocal, 'name_with_type', _.get(this.form.recipient, 'street', ''));
        this.$set(this.streetToLocal, 'label', _.get(this.form.recipient, 'street', ''));
        this.$set(this.streetToLocal, 'fias_id', _.get(this.form.recipient, 'street_fias_id', ''));
        this.$set(this.streetToLocal, 'postal_code', _.get(this.form.recipient, 'postal_code', ''));

        this.$set(this.houseFromLocal, 'name', _.get(this.form.sender, 'house', ''));
        this.$set(this.houseFromLocal, 'name_with_type', _.get(this.form.sender, 'house', ''));
        this.$set(this.houseFromLocal, 'label', _.get(this.form.sender, 'house', ''));
        this.$set(this.houseFromLocal, 'fias_id', _.get(this.form.sender, 'city_fias_id', ''));
        this.$set(this.houseFromLocal, 'postal_code', _.get(this.form.sender, 'postal_code', ''));

        this.$set(this.houseToLocal, 'name', _.get(this.form.recipient, 'house', ''));
        this.$set(this.houseToLocal, 'name_with_type', _.get(this.form.recipient, 'house', ''));
        this.$set(this.houseToLocal, 'label', _.get(this.form.recipient, 'house', ''));
        this.$set(this.houseToLocal, 'fias_id', _.get(this.form.recipient, 'city_fias_id', ''));
        this.$set(this.houseToLocal, 'postal_code', _.get(this.form.recipient, 'postal_code', ''));

        this.$set(this.countryFromLocal, 'name', _.get(this.form.sender, 'country_name', ''));
        this.$set(this.countryFromLocal, 'name_with_type', _.get(this.form.sender, 'country_name', ''));
        this.$set(this.countryFromLocal, 'label', _.get(this.form.sender, 'country_code', '') + ' ' + _.get(this.form.sender, 'country_name', ''));
        this.$set(this.countryFromLocal, 'id', _.get(this.form.sender, 'country_code', ''));

        this.$set(this.countryToLocal, 'name', _.get(this.form.recipient, 'country_name', ''));
        this.$set(this.countryToLocal, 'name_with_type', _.get(this.form.recipient, 'country_name', ''));
        this.$set(this.countryToLocal, 'label', _.get(this.form.recipient, 'country_code', '') + ' ' + _.get(this.form.sender, 'country_name', ''));
        this.$set(this.countryToLocal, 'id', _.get(this.form.recipient, 'country_code', ''));


        this.addressFrom = this.copyObject(this.form.sender);
        this.addressTo = this.copyObject(this.form.recipient);
        this.cityFrom = this.copyObject(this.form.sender);
        this.cityTo = this.copyObject(this.form.recipient);

        if (_.size(this.form.tariffs)) {
            let orderedTariff = this.form.tariffs[0];
            let tariff = orderedTariff.tariff;
            this.tariff = {
                id: orderedTariff.id,
                type: "tariffs",
                attributes: {
                    courier_before: orderedTariff.courier_before,
                    courier_name: tariff.courier_service.name,
                    days_max: orderedTariff.max_delivery_time,
                    days_min: orderedTariff.min_delivery_time,
                    office_before: orderedTariff.office_before,
                    order_before: orderedTariff.order_before,
                    price: parseFloat(this.form.price),
                    tariff_id: orderedTariff.tariff_id,
                    tariff_name: orderedTariff.name
                }
            }
        }
    },
    methods:{
        copyObject(obj){
            return Object.assign({}, obj);
        },
        preUpdate() {
            this.form.tariff = this.tariff;
            this.form.cargoes = this.cargoes.packages;
            this.form.pay_now = false;
            this.form.package_type = this.cargoes.type;
            this.form.tariff_response_id  = this.tariff.attributes.tariff_response_id;
            this.form.from = {
                "country_name": this.countryFromLocal.name,
                "country_code": this.countryFromLocal.id,
                "city_id": this.form.sender.city_id,
                "city_name": this.form.sender.city_name,
                "city_name_with_type": this.form.sender.city_name_with_type,
                "region_name": this.form.sender.region_name,
                "region_code": 1,//TODO не пишется в БД вернуть после исправления //this.form.sender.region_code,
                "street": this.form.sender.street,
                "postal_code": this.form.sender.postal_code,
                "house": this.form.sender.house,
                "flat": this.form.sender.flat,
                "company_name": this.form.sender.company_name,
                "first_name": this.form.sender.first_name,
                "last_name": this.form.sender.last_name,
                "phone": this.form.sender.phone,
                "email": this.form.sender.email,
                "city_fias_id": this.form.sender.city_fias_id
            };

            this.form.to = {
                "country_name": this.countryToLocal.name,
                "country_code": this.countryToLocal.id,
                "city_id": this.form.recipient.city_id,
                "city_name": this.form.recipient.city_name,
                "city_name_with_type": this.form.recipient.city_name_with_type,
                "region_name": this.form.recipient.region_name,
                "region_code":1,//TODO не пишется в БД вернуть после исправления //this.form.recipient.region_code,
                "street": this.form.recipient.street,
                "postal_code": this.form.recipient.postal_code,
                "house": this.form.recipient.house,
                "flat": this.form.recipient.flat,
                "company_name": this.form.recipient.company_name,
                "first_name": this.form.recipient.first_name,
                "last_name": this.form.recipient.last_name,
                "phone": this.form.recipient.phone,
                "email": this.form.recipient.email,
                "city_fias_id": this.form.recipient.city_fias_id
            };
        }
    },
    watch: {
        cityFromLocal(city) {
            this.form.sender.city_fias_id = city.fias_id;
            this.form.sender.city_name = city.name;
            this.form.sender.city_name_with_type = city.name_with_type;
            this.form.sender.postal_code = city.postal_code;
            this.form.sender.country_name = this.countryFromLocal.name;
            this.form.sender.country_code = this.countryFromLocal.id;
            this.cityFrom = this.copyObject(this.form.sender);
        },
        cityToLocal(city) {
            this.form.recipient.city_fias_id = city.fias_id;
            this.form.recipient.city_name = city.name;
            this.form.recipient.city_name_with_type = city.name_with_type;
            this.form.recipient.postal_code = city.postal_code;
            this.form.recipient.country_name = this.countryToLocal.name;
            this.form.recipient.country_code = this.countryToLocal.id;
            this.cityTo = this.copyObject(this.form.recipient);
        },
        streetFromLocal(street) {
            this.form.sender.street_fias_id = street.fias_id;
            this.form.sender.street = street.name_with_type;
            this.form.sender.postal_code = street.postal_code;
            this.cityFrom = this.copyObject(this.form.sender);
        },
        streetToLocal(street) {
            this.form.recipient.street_fias_id = street.fias_id;
            this.form.recipient.street = street.name_with_type;
            this.form.recipient.postal_code = street.postal_code;
            this.cityTo = this.copyObject(this.form.recipient);
        },
        houseFromLocal(house) {
            this.form.sender.house = house.name;
            this.form.sender.postal_code = house.postal_code;
            this.cityFrom = this.copyObject(this.form.sender);
        },
        houseToLocal(house) {
            this.form.recipient.house = house.name;
            this.form.recipient.postal_code = house.postal_code;
            this.cityTo = this.copyObject(this.form.recipient);
        }
    },
    computed: {
        step: {
            // Текущая страница калькулятора может
            // 0 - это заглавная форма,
            // 1 - выбор типа отправления,
            // 2 - выбор тарифа,
            // 3 - Оформление заявки
            get: function () {
                return this.$store.getters['calculator/getStep'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setStep', newValue);
            }
        },
        cityFrom: {
            get: function () {
                return this.$store.getters['calculator/getCityFrom'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityFrom', newValue);
            }
        },
        cityFromPostalCode: {
            get: function () {
                return (this.$store.state.calculator.cityFrom) ? this.$store.state.calculator.cityFrom.postal_code : null;
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityFromPostalCode', newValue);
            }
        },
        cityTo: {
            get: function () {
                return this.$store.getters['calculator/getCityTo'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityTo', newValue);
            }
        },
        cityToPostalCode: {
            get: function () {
                return (this.$store.state.calculator.cityTo) ? this.$store.state.calculator.cityTo.postal_code : null;
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCityToPostalCode', newValue);
            }
        },
        cargoes: {
            get: function () {
                return this.$store.getters['calculator/getCargoes'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setCargoes', newValue);
            }
        },
        addressFrom: {
            get() {
                return this.$store.getters['calculator/getAddressFrom'];
            },
            set(newValue) {
                this.$store.dispatch('calculator/setAddressFrom', newValue);
            }
        },
        addressTo: {
            get() {
                return this.$store.getters['calculator/getAddressTo'];
            },
            set(newValue) {
                this.$store.dispatch('calculator/setAddressTo', newValue);
            }
        },
        storedOrder: {
            get: function () {
                return this.$store.getters['calculator/getOrder'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setOrder', newValue);
            }
        },
        tariff: {
            get: function () {
                return this.$store.getters['calculator/getTariff'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setTariff', newValue);
            }
        }
    }
});
