<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <address-autocomplete
            :id="id"
            :type="type"
            :placeholder="placeholder"
            class="form-control multiselect-input"
            :class="{'validated-error': errors.has(name)}"
            :disabled="disabled"
            :readonly="readonly"
            :search-by="searchBy"
            :refresh="refresh"
            :name="name"
            v-bind:value="value"
            v-on:input="(payload) => {this.$emit('input', payload)}"
        ></address-autocomplete>
        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";
    import AddressAutocomplete from "../addressAutocomplete/Index";

    export default {
        name: "AddressField",
        components: {AddressAutocomplete},
        mixins: [BaseField],
        props: ['searchBy', 'type', 'refresh']
    }
</script>

<style scoped>

</style>
