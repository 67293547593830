import MixinsEdit from '../../../crud/mixins/edit.js';
import RoleEditCheckbox from '../RoleEditCheckbox.vue';

Vue.component('role-edit', {
    data() {
        return {
            link: '/admin/roles',
        }
    },
    props: {
        data: {
            default: () => {
                return {}
            }
        },
        permissions: Object,
    },
    mixins: [MixinsEdit],
    components: {
        RoleEditCheckbox
    }
});
