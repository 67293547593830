<template>
    <div class="card-body">
        <div class="form-group" v-show="user">
            <toggle-button v-model="address.save" :sync="true"/>
            <label class="">
                {{ trans('app.contacts.save_new') }}
            </label>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <text-field
                    :required="false"
                    v-model="address.company_name"
                    :name="prefix + 'company_name'"
                    :label="trans('app.contacts.columns.company')"
                    :placeholder="trans('app.contacts.columns.company')"
                ></text-field>
            </div>
            <div class="col-lg-6">
                <text-field
                    :required="true"
                    :readonly="true"
                    v-model="address.region_name"
                    :name="prefix + 'region_name'"
                    :label="trans('app.contacts.columns.region')"
                    :placeholder="trans('app.contacts.columns.region')"
                ></text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <text-field
                    :required="true"
                    :readonly="true"
                    v-model="address.city_name"
                    :name="prefix + 'city_name'"
                    :label="trans('app.contacts.columns.city')"
                    :placeholder="trans('app.contacts.columns.city')"
                ></text-field>
            </div>
            <div class="col-lg-6">
                <address-field
                    :required="true"
                    type="street"
                    v-model="street"
                    :search-by="address.city_fias_id"
                    :name="prefix + 'street'"
                    :label="trans('app.contacts.columns.street')"
                    :placeholder="trans('app.contacts.columns.street')"
                ></address-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <address-field
                    :required="true"
                    type="house"
                    v-model="house"
                    :search-by="street.fias_id"
                    :name="prefix + 'house'"
                    :label="trans('app.contacts.columns.house')"
                    :placeholder="trans('app.contacts.columns.house')"
                ></address-field>
            </div>
            <div class="col-lg-4">
                <text-field
                    :required="false"
                    v-model="address.flat"
                    :name="prefix + 'flat'"
                    :label="trans('app.contacts.columns.flat')"
                    :placeholder="trans('app.contacts.columns.flat')"
                ></text-field>
            </div>
            <div class="col-lg-4">
                <text-field
                    :required="true"
                    :readonly="false"
                    v-model="address.postal_code"
                    :name="prefix + 'postal_code'"
                    :label="trans('app.contacts.columns.index')"
                    :placeholder="trans('app.contacts.columns.index')"
                ></text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <text-field
                    :required="true"
                    v-model="address.last_name"
                    :name="prefix + 'last_name'"
                    :label="trans('app.contacts.columns.last_name')"
                    :placeholder="trans('app.contacts.columns.last_name')"
                ></text-field>
            </div>
            <div class="col-lg-6">
                <text-field
                    :required="true"
                    v-model="address.first_name"
                    :name="prefix + 'first_name'"
                    :label="trans('app.contacts.columns.first_name')"
                    :placeholder="trans('app.contacts.columns.first_name')"
                ></text-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <phone-field
                    :required="true"
                    v-model="address.phone"
                    :name="prefix + 'phone'"
                    :label="trans('app.contacts.columns.phone')"
                    :placeholder="trans('app.contacts.columns.phone')"
                    :onlyCountries="countries"
                    :defaultCountry="address.country_code"
                    :preferredCountries="[]"
                ></phone-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <text-field
                    :required="false"
                    v-model="address.comment"
                    :name="prefix + 'comment'"
                    :label="trans('app.contacts.columns.comment')"
                    :placeholder="trans('app.contacts.columns.comment')"
                ></text-field>
            </div>
        </div>
    </div>
</template>

<script>
    import AddressAutocomplete from "../../../addressAutocomplete/Index";
    export default {
        name: "AddressForm",
        inject: ['$validator'],
        components: {AddressAutocomplete},
        data() {
            return {
                countries:[],
                address: (this.value) ? this.value : {},
                street: {
                    fias_id: "",
                    name: (this.value) ? this.value.street : "",
                    name_with_type: (this.value) ? this.value.street : "",
                    label: (this.value) ? this.value.street : "",
                    settlement_fias_id: "",
                    settlement_with_type:""
                },
                house: {
                    fias_id: "",
                    name: (this.value) ? this.value.house : "",
                    name_with_type: (this.value) ? this.value.house : "",
                    label: (this.value) ? this.value.house : ""
                }
            };
        },
        mounted() {
            let initAddress = null;
            this.countries.push(this.address.country_code);

            if (this.prefix.includes('from')) {
                initAddress = Object.assign(this.address, this.addressFrom);
            } else if (this.prefix.includes('to')) {
                initAddress = Object.assign(this.address, this.addressTo);
            }

            if (!_.isEmpty(initAddress)) {
                this.address = initAddress;
                /*for (let fieldName in initAddress) {
                    console.log('f:v ', fieldName, initAddress[fieldName]);
                    this.$set(this.address, fieldName, initAddress[fieldName]);
                }*/ // second way

                this.$set(this.street, 'name', initAddress['street']);
                this.$set(this.street, 'name_with_type', initAddress['street']);
                this.$set(this.street, 'label', initAddress['street']);

                this.$set(this.house, 'name', initAddress['house']);
                this.$set(this.house, 'name_with_type', initAddress['house']);
                this.$set(this.house, 'label', initAddress['house']);
            }
        },
        computed: {
            user: {
                get() {
                    return this.$store.getters['user/getUser'];
                },
                set(newValue) {
                    this.$store.dispatch('user/setUser', newValue);
                }
            },
            addressFrom: {
                get() {
                    return this.$store.getters['calculator/getAddressFrom'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressFrom', newValue);
                }
            },
            addressTo: {
                get() {
                    return this.$store.getters['calculator/getAddressTo'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressTo', newValue);
                }
            },
        },
        props: {
            prefix: {
                default: ""
            },
            value: {
                default: () => {
                    return {
                        "country_name": "",
                        "region_name": "",
                        "email": "",
                        "city_id": "",
                        "city_fias_id": "",
                        "city_name": "",
                        "street": "",
                        "house": "",
                        "flat": "",
                        "company_name": "",
                        "first_name": "",
                        "last_name": "",
                        "phone": "",
                        "postal_code": "",
                        "comment": "",
                        "save": false
                    }
                }
            },
        },
        watch: {
            address: {
                handler(val) { //Для v-model в родитель

                    if (this.prefix.includes('from')) {
                        this.addressFrom = val;
                    } else if (this.prefix.includes('to')) {
                        this.addressTo = val;
                    }

                    this.countries = [];
                    this.countries.push(this.address.country_code);
                   // this.$refs.phoneNumber.choose(this.address.country_code);
                    this.$emit('input', val);
                },
                deep: true
            },
            street(val) {
                // console.log('street - ', val);
                let address = Object.assign({}, this.address);
                address.street = _.get(val, 'name_with_type', '');
                address.street_fias_id = _.get(val, 'fias_id', '');
                if (_.get(val, 'postal_code', false)) {
                    address.postal_code = _.get(val, 'postal_code', address.postal_code);
                }

                if (_.get(val, 'settlement', false)) {
                    address.city_name = _.get(val, 'settlement', address.city);
                }

                if (_.get(val, 'settlement_with_type', false)) {
                    address.city_name_with_type = _.get(val, 'settlement_with_type', address.city);
                }

                if (_.get(val, 'settlement_fias_id', false)) {
                    address.city_fias_id = _.get(val, 'settlement_fias_id', address.fias_id);
                }
                this.address = address;
            },
            house(val) {
                let address = Object.assign({}, this.address);
                address.house = _.get(val, 'name_with_type', '');
                if (_.get(val, 'postal_code', false)) {
                    address.postal_code = _.get(val, 'postal_code', address.postal_code);
                }
                this.address = address;
            }
        },
    }
</script>

<style>
    .multiselect.form-control.multiselect-input {
        box-sizing: border-box;
        padding: 0.75rem 0rem;
    }

    .multiselect-input .multiselect__tags {
        min-height: 20px !important;
        padding-top: unset !important;
    }
</style>
