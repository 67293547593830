<template>
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <label for>{{ trans('calculator.pickup_date') }}</label>
                <date-picker
                    class="form-control"
                    v-model="pickup_date"
                    :config="pickUpDateConfig"
                    v-bind:class="{'is-invalid':(validationErrorsData && validationErrorsData.pickup_date)}"
                ></date-picker>
                <div
                    v-if="validationErrorsData && validationErrorsData.pickup_date"
                    class="invalid-feedback"
                >{{ validationErrorsData.pickup_date[0] }}
                </div>
                <div class="text-danger" v-if="errors.has('pickup_date')"> {{ errors.first('pickup_date') }} </div>
            </div>
        </div>
        <div :class="discount != 1 ? 'col-lg-4' : 'col-lg-8'">
            <div class="form-group">
                <label for>{{ trans('app.labels.from') }}</label>
                <time-range-slider v-if="discount==1" v-model="timeRange" :time_start="rangeFrom" :time_end="rangeTo"></time-range-slider>
                <date-picker v-else
                    v-model="pickup_time_from"
                    :config="pickUpTimeFromConfig"
                    class="form-control"
                    v-bind:class="{'is-invalid':(validationErrorsData && validationErrorsData.pickup_time_from)}"
                ></date-picker>
                <div
                    v-if="validationErrorsData && validationErrorsData.pickup_time_from"
                    class="invalid-feedback"
                >{{ validationErrorsData.pickup_time_from[0] }}
                </div>
                <div class="text-danger" v-if="errors.has('pickup_time_from')"> {{ errors.first('pickup_time_from') }} </div>
            </div>
        </div>
        <div class="col-lg-4" v-if="discount != 1">
            <div class="form-group">
                <label>{{ trans('app.labels.to') }}</label>
                <date-picker
                             v-model="pickup_time_to"
                             :config="pickUpTimeToConfig"
                             class="form-control"
                             v-bind:class="{'is-invalid':(validationErrorsData && validationErrorsData.pickup_time_to)}"
                ></date-picker>
                <div
                    v-if="validationErrorsData && validationErrorsData.pickup_time_to"
                    class="invalid-feedback"
                >{{ validationErrorsData.pickup_time_to[0] }}
                </div>
                <div class="text-danger" v-if="errors.has('pickup_time_to')"> {{ errors.first('pickup_time_to') }} </div>

            </div>
        </div>
    </div>
</template>

<script>
    import datePicker from "vue-bootstrap-datetimepicker";
    import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
    import moment from "moment";
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import TimeRangeSlider from './TimeRangeSlider';

    export default {
        name: "PickupDateTime",
        components: {
            datePicker,
            VueSlider,
            TimeRangeSlider
        },
        watch: {
            timeRange: {
                handler(val) {
                    this.pickup_time_from = val.pickup_time_from;
                    this.pickup_time_to = val.pickup_time_to;
                },
                deep: true
            },
            pickup_date(newVal, oldVal) {
                if (newVal) {
                    let dayOfWeek = moment(newVal).day();
                    let enabledHours = this.pickUpTimeEnabledHours(dayOfWeek);
                    let pickUpTimeFromConfig = this.pickUpTimeFromConfig;
                    let pickUpTimeToConfig = this.pickUpTimeToConfig;

                    pickUpTimeFromConfig.enabledHours = enabledHours;
                    pickUpTimeToConfig.enabledHours = enabledHours;
                    this.pickUpTimeFromConfig = pickUpTimeFromConfig;
                    this.pickUpTimeToConfig = pickUpTimeToConfig;

                    this.initTimes(dayOfWeek);
                    this.setData(newVal);
                }
            },
            pickup_time_from(newVal, oldVal) {
                this.checkData(this.pickup_date, newVal, this.pickup_time_to);
                this.setData(this.pickup_date);
            },
            pickup_time_to(newVal, oldVal) {
                this.checkData(this.pickup_date, this.pickup_time_from, newVal);
                this.setData(this.pickup_date);
            },
            availableDays: {
                handler(val) {
                    this.initConfig();
                },
                deep: true
            }
        },
        data: function () {
            return {
                timeRange: {
                    pickup_time_from:'',
                    pickup_time_to:''
                },
                time_from: [10, 15],
                time_to: [10, 15],
                validationErrorsData: this.validationErrors,
                pickup_date: null,
                pickup_time_from: null,
                pickup_time_to: null,
                pickUpDateConfig: {
                    format: "YYYY-MM-DD",
                    daysOfWeekDisabled: [],
                    locale: "ru",
                    minDate: moment()
                },
                pickUpTimeFromConfig: {
                    format: "HH:mm",
                    locale: "ru",
                    enabledHours: [9, 10, 11, 12, 13, 14, 15, 16, 17, 19]
                },
                pickUpTimeToConfig: {
                    format: "HH:mm",
                    locale: "ru",
                    enabledHours: [9, 10, 11, 12, 13, 14, 15, 16, 17, 19]
                }
            };
        },
        props: {
            weight: "",
            discount: "",
            availableDays: {
                default: () => [
                    {
                        dayOfWeek: 0, //sunday
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 1,
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 2,
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 3,
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 4,
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 5,
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    },
                    {
                        dayOfWeek: 6, //saturday
                        hours: {
                            from: "9",
                            to: "18"
                        }
                    }
                ]
            },
            validationErrors: {
                default: () => {
                    return {};
                }
            }
        },
        mounted() {
            this.initTimes();
            this.initConfig();
        },
        computed:{
            rangeFrom: function(){
                return _.head(this.pickUpTimeFromConfig.enabledHours)+':00';
            },
            rangeTo: function(){
                return _.last(this.pickUpTimeFromConfig.enabledHours)+':59';
            }
        },
        methods: {
            initTimes(dayOfWeek = moment().day()) {
                let day = this.getNextBusinessDay(dayOfWeek);
                this.pickup_time_from = moment(
                    day.hours.from + ":00",
                    "HH:mm"
                ).format("HH:mm");
                this.pickup_time_to = moment(
                    day.hours.to - 1 + ":00",
                    "HH:mm"
                ).format("HH:mm");
            },
            initConfig() {
                let date = this.pickUpDateDefault();
                this.pickup_date = date;
                let enabledHours = this.pickUpTimeEnabledHours();
                this.pickUpDateConfig = {
                    format: "YYYY-MM-DD",
                    daysOfWeekDisabled: this.getDisabledDayOfWeeks(),
                    locale: "ru",
                    minDate: moment().subtract(1, "days")
                };
                this.pickUpTimeFromConfig = {
                    format: "HH:mm",
                    locale: "ru",
                    enabledHours: enabledHours
                };
                this.pickUpTimeToConfig = {
                    format: "HH:mm",
                    locale: "ru",
                    enabledHours: enabledHours
                };
            },
            setData(val) {
                let date = moment(val).format("YYYY-MM-DD");
                let pickup_time_from = moment(date + " " + this.pickup_time_from);
                let pickup_time_to = moment(date + " " + this.pickup_time_to);

                this.$emit("input", {
                    pickup_date: moment(val),
                    pickup_time_from: pickup_time_from,
                    pickup_time_to: pickup_time_to
                });
            },
            checkData(datePickUp, fromTime, toTime) {
                if (!datePickUp || !fromTime || !toTime) return false;
                this.validationErrorsData = {};
                let date = moment(datePickUp).format("YYYY-MM-DD");

                let new_pickup_time_from = moment(date + " " + fromTime);
                let new_pickup_time_to = moment(date + " " + toTime);

                let duration = moment.duration(
                    new_pickup_time_to.diff(new_pickup_time_from)
                );
                let hours = duration.asHours();
                if (hours < 3) {
                    this.systemMessage("error", {
                        text: this.trans("calculator.diff_less_then_3_hours")
                    });
                    this.validationErrorsData = {
                        pickup_time_to: [
                            this.trans("calculator.diff_less_then_3_hours")
                        ],
                        pickup_time_from: [
                            this.trans("calculator.diff_less_then_3_hours")
                        ]
                    };
                    return false;
                }
                return true;
            },
            pickUpDateMinDefault() {
                return this.getNextBusinessDay();
            },
            getDisabledDayOfWeeks() {
                let result = [0, 1, 2, 3, 4, 5, 6];
                for (let i = 0; i < this.availableDays.length; i++) {
                    var index = result.indexOf(
                        parseInt(this.availableDays[i].dayOfWeek)
                    );
                    result.splice(index, 1);
                }
                return result;
            },
            getDayOfWeek(dayOfWeek) {
                let result = null;
                for (let i = 0; i < this.availableDays.length; i++) {
                    if (this.availableDays[i].dayOfWeek === dayOfWeek) {
                        result = this.availableDays[i];
                    }
                }
                return result;
            },
            getNextBusinessDay(dayOfWeek, daysIncrement = 0) {
                let result = {};
                let getNextDay = false;
                let availableDay = this.getDayOfWeek(dayOfWeek);
                if (availableDay) {
                    if (
                        moment().day() === dayOfWeek &&
                        (moment().format("H") >= availableDay.hours.to ||
                            moment().format("H") <= availableDay.hours.from)
                    ) {
                        getNextDay = true;
                    } else {
                        result = availableDay;
                        result.daysInc = daysIncrement;
                    }
                } else {
                    getNextDay = true;
                }

                if (getNextDay) {
                    let daysInc = daysIncrement + 1;

                    let nextDay = dayOfWeek;
                    if (nextDay === 6) nextDay = 0;
                    else nextDay++;
                    result = this.getNextBusinessDay(nextDay, daysInc);
                }
                return result;
            },
            pickUpDateDefault() {
                let day = this.getNextBusinessDay(moment().day());
                return moment().add(day.daysInc, "day");
            },
            pickUpTimeEnabledHours(dayOfWeek = moment().day()) {
                let day = this.getNextBusinessDay(dayOfWeek);
                return _.range(day.hours.from, day.hours.to);
            }
        }
    };
</script>

<style scoped>
</style>
