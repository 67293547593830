<template>
        <multiselect
            v-model="value"
            :options="options"
            :searchable="false"
            :show-labels="false"
            :close-on-select="true"
            :clear-on-select="false"
            class="form-control multiselect"
            placeholder="">
        </multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        name: "multiselect-vue",
        components: {
            Multiselect
        },
        data: function () {
            return {
                value: "",
            };
        },
        props: {
            placeholder: {
                default: ""
            },
            options: {
                default: []
            },
        },
    }

</script>

<style scoped>

</style>
