// initial state
// shape: [{ id, quantity }]
const state = {
    user: null
};

// getters
const getters = {
    getUser: (state, getters, rootState) => {
        return state.user;
    },
};

// actions
const actions = {
    setUser({commit, state}, user) {
        commit('setUser', user);
    },
};

// mutations
const mutations = {
    setUser (state, { user }) {
        Vue.set(state, 'user', user);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
