import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('entity-profile-index', {
    data() {
        return {
            link: '/admin/entity_profiles',
        }
    },
    mixins: [MixinsIndex]
});
