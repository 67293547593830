import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'
import lodash from 'lodash';

Object.defineProperty(Vue.prototype, '_', { value: lodash });
Vue.component('country-edit', {
    mixins: [MixinsEdit],
    data() {
        return {
            link: '/admin/countries',
        }
    },
});
