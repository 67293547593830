Vue.component('adminprofile-edit', {
    data() {
        return {
            form: this.data,
            loading: false,
            subscribed: false,
            isEnglish: this.language === 'en',
            mediaCollections: {}
        }
    },
    props: {
        data: {
            default: () => {
                return {}
            }
        },
        language: String,
    },
    mounted() {
        this.subscribed = !!this.form.subscribed;
    },
    watch: {
        // Пареключатель языка
        isEnglish: function (newValue, oldValue) {
            if (oldValue === false && newValue === true) {
                const parser = new URL(window.location);
                parser.searchParams.set('language', 'en');
                window.location = parser.href;
            } else if (oldValue === true && newValue === false) {
                const parser = new URL(window.location);
                parser.searchParams.set('language', 'ru');
                window.location = parser.href;
            }
        }
    },
    methods: {
        update(changePassword) {
            if (this.loading) {
                return;
            }
            this.form.subscribed = this.subscribed;
            // check frontend errors
            this.errors.clear(null);
            if (this.errors.count('frontend')) {
                _.forEach(this.errors.collect(), ((item, index) => {
                    this.errors.add({
                        field: index,
                        msg: this.errors.first(index, 'frontend')
                    });
                }));
                return;
            }

            this.loading = true;
            this.form.name = this.form.admin_profile.first_name + ' ' + this.form.admin_profile.last_name;
            let data = JSON.parse(JSON.stringify(this.form));

            if (!changePassword) {
                delete data.admin_profile.password;
                delete data.admin_profile.password_confirmation;
            }

            data.mediaCollections = this.getMedia();
            console.log("data", data);
            axios.put('/admin/profile/' + this.data.id, data)
            .then(response => {
                this.systemMessage();
            })
            .catch(error => {
                this.systemMessage('error');
                console.log("error", error);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        getMedia() {
            const CircularJSON = require('circular-json');
            let mediaCollections = {};
            _.forEach(this.$refs, (item, index) => {
                if (item.$refs.dropzone) {
                    mediaCollections[index] = item.$refs.dropzone.getAcceptedFiles();
                }
            });
            return JSON.parse(CircularJSON.stringify(mediaCollections));
        },
        systemMessage(type = 'success', options = {}) {
            options = Object.assign(
                {
                    group: 'system',
                    type: type,
                    title: type == 'success' ? this.trans('crud.success') : this.trans('crud.error'),
                    text: type == 'success' ? this.trans('crud.action_completed') : this.trans('crud.action_failed')
                },
                options
            )
            this.$notify(options);
        },
        onFileUpdate(path) {
            this.form['mediaUpdate'] = true;
        }
    },
    computed: {
        allowPasswordSaving() {
            return this.form.admin_profile.password
                && this.form.admin_profile.password_confirmation
                && this.form.admin_profile.password === this.form.admin_profile.password_confirmation;
        }
    }
});
