<template>
    <div class="form-group">
        <label :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <select
            :id="id"
            :name="name"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)"
            type="text"
            :placeholder="placeholder"
            class="form-control"
            :class="{'validated-error': errors.has(name)}"
            :disabled="disabled"
            :readonly="readonly"
        >
            <option v-for="option in options" :value="option.id">{{ option[displayColumn] }}</option>
        </select>
        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";
    export default {
        name: "SelectField",
        mixins: [BaseField],
        props: {
            options: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            displayColumn: {
                type: String,
                default: "name"
            }
        }
    }
</script>

<style scoped>

</style>
