<template>
    <div>
        <div
            v-if="data.dostavista_order &&
                data.dostavista_order.dostavista_points &&
                data.dostavista_order.dostavista_points[0] &&
                data.dostavista_order.dostavista_points[0].latitude &&
                data.dostavista_order.dostavista_points[0].longitude"
            class="height-550">
            <div class="full-height">
                <GmapMap
                    :center="{
                lat: parseFloat(data.dostavista_order.dostavista_points[0].latitude),
                lng: parseFloat(data.dostavista_order.dostavista_points[0].longitude)
                }"
                    :zoom="14"
                    style="width: 100%; height: 100%"
                    :options="{
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }"
                >
                    <GmapMarker
                        v-for="(point, index) in data.dostavista_order.dostavista_points"
                        :key="index"
                        :position="{lat:parseFloat(point.latitude),lng:parseFloat(point.longitude)}"
                        :clickable="false"
                        :draggable="false"
                        :icon="{url: (point.courier_visit_datetime ? greenPointUrl : redPointUrl), labelOrigin:{x:30,y:75}}"
                        :label="{text: point.street+' '+point.house}"
                    />
                    <GmapMarker
                        v-if="data.dostavista_order.courier_latitude && data.dostavista_order.courier_longitude"
                        :position="{lat:parseFloat(data.dostavista_order.courier_latitude),lng:parseFloat(data.dostavista_order.courier_longitude)}"
                        :clickable="false"
                        :draggable="false"
                        :icon="{url: courierPointUrl, labelOrigin:{x:15,y:35}}"
                        :label="{text: data.dostavista_order.courier_name}"
                    />
                </GmapMap>
            </div>
        </div>
        <div v-else class="text-center">{{ textCourierSearch }}</div>
    </div>
</template>

<script>
export default {
    name: "GmapCourierTracking",
    data: () => {
        return {
            redPointUrl: "https://maps.google.com/mapfiles/kml/paddle/red-circle.png",
            greenPointUrl: "https://maps.google.com/mapfiles/kml/paddle/grn-blank.png",
            courierPointUrl: "https://maps.google.com/mapfiles/kml/pal4/icon62.png"
        }
    },
    props: {
        data: {
            default: () => {

            }
        }
    },
    computed:{
        textCourierSearch() {
            return this.trans('crud.shipment_status.dostavista.courier_search');
        }
    }
}
</script>

