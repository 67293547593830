<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-4 col-12 mb-2">
                <h3 class="content-header-title">
                    {{ trans('calculator.calculator') }}
                </h3>
            </div>
        </div>
        <div class="content-body">
            <!--      Step 0 City select         -->
            <section id="search-website" class="card overflow-inherit">
                <div class="card-content collapse show" ref="scrollCalculater">
                    <div class="card-body">
                        <div class="row justify-content-center align-items-center calculator__container"
                             v-bind:class="{ calculator__container_main: step === 0 }">
                            <div class="col-xl-12 col-custom1920-10 px-xl-0 px-custom1920-1">
                                <h1 class="text-center calculator__title d-none px-1"
                                    v-bind:class="{'d-block': (step === 0)}">
                                    {{ trans('calculator.title') }}
                                </h1>
                                <div class="search-tariffs__container">
                                    <div class="row no-gutters">
                                        <select-city
                                            v-model="cityFromLocal"
                                            prefix="from"
                                            class="col-xl-4"
                                            :clients="clients"
                                            :default-cities="defaultCities"
                                            :placeholder="trans('calculator.sender.city')"
                                            address-book-id="addressBookFrom"
                                        >
                                        </select-city>
                                        <select-city
                                            v-model="cityToLocal"
                                            prefix="to"
                                            class="col-xl-4 pl-xl-1"
                                            :clients="clients"
                                            :default-cities="defaultCities"
                                            :placeholder="trans('calculator.recipient.city')"
                                            address-book-id="addressBookTo"
                                        ></select-city>
                                        <div class="search-tariffs__col_sm col-lg-6 col-xl-2 pl-xl-1 mb-1">
                                            <input type="text"
                                                   readonly
                                                   :value="(cargoes && cargoes.type === 'documents')?trans('calculator.documents_and_parcels'): trans('calculator.cargo')"
                                                   class="form-control input-xl text-center input-calculator"
                                                   @click="checkStep(1)"
                                                   :placeholder="trans('app.calculator.object')">
                                        </div>
                                        <div class="search-tariffs__col_sm col-lg-6 col-xl-2 pl-lg-1 mb-1">
                                            <a class="btn btn-bg-gradient-x-purple-blue btn-lg btn-block btn-calculator"
                                               @click="getTariffsOnClick">
                                                {{ trans('calculator.form') }}
                                            </a>
                                        </div>
                                    </div>

                                    <div v-if="tariffsIsLoading" class="row align-items-center mx-auto col-12">
                                        <div class="col-12">
                                            <span class="p-1 d-flex justify-content-center">
                                                <span class="spinner-border align-middle text-primary m-0"></span>
                                                <span class="ml-2">{{ trans('calculator.loading') }}</span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--      Step 0 City select End         -->

            <!--      Step 1 Cargoes         -->
            <div class="card d-none" v-bind:class="{'d-block': (step === 1)}">
                <select-cargoes ref="selectCargoes" @on-calculate="checkStep(2)" :cargo-types="cargoTypes"></select-cargoes>
            </div>
            <!--      Step 1 Cargoes End         -->

            <!--      Step 2 Tariffs -->
            <div class="card d-none" v-bind:class="{'d-block': (step === 2)}">
                <select-tariff :courier-services="courierServices"></select-tariff>
            </div>
            <!--      Step 2 Tariffs -->

            <!--      Step 3 Tariffs -->
            <div class="card d-none" v-bind:class="{'d-block': (step === 3)}">
                <card-dostavista :clients="clients"
                                 v-if="$store.state.calculator.tariff.attributes.courier_name === 'dostavista'"></card-dostavista>
                <create-order
                    :insurance_coefficient="insurance_coefficient"
                    :high_responsibility_price="high_responsibility_price"
                    :billcode_active="billcode_active"
                    :billcode_required="billcode_required"
                    v-else :embedded="embedded" :clients="clients"
                ></create-order>
            </div>
            <!--      Step 2 Tariffs -->
        </div>
        <template v-if="user">
            <address-book prefix="from" :clients="clients" id="addressBookFrom"></address-book>
            <address-book prefix="to" :clients="clients" id="addressBookTo"></address-book>
        </template>
    </div>
</template>

<script>
    import SelectCity from './components/partials/SelectCity';
    import SelectCargoes from './components/SelectCargoes';
    import SelectTariff from './components/SelectTariff';
    import CreateOrder from "./components/CreateOrder";
    import CardDostavista from "../dostavista/Index";
    import AddressBook from "./components/partials/AddressBook";

    export default {
        name: "calculator-index",
        data() {
            return {
                cityFromLocal: {
                    city_fias_id: _.get(this.order.sender, 'city_fias_id', ''),
                    city_label: _.get(this.order.sender, 'postal_code', '') + ' ' + _.get(this.order.sender, 'city_name', ''),
                    city_name: _.get(this.order.sender, 'city_name', ''),
                    city_name_with_type: _.get(this.order.sender, 'city_name_with_type', ''),
                    country_code: _.get(this.order.sender, 'country_code', ''),
                    country_name: _.get(this.order.sender, 'country_name', ''),
                    id: _.get(this.order.sender, 'postal_code', ''),
                    lat: "",
                    lng: "",
                    postal_code: _.get(this.order.sender, 'postal_code', ''),
                    region_name: _.get(this.order.sender, 'region_name', '')
                },
                cityToLocal: {
                    city_fias_id: _.get(this.order.recipient, 'city_fias_id', ''),
                    city_label: _.get(this.order.recipient, 'postal_code', '') + ' ' + _.get(this.order.recipient, 'city_name', ''),
                    city_name: _.get(this.order.recipient, 'city_name', ''),
                    city_name_with_type: _.get(this.order.recipient, 'city_name_with_type', ''),
                    country_code: _.get(this.order.recipient, 'country_code', ''),
                    country_name: _.get(this.order.recipient, 'country_name', ''),
                    id: _.get(this.order.recipient, 'postal_code', ''),
                    lat: "",
                    lng: "",
                    postal_code: _.get(this.order.recipient, 'postal_code', ''),
                    region_name: _.get(this.order.recipient, 'region_name', '')
                },
            }
        },
        props: {
            cargoTypes: {
                default: () => []
            },
            courierServices: {
                default: () => []
            },
            clients: {
                default: () => []
            },
            defaultCities: {
                default: () => []
            },
            embedded: {
                default: 0
            },
            order: {
                default: {}
            },
            insurance_coefficient: {
                default: 0.01
            },
            high_responsibility_price: {
                default: 700
            },
            billcode_active: {
                default: 0
            },
            billcode_required: {
                default: 0
            },
        },
        components: {
            SelectCity,
            SelectCargoes,
            SelectTariff,
            CreateOrder,
            CardDostavista,
            AddressBook
        },
        watch: {
            cityFrom(city) {
                this.cityFromLocal = city;
            },
            cityTo(city) {
                this.cityToLocal = city;
            },
            cityFromLocal(city) {
                this.cityFrom = city;

                this.addressFrom = Object.assign({}, this.addressFrom, {
                    city_fias_id: _.get(city, 'city_fias_id', ''),
                    city_name: _.get(city, 'city_name', ''),
                    country_code: _.get(city, 'country_code', ''),
                    country_name: _.get(city, 'country_name', ''),
                    city_name_with_type: _.get(city, 'city_name_with_type', ''),
                    postal_code: _.get(city, 'postal_code', _.get(this.addressFrom, 'postal_code', '')),
                    region_name: _.get(city, 'region_name', ''),
                });
            },
            cityToLocal(city) {
                this.cityTo = city;

                this.addressTo = Object.assign({}, this.addressTo, {
                    city_fias_id: _.get(city, 'city_fias_id', ''),
                    city_name: _.get(city, 'city_name', ''),
                    country_code: _.get(city, 'country_code', ''),
                    country_name: _.get(city, 'country_name', ''),
                    city_name_with_type: _.get(city, 'city_name_with_type', ''),
                    postal_code: _.get(city, 'postal_code', _.get(this.addressTo, 'postal_code', '')),
                    region_name: _.get(city, 'region_name', ''),
                });
            },
        },
        methods: {
            checkStep(step) {
                if (this.cityFrom && this.cityTo && this.cityFromSuccess && this.cityToSuccess) {
                    console.log('debug 1');
                    if(this.step === 1 && step === 2 && !this.$refs.selectCargoes.validate()){
                        console.log('debug 2');
                        return;
                    }
                    console.log('debug 3', this.step, step);
                    this.step = step;
                } else {
                    this.step = 0;
                    /* Пока оставляем на всякий случай для уведомлений о том что не найден пост кодv
                    if (!this.cityFromPostalCode) {
                        this.systemMessage("failed", {'message': ''});
                    }
                    if (!this.cityToPostalCode) {
                        this.systemMessage("failed", {'message': ''});
                    }*/
                }
            },
            getTariffsOnClick() {
                this.checkStep(2);
            }
        },
        computed: {
            user: {
                // TODO delete after
                get() {
                    return this.$store.getters['user/getUser'];
                },
                set(newValue) {
                    this.$store.dispatch('user/setUser', newValue);
                }
            },
            step: {
                // Текущая страница калькулятора может
                // 0 - это заглавная форма,
                // 1 - выбор типа отправления,
                // 2 - выбор тарифа,
                // 3 - Оформление заявки
                get: function () {
                    return this.$store.getters['calculator/getStep'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setStep', newValue);
                }
            },
            cityFrom: {
                get: function () {
                    return this.$store.getters['calculator/getCityFrom'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityFrom', newValue);
                    this.checkStep(1);
                }
            },
            cityFromPostalCode: {
                get: function () {
                    return (this.$store.state.calculator.cityFrom) ? this.$store.state.calculator.cityFrom.postal_code : null;
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityFromPostalCode', newValue);
                    this.checkStep(1);
                }
            },
            cityFromSuccess: {
                get: function () {
                    return this.$store.state.calculator.cityFrom?.city_name && this.$store.state.calculator.cityFrom?.country_code;;
                },
                set: function (newValue) {
                    this.checkStep(1);
                }
            },
            cityTo: {
                get: function () {
                    return this.$store.getters['calculator/getCityTo'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityTo', newValue);
                    this.checkStep(1);
                }
            },
            cityToPostalCode: {
                get: function () {
                    return (this.$store.state.calculator.cityTo) ? this.$store.state.calculator.cityTo.postal_code : null;
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCityToPostalCode', newValue);
                    this.checkStep(1);
                }
            },
            cityToSuccess: {
                get: function () {
                    return this.$store.state.calculator.cityTo?.city_name && this.$store.state.calculator.cityTo?.country_code;
                },
                set: function (newValue) {
                    this.checkStep(1);
                }
            },
            cargoes: {
                get: function () {
                    return this.$store.getters['calculator/getCargoes'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setCargoes', newValue);
                }
            },
            addressFrom: {
                get() {
                    return this.$store.getters['calculator/getAddressFrom'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressFrom', newValue);
                }
            },
            addressTo: {
                get() {
                    return this.$store.getters['calculator/getAddressTo'];
                },
                set(newValue) {
                    this.$store.dispatch('calculator/setAddressTo', newValue);
                }
            },
            storedOrder: {
                get: function () {
                    return this.$store.getters['calculator/getOrder'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setOrder', newValue);
                }
            },
            tariffsIsLoading: {
                get: function () {
                    return this.$store.getters['calculator/getTariffsIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setTariffsIsLoading', newValue);
                }
            }
        },

        mounted() {
            // Привязываем к экрану шапку калькулятора
            var scrollCalculater = this.$refs.scrollCalculater;
            if (scrollCalculater) {
                var sticky = scrollCalculater.offsetTop;
                var self = this;
                window.addEventListener("scroll", () => {
                    if (self.step > 0) {
                        if (window.pageYOffset > sticky) {
                            scrollCalculater.classList.add("sticky");
                            scrollCalculater.classList.add("fadeInDown");
                        } else {
                            scrollCalculater.classList.remove("fadeInDown");
                            scrollCalculater.classList.remove("sticky");
                        }
                    }
                });
            }
            if (!_.isEmpty(this.order)) {
                this.storedOrder = this.order;

                this.addressFrom = {
                    city_fias_id: _.get(this.order.sender, 'city_fias_id', ''),
                    // city_id: _.get(this.order.sender, 'city_id', ''),
                    city_name: _.get(this.order.sender, 'city_name', ''),
                    city_name_with_type: _.get(this.order.sender, 'city_name_with_type', ''),
                    comment: _.get(this.order.sender, 'comment', ''),
                    company_name: _.get(this.order.sender, 'company_name', ''),
                    country_code: _.get(this.order.sender, 'country_code', ''),
                    country_name: _.get(this.order.sender, 'country_name', ''),
                    email: _.get(this.order.sender, 'email', ''),
                    first_name: _.get(this.order.sender, 'first_name', ''),
                    house: _.get(this.order.sender, 'house', ''),
                    flat: _.get(this.order.sender, 'flat', ''),
                    last_name: _.get(this.order.sender, 'last_name', ''),
                    phone: _.get(this.order.sender, 'phone', ''),
                    postal_code: _.get(this.order.sender, 'postal_code', ''),
                    region_name: _.get(this.order.sender, 'region_name', ''),
                    save: false,
                    street: _.get(this.order.sender, 'street', ''),
                    street_fias_id: _.get(this.order.sender, 'street_fias_id', '')
                };
                this.addressTo = {
                    city_fias_id: _.get(this.order.recipient, 'city_fias_id', ''),
                    // city_id: _.get(this.order.recipient, 'city_id', ''),
                    city_name: _.get(this.order.recipient, 'city_name', ''),
                    city_name_with_type: _.get(this.order.recipient, 'city_name_with_type', ''),
                    comment: _.get(this.order.recipient, 'comment', ''),
                    company_name: _.get(this.order.recipient, 'company_name', ''),
                    country_code: _.get(this.order.recipient, 'country_code', ''),
                    country_name: _.get(this.order.recipient, 'country_name', ''),
                    email: _.get(this.order.recipient, 'email', ''),
                    first_name: _.get(this.order.recipient, 'first_name', ''),
                    house: _.get(this.order.recipient, 'house', ''),
                    flat: _.get(this.order.recipient, 'flat', ''),
                    last_name: _.get(this.order.recipient, 'last_name', ''),
                    phone: _.get(this.order.recipient, 'phone', ''),
                    postal_code: _.get(this.order.recipient, 'postal_code', ''),
                    region_name: _.get(this.order.recipient, 'region_name', ''),
                    save: false,
                    street: _.get(this.order.recipient, 'street', ''),
                    street_fias_id: _.get(this.order.recipient, 'street_fias_id', '')
                };
            }
        }
    };

</script>
