<template>
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
                <label for="">
                    {{ trans('calculator.pickup_date') }}
                </label>
                <date-picker class="form-control"
                             v-model="pickup_date"
                             :config="pickUpDateConfig"
                             :class="{'validated-error': errors.has('pickup_date')}"
                ></date-picker>
                <div class="text-danger" v-if="errors.has('pickup_date')" v-html="errors.first('pickup_date')"></div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label for="">
                    {{ trans('app.labels.from') }}
                </label>
                <date-picker v-model="pickup_time_from"
                             :config="pickUpTimeFromConfig"
                             class="form-control"
                             :class="{'validated-error': errors.has('pickup_time_from')}"
                ></date-picker>
                <div class="text-danger" v-if="errors.has('pickup_time_from')" v-html="errors.first('pickup_time_from')"></div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group">
                <label for="">
                    {{ trans('app.labels.to') }}
                </label>
                <date-picker v-model="pickup_time_to"
                             :config="pickUpTimeToConfig"
                             class="form-control"
                             :class="{'validated-error': errors.has('pickup_time_to')}"
                ></date-picker>
                <div class="text-danger" v-if="errors.has('pickup_time_to')" v-html="errors.first('pickup_time_to')"></div>
            </div>
        </div>
        <div class="modal fade show alert-selected-date" id="cse-alert" tabindex="-1" role="dialog" :class="{'d-block': modalVisible}">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>У данного поставщика есть ограничение на даты забора. Ваш заказ попадет в базу. Вы получите накладную ближе к дате реализации.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal" @click="hideModal">Закрыть</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import moment from 'moment';
    export default {
        name: "PickupDateTime",
        inject: ['$validator'], // inherit validation scope from parent
        components: {
            datePicker
        },
        computed: {
            tariff: {
                get: function () {
                    return this.$store.getters['calculator/getTariff'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setTariff', newValue);
                }
            }
        },
        watch: {
            pickup_date(newVal, oldVal){
                let diffSelectedDate = Math.floor((new Date(newVal) - new Date()) / (1000*60*60*24));
                if (this.$attrs.courier_name === "cse" && diffSelectedDate > 14) {
                    this.modalVisible = true;
                    // document.querySelector('.alert-selected-date').classList.toggle('show');
                }

                if(newVal) {
                    let dayOfWeek = moment(newVal).day();
                    let enabledHours = this.pickUpTimeEnabledHours(dayOfWeek);
                    let pickUpTimeFromConfig = this.pickUpTimeFromConfig;
                    let pickUpTimeToConfig = this.pickUpTimeToConfig;

                    pickUpTimeFromConfig.enabledHours = enabledHours;
                    pickUpTimeToConfig.enabledHours = enabledHours;
                    this.pickUpTimeFromConfig = pickUpTimeFromConfig;
                    this.pickUpTimeToConfig = pickUpTimeToConfig;

                    this.initTimes(dayOfWeek);
                    this.setData(newVal);
                }
            },
            pickup_time_from(newVal, oldVal){
                this.checkData(this.pickup_date, newVal, this.pickup_time_to);
                this.setData(this.pickup_date);
            },
            pickup_time_to(newVal, oldVal){
                this.checkData(this.pickup_date,this.pickup_time_from ,newVal);
                this.setData(this.pickup_date);
            },
            availableDays: {
                handler(val) {
                    this.initConfig();
                },
                deep: true
            }
        },
        data:function (){
            return {
                modalVisible: false,
                validationErrorsData: this.validationErrors,
                pickup_date: null,
                pickup_time_from: null,
                pickup_time_to: null,
                pickUpDateConfig: {
                    format: 'YYYY-MM-DD',
                    daysOfWeekDisabled: [],
                    locale: 'ru',
                    minDate: moment()
                },
                pickUpTimeFromConfig: {
                    format: 'HH:mm',
                    locale: 'ru',
                    enabledHours: [9,10,11,12,13,14,15,16,17,19]
                },
                pickUpTimeToConfig: {
                    format: 'HH:mm',
                    locale: 'ru',
                    enabledHours: [9,10,11,12,13,14,15,16,17,19]
                }
            }
        },
        props: {
            value:{
                default: {
                    pickup_date: null,
                    pickup_time_from: null,
                    pickup_time_to: null,
                    courier_name: null
                }
            },
            availableDays: {
                default: () => [
                    {
                        'dayOfWeek': 0, //sunday
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 1,
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 2,
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 3,
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 4,
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 5,
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    },
                    {
                        'dayOfWeek': 6, //saturday
                        'hours': {
                            'from':'9',
                            'to':'18'
                        }
                    }
                ]
            },
            validationErrors: {
                default: () => {
                    return {
                    }
                }
            }
        },
        mounted(){
            this.initTimes();
            this.initConfig();
        },
        methods: {
            hideModal() {
                this.modalVisible = false;
            },
            initTimes(dayOfWeek = moment().day()){
                let day = this.getNextBusinessDay(dayOfWeek);
                this.pickup_time_from = moment(day.hours.from + ":00", 'HH:mm').format('HH:mm');
                this.pickup_time_to = moment((day.hours.to-1) + ":00", 'HH:mm').format('HH:mm');
            },
            initConfig(){
                let date = this.pickUpDateDefault();
                this.pickup_date = date;
                let enabledHours = this.pickUpTimeEnabledHours();
                this.pickUpDateConfig = {
                    format: 'YYYY-MM-DD',
                    daysOfWeekDisabled: this.getDisabledDayOfWeeks(),
                    locale: 'ru',
                    minDate: moment().subtract(1, "days")
                };
                this.pickUpTimeFromConfig =  {
                    format: 'HH:mm',
                    locale: 'ru',
                    enabledHours: enabledHours
                };
                this.pickUpTimeToConfig = {
                    format: 'HH:mm',
                    locale: 'ru',
                    enabledHours: enabledHours
                }
            },
            setData(val){
                let date = moment(val).format('YYYY-MM-DD');
                let pickup_time_from = moment(date + " " + this.pickup_time_from);
                let pickup_time_to = moment(date + " " + this.pickup_time_to);

                this.$emit('input', {
                    pickup_date: moment(val),
                    pickup_time_from: pickup_time_from,
                    pickup_time_to: pickup_time_to
                });
            },
            checkData(datePickUp, fromTime, toTime){
                if(!datePickUp || !fromTime || !toTime) return false;
                this.validationErrorsData = {};
                let date = moment(datePickUp).format('YYYY-MM-DD');

                let new_pickup_time_from = moment(date + " " + fromTime);
                let new_pickup_time_to = moment(date + " " + toTime);

                let duration = moment.duration(new_pickup_time_to.diff(new_pickup_time_from));
                let hours = duration.asHours();
                if(hours<3){
                    this.systemMessage("error",
                        {
                            text: this.trans('calculator.diff_less_then_3_hours')
                        });
                    this.validationErrorsData = {
                        pickup_time_to: [ this.trans('calculator.diff_less_then_3_hours')],
                        pickup_time_from: [this.trans('calculator.diff_less_then_3_hours')]
                    };
                    return false;
                }
                return true;
            },
            pickUpDateMinDefault(){
                return this.getNextBusinessDay();
            },
            getDisabledDayOfWeeks(){
                let result = [0,1,2,3,4,5,6];
                for(let i=0; i<this.availableDays.length; i++){
                    let index = result.indexOf(parseInt(this.availableDays[i].dayOfWeek));
                    result.splice(index, 1);
                }
                return result;
            },
            getDayOfWeek(dayOfWeek){
                let result = null;
                for(let i=0; i<this.availableDays.length; i++){
                    if(this.availableDays[i].dayOfWeek === dayOfWeek){
                        result = this.availableDays[i];
                    }
                }
                return result;
            },
            getNextBusinessDay(dayOfWeek, daysIncrement = 0){
                let result = {};
                let getNextDay = false;
                let availableDay = this.getDayOfWeek(dayOfWeek);
                if(availableDay){
                    if (moment().day()===dayOfWeek
                        && (moment().format('H') >= availableDay.hours.to
                            || moment().format('H') <= availableDay.hours.from)) {
                        getNextDay = true;
                    } else {
                        result = availableDay;
                        result.daysInc = daysIncrement;
                    }
                }else {
                    getNextDay = true;
                }

                if(getNextDay){
                    let daysInc = daysIncrement + 1;

                    let nextDay = dayOfWeek;
                    if(nextDay===6) nextDay = 0;
                    else
                        nextDay++;
                    result = this.getNextBusinessDay(nextDay, daysInc);
                }
                return result;
            },
            pickUpDateDefault(){
                let day = this.getNextBusinessDay(moment().day()),
                    result = null;

                if(!_.isEmpty(this.value.pickup_date)){
                    result = this.value.pickup_date;
                }
                else
                    result = moment().add(day.daysInc, 'day');
                return result;
            },
            pickUpTimeEnabledHours(dayOfWeek = moment().day()){
                let day = this.getNextBusinessDay(dayOfWeek);
                return _.range(day.hours.from, day.hours.to);
            }
        }
    }
</script>

<style scoped>

</style>
