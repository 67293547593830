import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'

Vue.component('order-create', {
    data() {
        return {
            form: {
                sender:{},
                recipient:{},
                document:{},
                cargo:{}
            },
            link: '/admin/orders',
        }
    },
    mixins: [MixinsCreate]
});
