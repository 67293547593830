<template>
    <div class="card-body">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-10 col-sm-8 col-xl-12 col-custom1920-8 col-custom2200-7">
                <div class="row mb-3 mt-5 mx-auto col-xl-8">
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 px-12 mb-1">
                        <button class="btn btn-outline-info btn-block" v-bind:class="{'active':(orderType==='speed')}"
                                @click="orderType='speed'">
                            {{ trans('calculator.sort_by_speed') }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-6 col-md-12 col-lg-6 px-7 mb-1">
                        <button class="btn btn-outline-info btn-block" v-bind:class="{'active':(orderType==='price')}"
                                @click="orderType='price'">
                            {{ trans('calculator.sort_by_price') }}
                        </button>
                    </div>
                    <div class="row align-items-center mx-auto col-12">
                        <div class="col-xl-4 text-center mb-1">
                            <div class="small text-muted">
                                {{ trans('calculator.sender.city') }}
                            </div>
                            <h4 v-if="cityTo" class="font-weight-bold">
                                {{ cityFrom.city_name }}
                            </h4>
                        </div>
                        <div class="col-xl-4 text-center mb-1">
                            <i class="la la-archive"></i>
                            <div v-if="cargoes && cargoes.type === 'documents'">
                                {{ trans('calculator.documents') }}
                            </div>
                            <div v-else>
                                {{ trans('calculator.cargo') }}
                            </div>
                            <div>
                                {{ trans('calculator.weight') }}: {{ getWeight() }} кг
                            </div>
                        </div>
                        <div class="col-xl-4 text-center mb-1">
                            <div class="small text-muted">
                                {{ trans('calculator.recipient.city') }}
                            </div>
                            <h4 v-if="cityTo" class="font-weight-bold">
                                {{ cityTo.city_name }}
                            </h4>
                        </div>
                    </div>
                </div>
                <!--                     Tariffs -->
                <template v-for="tariffElement in tariffsWithLimit">
                    <order-tariff-card :tariff-element="tariffElement"></order-tariff-card>
                </template>
                <!-- Tariffs -->
                <div v-if="showLimit < tariffsList.length" class="mt-3 text-center">
                    <a class="btn btn-info" @click="showMore()">
                        {{ trans('app.labels.more') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectTariff from "../../../calculator/components/SelectTariff";
    import OrderTariffCard from "../../../calculator/components/partials/TariffCard";
    export default {
        name: "OrderSelectTariff",
        extends: SelectTariff,
        components: {OrderTariffCard},
        props: {
            form: {
                default: () => []
            },
        },
        mounted: function(){
        },
        watch: {
            step(newValue, oldValue) { // проверка шага
                if (newValue === 2) {
                    this.getTariffs();
                } else if (newValue === 1) {
                    this.value = [];
                    this.loads = [];
                    this.validateErrors = {};
                    this.weight = 0;
                    this.tariffsList = [];
                    this.showLimit = 4;
                    this.tariffsWithLimit = [];
                    this.orderType = "speed";
                }
            },
        }
    }
</script>

<style scoped>

</style>
