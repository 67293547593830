import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('courier_service-index', {
    data() {
        return {
            link: '/admin/courier_services',
        }
    },
    methods : {
        massClearCache(selected = null) {
            if (!selected) {
                selected = this.selected;
            }
            this.$bvModal.msgBoxConfirm(this.trans('crud.confirmation.message'),
                {
                    okTitle: this.trans('crud.confirmation.yes'),
                    cancelTitle: this.trans('crud.confirmation.cancel'),
                })
                .then(value => {
                    if (value) {
                        axios.post(this.link + '/cache/clear', {
                            selected: selected
                        })
                            .then(response => {
                                this.selected = [];
                                this.allSelected = false;
                                this.getData()
                                this.systemMessage('success',{
                                    'title':this.trans('crud.actions.info'),
                                    'text':this.trans('crud.actions.success.delete')
                                });
                            })
                            .catch(error => {
                                this.systemMessage('error',{
                                    'title':this.trans('crud.actions.warning'),
                                    'text':this.trans('crud.actions.fail.delete')
                                });
                            })
                    }
                })
                .catch(err => {

                });
        },
        booleanToText(value = null) {
            if (value == 0) {
                return this.trans('app.false')
            }
            else if (value == 1) {
                return this.trans('app.true')
            }
        }
    },
    mixins: [MixinsIndex]
});
