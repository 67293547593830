import MixinsIndex from '../../../vendor/codersstudio/crud/mixins/index.js'

Vue.component('city-index', {
    data() {
        return {
            link: '/admin/cities',
        }
    },
    mixins: [MixinsIndex],
    props: {
        courierServices : {
            default: () => {
                return []
            }
        }
    },
    methods: {
        getCachedCityId(service, city) {
            let cached_city_id = null;
            let foundedService = _.find(city.courier_services, ['id', service.id]);
            if (foundedService !== undefined) {
                cached_city_id = foundedService.pivot.cached_city_id;
            }
            return cached_city_id;
        }
    }
});
