<template>
    <multiselect
        ref="search"
        v-model="selectedValue"
        :options="results"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="placeholder"
        open-direction="bottom"
        label="label"
        track-by="id"
        class="address-autocomplete-input"
        :preselect-first="true"
        @search-change="getData"
        @select="onSelect"
        :show-labels="true"
        deselectLabel= "x"
        selectLabel= ""
        selectedLabel=""
        :hide-selected="true"
        :show-pointer:="false"
        :internal-search="false"
        :showNoResults="false"
        :allowEmpty="false"
    >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} options selected</span>
        </template>
        <template slot="singleLabel" slot-scope="props">{{props.option.name_with_type}}</template>
        <template slot="option" slot-scope="props">
            <div class="option__desc"><span class="option__title m-1">{{props.option.label}}</span></div>
        </template>
        <template slot="noOptions">
            <span class="m-1">{{trans('calculator.start_enter')}}</span>
        </template>
        <template slot="noResult">
            <span class="m-1">{{trans('calculator.manual_found')}} </span>
        </template>
    </multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    export default {
        name: "address-autocomplete",
        components: {
            Multiselect
        },
        data(){
            return {
                selectedValue: this.value,
                results: [],
                isLoading: false
            }
        },
        props: {
            refresh: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: ""
            },
            value: {
                default: ""
            },
            type: {
                type: String,
                /**
                 * Possible values
                 * country city street house
                 */
                default: "country"
            },
            searchBy: {
                type: String,
                /** TYPE  | street      | house             | city        | country
                 *  VALUE | region_name | fias_id of street | country ISO | empty
                 */
                default: ""
            }
        },
        watch:{
            value(val) {
                if (this.type === 'address') {
                    this.getData(val.label,() =>{
                        this.selectedValue = this.results[0];
                    });
                }
            },
            selectedValue(val) { //Для v-model в родитель
                if(!_.isEmpty(val) && !_.isEmpty(val.name_with_type)){
                    this.$refs.search.search = val.name_with_type;
                    this.$refs.search.$refs.search.selectionEnd = this.$refs.search.$refs.search.selectionStart = val.name_with_type.length;
                    this.$emit('input', val, this.results);
                }
            },
            refresh(val) {
                if (Boolean(val)) {
                    this.$set(this, 'selectedValue', "");
                    this.$set(this, 'results', []);
                }
            },
        },
        methods: {
            onSelect(selectedOption, id){

            },
            setResultData(data, query){
                this.results = [];

                // allow user input for not founded streets and houses
                if (this.type === "street" || this.type === "house") {
                    let value = {
                        'id': '',
                        'name': query,
                        'name_with_type': query,
                        'name_without_type': query,
                        'label': query,
                        'fias_id': '',
                        'postal_code': '',
                        'region_name': '',
                    };
                    this.results.push(value);
                    //this.selectedValue = value;
                }

                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    let name = "";
                    let name_with_type = "";
                    let name_without_type = "";
                    let settlement = "";
                    let settlement_with_type = "";
                    let settlement_fias_id = "";
                    let region_name = "";
                    let fias_id = "";
                    let postal_code = (item.attributes.postal_code)?item.attributes.postal_code:"";
                    if(this.type === "city") {
                        name = (item.attributes.settlement)?item.attributes.settlement:item.attributes.city;
                        if(!name || item.attributes.street) continue; //проверяем чтобы улица тоже не залетала
                        name_with_type = (item.attributes.settlement_with_type)?item.attributes.settlement_with_type:item.attributes.city_with_type;
                        fias_id = (item.attributes.city_fias_id)?item.attributes.city_fias_id:item.attributes.settlement_fias_id;
                        region_name = (item.attributes.region_with_type)?item.attributes.region_with_type:"";
                    }
                    else if(this.type === "street") {
                        name = item.attributes.street;
                        name_with_type = item.attributes.street_with_type;
                        fias_id = item.attributes.street_fias_id;
                        settlement = item.attributes.settlement;
                        settlement_with_type = item.attributes.settlement_with_type;
                        settlement_fias_id = item.attributes.settlement_fias_id;
                        //if(!postal_code) continue;
                    }
                    else if(this.type === "house"){
                        name = item.attributes.house;
                        fias_id = item.attributes.house_fias_id;
                        let block = item.attributes.block;
                        let block_type = item.attributes.block_type;
                        name_with_type = item.attributes.house_type + " " + name + ((block)? " " + block_type + " " + block:"");
                        if(!postal_code) continue;
                    }else if(this.type === "address"){
                        fias_id = item.attributes.house_fias_id ? item.attributes.house_fias_id : item.attributes.fias_id;
                        name_with_type = ((item.attributes.street_with_type)?item.attributes.street_with_type+', ':'')+(item.attributes.house ? (item.attributes.house_type+
                            item.attributes.house+(item.attributes.block ? ' '+item.attributes.block_type+ ' '+ item.attributes.block : '')) : '');

                        if(name_with_type) name = name_with_type+', '+item.attributes.city+', '+item.attributes.country;
                    }else{
                        name = item.attributes.country_iso_code;
                        name_with_type = item.attributes.country_iso_code + " " + item.attributes.country;
                        name_without_type = item.attributes.country;
                    }
                    if(!_.isEmpty(name)) {
                        let itemId = (item.id) ? item.id : fias_id;
                        this.results.push({
                            'id': itemId,
                            'name': name,
                            'name_with_type': name_with_type.toUpperCase(),
                            'name_without_type': name_without_type,
                            'label': ((postal_code) ? postal_code + " " : "") + name_with_type,
                            'fias_id': fias_id,
                            'postal_code': item.attributes.postal_code,
                            'region_name': region_name,
                            'settlement': settlement,
                            'settlement_with_type': settlement_with_type,
                            'settlement_fias_id': settlement_fias_id,
                            'street': item.attributes.street_with_type ? item.attributes.street_with_type : null,
                            'house': item.attributes.house ? item.attributes.house_type + ' ' + item.attributes.house
                                + (item.attributes.block ? ' ' + item.attributes.block_type + ' ' +
                                    item.attributes.block : '') : null,
                        });
                    }
                }
            },
            getData(query, callback) {
                if(_.size(query) < 1 || this.isLoading) {
                    return;
                }
                let type = "countries";
                let params = {
                    'query': query
                };
                if(this.type === "city") {
                    type = "cities";
                    params.country = this.searchBy;
                }
                else if(this.type === "street") {
                    type = "streets";
                    params.fias_id = this.searchBy;
                }
                else if(this.type === "house"){
                    type = "houses";
                    params.fias_id = this.searchBy;
                }else if(this.type === "address"){
                    type = "addresscomplex";
                    params.fias = this.$store.state.calculator.dostavistaFias;
                    params.query = query;
                }

                if(type!=="countries" && type!=="addresscomplex" && !this.searchBy) {
                    // allow user input for not founded streets and houses
                    if (this.type === "street" || this.type === "house") {
                        this.results = [];
                        let value = {
                            'id': '',
                            'name': query,
                            'name_with_type': query,
                            'name_without_type': query,
                            'label': query,
                            'fias_id': '',
                            'postal_code': '',
                            'region_name': '',
                        };
                        this.results.push(value);
                        this.selectedValue = value;
                    }
                    return;
                } // не отправляем запрос без уточнения

                this.isLoading = true;
                axios.get('/geocoding/' + type, {
                    params: params
                }).then(response => {
                        // handle success
                        if (response.data.data) {
                            this.setResultData(response.data.data, query);
                            if(callback) callback(this.cities);
                        }
                }).catch(error => {
                        console.log(error);
                        //this.systemMessage("error");
                }).finally(() => {
                    this.isLoading = false;
                });
            },
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.address-autocomplete-input .multiselect__element{
     margin-left: 0 !important;
     padding-left: 0 !important;
 }
</style>
