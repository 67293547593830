/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
window.Switchery = require('switchery');
require('../vendors/js/ui/unison.min.js');


$( document ).ready(function() {
	window.PerfectScrollbar = require('perfect-scrollbar').default;
	require('./chameleon/core/app.js');
	require('./chameleon/jquery.sharrre.min.js');
	require('./chameleon/core/app-menu.js');
	require('./chameleon/scripts/customizer-lite.js');
	require('./chameleon/scripts/forms/switch.js');
	require('../vendors/js/forms/icheck/icheck.min.js');
    // require('../vendors/js/vendors.min.js');
	require('./chameleon/scripts/forms/checkbox-radio.js');
});

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('user-service-tweak-card', require('./components/admin/user/components/UserServiceTweakCard.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/**
 * Require Section
  */
require('./vendor/codersstudio/acl/index');
require('./vendor/codersstudio/crud/index');
require('./vendor/codersstudio/adminprofile/edit.js');
require('./components/contact/index.js');

/**
 * Import Section
 */
import store from './store';
import ToggleButton from 'vue-js-toggle-button';
import CalculatorIndex from './components/calculator/Index';
import Spinner from './components/spinner/Index';
import Csnotifications from '../assets/vendor/codersstudio/js/components/CsNotifications';
import AddressAutocomplete from './components/addressAutocomplete/Index';
import GmapCourierTracking from './components/dostavista/components/GmapCourierTracking';
import Multiselect from 'vue-multiselect';
import * as VueGoogleMaps from 'vue2-google-maps';
import Register from './components/register/Index';

Vue.use(ToggleButton);
Vue.use(VueGoogleMaps, {
    load: {
        region: 'RU',
        language: 'ru',
        key: process.env.MIX_GOOGLE_API_KEY,
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});

const app = new Vue({
    el: '#app',
    store,
    components: {
        CalculatorIndex,
        Csnotifications,
        Spinner,
        AddressAutocomplete,
        Multiselect,
        GmapCourierTracking,
        Register
    },
    data(){
        return {
            'spinnerText': '',
            'cookieWarning': false
        };
    },
    mounted() {
        this.$store.dispatch('user/setUser', {
            user: window.user
        });
        this.spinnerText = this.trans('app.loading');
        this.getModal();

        this.cookieWarning = localStorage.getItem('cookieWarning') !== 'N';
    },
    methods: {
        getModal() {
            if(typeof user !== 'undefined') {
                axios.get('/')
                    .then(response => {
                        $("#poll").modal('show');
                    });
            }
        },
        hideCookieWarning() {
            this.cookieWarning = false;

            localStorage.setItem('cookieWarning', 'N');
        }
    },
    watch:{
        step(val){
            if(val===2) {
                this.spinnerText = this.trans('calculator.loading');
            }else{
                this.spinnerText = this.trans('app.loading');
            }
        }
    },
    computed:{
        step: {
            // Текущая страница калькулятора может
            // 0 - это заглавная форма,
            // 1- выбор типа отправления,
            // 2 - выбор тарифа,
            // 3 - Оформление заявки
            get: function () {
                return this.$store.getters['calculator/getStep'];
            },
            set: function (newValue) {
                this.$store.dispatch('calculator/setStep', newValue);
            }
        }
    }
});
