import Multiselect from 'vue-multiselect';
import Number from '../../../../components/fields/Number'
export default {
    name: 'MixinsEdit',
    components: {
        Multiselect, Number
    },
	data() {
        return {
            form: {},
            loading: false,
            mediaCollections: {},
            link: null,
            getParams: ''
        }
    },
    props: {
        data: {
            default: () => {
                return {}
            }
        }
    },
    methods: {
	    preStore() {},
        store() {
	        this.preStore();
            if (this.loading) {
                return;
            }

            // check frontend errors
            this.errors.clear(null);
            if (this.errors.count('frontend')) {
                _.forEach(this.errors.collect(), ((item, index) => {
                    this.errors.add({
                        field: index,
                        msg: this.errors.first(index, 'frontend')
                    });
                }));
                return;
            }

            this.getMedia();
            this.loading = true;
            this.isLoading = true;
            axios.post(this.link, this.form)
            .then(response => {
                window.location.href = this.link + this.getParams;
                this.systemMessage('success',{
                    'title':this.trans('crud.actions.info'),
                    'text':this.trans('crud.actions.success.create')
                });
            })
            .catch(error => {
                this.systemMessage('error',{
                    'title':this.trans('crud.actions.warning'),
                    'text':this.trans('crud.actions.fail.create')
                });
                _.forEach(error.response.data.errors, (item, index) => {
                    this.errors.add({
                        field: index,
                        msg: _.head(item)
                    });
                });
            })
            .finally(() => {
                this.loading = false;
                this.isLoading = false;
            });
        },
        getMedia() {
            _.forEach(this.$refs, (item, index) => {
                if (item.$refs.dropzone) {
                    this.$set(this.mediaCollections, index, item.$refs.dropzone.getAcceptedFiles());
                }
            });
            this.form = Object.assign({
                'mediaCollections': this.mediaCollections
            }, this.form);
        },

    },
    computed: {
        isLoading: {
            get: function () {
                return this.$store.getters['spinner/getIsLoading'];
            },
            set: function (newValue) {
                this.$store.dispatch('spinner/setIsLoading', newValue);
            }
        }
    }

}
