import {cloneObject} from "vue-flatpickr-component/src/util";

export default {
    name: 'CityTimeRestrictionsMixin',
    data() {
        return {
            cityFilterMultiselect: [],
            cityTimeRestriction: {
                weekdays: [],
                cities: [],
                pickup_time_from: '',
                pickup_time_to: '',
            },
        }
    },
    methods: {
        saveCityTimeRestriction() {
            if (
                this.cityTimeRestriction.cities.length &&
                this.cityTimeRestriction.weekdays.length &&
                this.cityTimeRestriction.pickup_time_from &&
                this.cityTimeRestriction.pickup_time_to
            ) {
                _.forEach(this.cityTimeRestriction.cities, (val) => {
                    _.forEach(this.cityTimeRestriction.weekdays, (weekday) => {
                        let exists = _.filter(this.form.city_pickup_restrictions, (o) => {
                            return o.city.id === val.id && o.weekday.id === weekday.id;
                        });
                        if (exists.length) {
                            exists[0].pickup_time_from = this.cityTimeRestriction.pickup_time_from;
                            exists[0].pickup_time_to = this.cityTimeRestriction.pickup_time_to;
                        } else {
                            this.form.city_pickup_restrictions.push({
                                'id' : _.uniqueId('new_'),
                                'pickup_time_from' : this.cityTimeRestriction.pickup_time_from,
                                'pickup_time_to' : this.cityTimeRestriction.pickup_time_to,
                                'city' : val,
                                'weekday' : weekday
                            });
                        }
                    });

                });
                this.cityTimeRestriction = {
                    weekdays: [],
                    cities: [],
                    pickup_time_from: '',
                    pickup_time_to: '',
                };
                $("#city-time-restrictions-modal").modal('hide');
            }
        },
        removeRestriction(id) {
            this.form.city_pickup_restrictions = _.filter(this.form.city_pickup_restrictions, o => {
                return o.id != id;
            });
        },
        editRestriction(id) {
            let restrictions = _.filter(this.form.city_pickup_restrictions, o => {
                return o.id == id;
            });
            Vue.set(this.cityTimeRestriction, 'pickup_time_from', restrictions[0].pickup_time_from);
            Vue.set(this.cityTimeRestriction, 'pickup_time_to', restrictions[0].pickup_time_to);
            Vue.set(this.cityTimeRestriction, 'cities',[restrictions[0].city]);
            Vue.set(this.cityTimeRestriction, 'weekdays',[restrictions[0].weekday]);
            $("#city-time-restrictions-modal").modal('show');
        }
    },
    computed: {
        cityFilter() {
            return _.map(this.cityFilterMultiselect, 'id');
        },
        filteredCityPickupRestrictions() {
            if (this.cityFilter.length) {
                return _.filter(this.form.city_pickup_restrictions, o => {
                    return _.includes(this.cityFilter, o.city.id);
                });
            } else {
                return this.form.city_pickup_restrictions;
            }

        },
        complete() {
            return this.cityTimeRestriction.cities.length &&
                this.cityTimeRestriction.weekdays.length &&
                this.cityTimeRestriction.pickup_time_from &&
                this.cityTimeRestriction.pickup_time_to;
        }
    }
}
