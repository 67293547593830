<template>
    <div class="form-group">
        <label :for="id" class="label">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <vue-tel-input
            :input-id="id"
            v-model="phoneValue"
            :name="name"
            v-on:input="onInputTransit"
            :placeholder="placeholder"
            mode="international"
            :enabledCountryCode="false"
            class="form-control"
            :class="{'validated-error': errors.has(name)}"
            :disabled="disabled"
            :onlyCountries="onlyCountries"
            :preferredCountries="preferredCountries"
            :defaultCountry="defaultCountry"
            :valid-characters-only="true"
            :input-options="{ showDialCode: false, tabindex: 0 }"
            :dropdown-options="{ disabledDialCode: false, tabindex: 0 }"
            :autofocus="false"
        ></vue-tel-input>
        <div class="text-danger" v-if="errors.has(name)" v-html="errors.first(name)"></div>
    </div>
</template>

<script>
    import BaseField from "./mixins/BaseField";

    export default {
        name: "PhoneField",
        mixins: [BaseField],
        data() {
            return {
                'phoneValue': this.value
            };
        },
        props: {
            defaultCountry:{
                default: "RU"
            },
            onlyCountries: {
                default: () => {
                    return []
                }
            },
            preferredCountries: {
                default: () => {
                    return ['RU', 'BY', 'KZ', 'EN', 'FR']
                }
            },
        },
        methods: {
            onInputTransit(phone, obj) {
                if (Boolean(obj.isValid)) {
                    this.errors.remove(this.name, 'frontend');
                } else {
                    this.errors.add({
                        field: this.name,
                        msg: this.trans('validation.frontend.phone'),
                        scope: 'frontend'
                    });

                    if (obj.possibility === 'too-long') {
                        let phoneNumber = String(this.phoneValue);
                        this.phoneValue = phoneNumber.slice(0, -2);
                        console.log('isValid slice', this.phoneValue);
                    }
                }

                this.$emit('input', this.phoneValue);
            }
        },
        mounted() {
            Vue.nextTick(() => {
                this.phoneValue = this.value;
            });
        }
    }
</script>

<style scoped>

</style>
