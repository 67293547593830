<template>
    <div v-if="this.data.check && (hasRole('client_entity') || hasRole('client_individual'))">
        <div class="form-group">
            <!-- Button trigger modal -->
            <!--<button type="button" class="btn btn-info " data-toggle="modal" data-backdrop="false" data-target="#myModalLabel11">
                Тест
            </button>-->
            <!-- Modal -->
            <div class="modal fade text-left" id="poll" tabindex="-1" ref="pollDialog" role="dialog" aria-labelledby="myModalLabel11"
                 aria-hidden="true" style="display: none;">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header bg-info white">
                            <h4 class="modal-title white" id="myModalLabel11"></h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form class="form">
                                <h4 class="form-section">
                                    <i class="fab fa-accusoft"></i> {{trans('app.poll.title')}}
                                </h4>
                                <div class="pb-2">{{trans('app.poll.please_answer')}}</div>
                                <div v-for="poll in this.data.questions" class="form-group">
                                    <label>
                                        {{ poll.title }}
                                    </label>
                                    <div v-if="poll.type === 'variable'">
                                        <multiselect
                                            v-model="form[poll.name]"
                                            :options="poll.answers"
                                            label="title"
                                            :searchable="false"
                                            :show-labels="false"
                                            :close-on-select="true"
                                            class="form-control multiselect"
                                            placeholder="">
                                        </multiselect>
                                    </div>
                                    <div v-if="poll.type === 'text'">
                                        <textarea
                                            v-model="form[poll.name].title"
                                            class="form-control">
                                        </textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn grey btn-secondary" data-dismiss="modal">
                                {{trans('crud.buttons.close')}}
                            </button>
                            <button type="button" @click="save" class="btn btn-info">
                                {{trans('crud.buttons.save')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        name: "poll",
        components: {
            Multiselect
        },
        data() {
            return {
                form: {},
                data: {},
                link: '/poll'
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                if(typeof user !== 'undefined') {
                    axios.get(this.link)
                        .then(response => {
                            this.data = response.data.data;
                            this.form = response.data.data.user_answers ? response.data.data.user_answers : {};
                            $("#poll").modal('show');
                        })
                        .catch(error => {
                            this.systemMessage('error', {
                                'title': this.trans('crud.actions.warning'),
                                'text': this.trans('crud.actions.fail.edit')
                            });
                            _.forEach(error.response.data.errors, (item, index) => {
                                this.errors.add({
                                    field: index,
                                    msg: _.head(item)
                                });
                            });
                        })
                }
            },
            save() {
                this.form.user_id = this.data.user;
                this.isLoading = true;
                $("#poll").modal('hide');
                axios.put(this.link + '/' + this.data.user, this.form)
                    .then(response => {

                        this.systemMessage('success',{
                            'title':this.trans('crud.actions.info'),
                            'text':this.trans('crud.actions.success.edit')
                        });
                    })
                    .catch(error => {
                        $("#poll").modal('show');
                        this.systemMessage('error',{
                            'title':this.trans('crud.actions.warning'),
                            'text':this.trans('crud.actions.fail.edit')
                        });
                        console.log(error);
                        _.forEach(error.response.data.errors, (item, index) => {
                            this.errors.add({
                                field: index,
                                msg: _.head(item)
                            });
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        computed: {
            isLoading: {
                get: function () {
                    return this.$store.getters['spinner/getIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('spinner/setIsLoading', newValue);
                }
            }
        }

    }

</script>

<style scoped>
    .multiselect.form-control.multiselect {
        padding: 0;
    }
</style>
