<template>
    <div class="container px-0" id="scroll">
        <div class="row justify-content-left">
            <div class="col-12">
                <div class="card">
                    <div class="card-body px-0 px-sm-1">
                        <div class="card-content collapse show card border_default mx-1 mx-lg-0">
                            <div class="px-0 my-1">
                                <div class="form-group d-flex col-12 col-md-7 col-lg-5 col-xl-3">
                                    <!--                                    Manual city select-->
                                    <!--                                    <i class="fas fa-map-marker-alt pr-1 pt-1 font-size-large "></i>-->
                                    <!--                                    <select class="form-control" v-model="cityFias">-->
                                    <!--                                        <option v-for="(fias, name) in dostavistaCities" :value="fias">{{ name }}-->
                                    <!--                                        </option>-->
                                    <!--                                    </select>-->
                                </div>
                            </div>
                            <div class="py-2 px-1">
                                <ul class="nav nav-tabs pb-1">
                                    <li class="nav-item text-center col-12 col-lg-auto">
                                        <div class="nav-link cursor-pointer" id="base-on_foot" @click="setVehicles(6)"
                                             :class="{'active': id==6 }">
                                            {{ trans('calculator.dostavista.on_foot') }}
                                        </div>
                                    </li>
                                    <li class="nav-item text-center col-12 col-lg-auto">
                                        <div class="nav-link cursor-pointer" id="base-car" @click="setVehicles(7)"
                                             :class="{'active': id==7 }">
                                            {{ trans('calculator.dostavista.car') }}
                                        </div>
                                    </li>
                                    <li class="nav-item text-center col-12 col-lg-auto">
                                        <div class="nav-link cursor-pointer" id="base-truck" @click="setVehicles(1)"
                                             :class="{'active': 1<=id&&id<5 }">
                                            {{ trans('calculator.dostavista.truck') }}
                                        </div>
                                    </li>
                                </ul>
                                <div class="px-1">
                                    <div class="tab-pane" v-if="id==6" :class="{'active': id==6 }">
                                        <div class="row pt-2" v-if="weight<=1">
                                            <div class="">
                                                <button type="button"
                                                        class="btn btn-outline-danger btn-min-width mr-1 mb-1"
                                                        :class="{'active':!discount}"
                                                        @click="setDiscount(0)">
                                                    <div class="">{{ trans('calculator.dostavista.0%') }}</div>
                                                    <div class="font-size-xsmall">{{
                                                        trans('calculator.dostavista.exact_time') }}
                                                    </div>
                                                </button>
                                                <button type="button"
                                                        class="btn btn-outline-danger btn-min-width mr-1 mb-1"
                                                        :class="{'active':discount}"
                                                        @click="setDiscount(1)">
                                                    <div class="">{{ trans('calculator.dostavista.-20%') }}</div>
                                                    <div class="font-size-xsmall">{{
                                                        trans('calculator.dostavista.4_hours') }}
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" v-if="1<=id&&id<5" :class="{'active': 1<=id&&id<5 }">
                                        <div class="row pb-1 mb-1">
                                            <div class="dv-order-image-tabs__tab" @click="setVehicles(1)"
                                                 :class="{'active': id==1 }">
                                                <div class="dv-order-image-tabs__icon">
                                                    <img src="/images/dostavista/icon-pickup.png"
                                                         title="">
                                                </div>
                                                <div class="dv-order-image-tabs__description">
                                                    <span>{{ trans('calculator.dostavista.pickup') }}</span>
                                                    <p v-html="trans('calculator.dostavista.weight_up_to_500')"></p>
                                                </div>
                                            </div>
                                            <div class="dv-order-image-tabs__tab" @click="setVehicles(2)"
                                                 :class="{'active': id==2 }">
                                                <div class="dv-order-image-tabs__icon">
                                                    <img src="/images/dostavista/icon-kabluk.png"
                                                         title="">
                                                </div>
                                                <div class="dv-order-image-tabs__description">
                                                    <span>{{ trans('calculator.dostavista.heel') }}</span>
                                                    <p v-html="trans('calculator.dostavista.weight_up_to_700')"></p>
                                                </div>
                                            </div>
                                            <div class="dv-order-image-tabs__tab" @click="setVehicles(3)"
                                                 :class="{'active': id==3 }">
                                                <div class="dv-order-image-tabs__icon">
                                                    <img src="/images/dostavista/icon-porter.png"
                                                         title="">
                                                </div>
                                                <div class="dv-order-image-tabs__description">
                                                    <span>{{ trans('calculator.dostavista.porter') }}</span>
                                                    <p v-html="trans('calculator.dostavista.weight_up_to_1000')"></p>
                                                </div>
                                            </div>
                                            <div class="dv-order-image-tabs__tab" @click="setVehicles(4)"
                                                 :class="{'active': id==4 }">
                                                <div class="dv-order-image-tabs__icon">
                                                    <img src="/images/dostavista/icon-gazel.png" title="">
                                                </div>
                                                <div class="dv-order-image-tabs__description">
                                                    <span>{{ trans('calculator.dostavista.gazelle') }}</span>
                                                    <p v-html="trans('calculator.dostavista.weight_up_to_1500')"></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-sm-1">
                        <div class="card offer-form__card" v-if="!user">

                                <div class="col-lg-12 px-3 pt-2">
                                    <text-field
                                        :required="true"
                                        v-model="data.email"
                                        :label="trans('calculator.email')"
                                        type="email"
                                        name="email"
                                    ></text-field>
                                </div>

                        </div>
                        <div class="mb-3" v-if="hasRole('administrator') || hasRole('manager')">
                            <multiselect-field
                                display-column="label"
                                :required="true"
                                v-model="client"
                                name="user_id"
                                :options="clients"
                                :label="trans('app.calculator.client')"
                            ></multiselect-field>
                        </div>
                    </div>
                    <div class="timeline card border_default col-auto pt-2 pl-lg-3 mx-1">
                        <ul class="base-timeline activity-timeline">
                            <li>
                                <div class="timeline-icon bg-danger d-none d-lg-block">
                                    <div>1</div>
                                </div>
                                <div class="act-time d-none d-lg-block">{{ trans('calculator.dostavista.where_from')
                                    }}
                                </div>
                                <ul class="base-timeline-sub list-unstyled users-list m-0">
                                    <where-dostavista :prefix="'from.'" :index="1" :where="trans('calculator.dostavista.where_from')"
                                                      class="card border_default px-lg-1" :discount="discount"
                                                      :address-string="addressStrings[0]" :geocoder="geocoder"></where-dostavista>
                                </ul>
                            </li>
                            <li>
                                <div class="timeline-icon bg-danger d-none d-lg-block">
                                    <div>2</div>
                                </div>
                                <div class="act-time d-none d-lg-block">{{ trans('calculator.dostavista.where_to') }}
                                </div>
                                <ul class="base-timeline-sub list-unstyled users-list m-0">
                                    <where-dostavista :prefix="'to.'" :index="2" :where="trans('calculator.dostavista.where_to')"
                                                      class="card border_default px-lg-1" :discount="discount"
                                                      :address-string="addressStrings[1]" :geocoder="geocoder"></where-dostavista>
                                </ul>
                            </li>
                            <li v-for="(address, index) in dostavistaPoints" v-bind:key="address.uid">
                                <div class="timeline-icon bg-danger d-none d-lg-block">
                                    <div>{{ index }}</div>
                                </div>
                                <div class="act-time d-none d-lg-block">{{ trans('calculator.dostavista.where_to') }}
                                </div>
                                <ul class="base-timeline-sub list-unstyled users-list m-0">
                                    <where-dostavista :prefix="'extra_points.'+ index +'.'" :index="index" :where="trans('calculator.dostavista.where_to')"
                                                      class="card border_default px-lg-1" :discount="discount"
                                                      :address-string="addressStrings[index-1]" :geocoder="geocoder"></where-dostavista>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <button type="button" class="btn btn-info btn-min-width mx-1 mb-1" @click="extrapointsQuantity">+ {{
                        trans('calculator.dostavista.add_address') }}
                    </button>
                    <div class="row justify-content-left px-1">
                        <div class="col-12">
                            <div class="card border_default mt-1 py-2 px-1">
                                <div class="row px-1">
                                    <div class="col-6">
                                        <div class="card-block">
                                            <fieldset class="form-group">
                                                <input type="text" class="form-control" v-model="data.insurance_amount"
                                                       :placeholder="trans('calculator.dostavista.value')">
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-auto align-items-center align-text-center">
                                                <div class="text-center pt-lg-1">
<!--                                                    <i class="fa fa-info-circle" triggers="focus"-->
<!--                                                       data-toggle="tooltip" data-placement="top" title=""-->
<!--                                                       :data-original-title="trans('calculator.dostavista.regulations')"></i>-->
                                                    {{ insuranceFee+' '+trans('calculator.dostavista.plus_to_order') }}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="" v-if="id>=1&&id!==6&&id<=7">
                                    <b-form-checkbox v-model="checked" name="check-button" switch>
                                        {{ trans('calculator.dostavista.loading_and_unloading_required') }}
                                    </b-form-checkbox>
                                    <div class="" v-if="checked && id<=5">
                                        <b-form-group label="">
                                            <b-form-radio checked name="some-radios" v-model="movers"
                                                          :value="false">{{
                                                trans('calculator.dostavista.help_of_the_driver') }}
                                            </b-form-radio>
                                            <b-form-radio name="some-radios"
                                                          v-model="movers"
                                                          :value="true">{{
                                                trans('calculator.dostavista.additionally_movers') }}
                                            </b-form-radio>
                                        </b-form-group>

                                        <div v-if="movers">
                                            <div class="row px-1">
                                                <b-form-input class="form-control col-12 col-lg-6 mb-1" type="number"
                                                              v-model="inputMovers"
                                                              placeholder=""></b-form-input>
                                                <div class="col-12 col-lg-6 mb-1">{{
                                                    trans('calculator.dostavista.number_of_movers') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="">
                                Оплатить сейчас
                                Оплатить позже
                            </div> -->
                            <!-- <div class="">
                                <div class="card border_default pb-1">
                                    <div class="dv-order-payment">
                                        <div class="card-header text-center">Способ оплаты:</div>
                                        <div class="dv-order-payment__list d-md-flex row mx-auto">
                                            <div class="dv-order-payment__option mb-1 mx-auto">
                                                <img src="/images/dostavista/non-tax.svg"
                                                     title="Наличными курьеру"
                                                     class="dv-order-payment__image">
                                                <p class="dv-order-payment__label">Наличными курьеру</p>
                                                <p class="dv-order-payment__description">Рубли</p>
                                            </div>
                                            <div class="dv-order-payment__option mb-1 mx-auto">
                                                <img src="/images/dostavista/card-icon.svg"
                                                     title="Картой онлайн"
                                                     class="dv-order-payment__image">
                                                <p class="dv-order-payment__label">Картой онлайн</p>
                                                <p class="dv-order-payment__description">Visa/MasterCard и
                                                    другие</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class=" my-1 py-0 px-2">
                                <div class="row">
                                    <div class="col-auto col-md-6 text-center">
                                        <div class="font-size-large text-bold-900" id="start-scroll">
                                            {{trans('calculator.dostavista.total')}} {{ price }}
<!--                                            <i class="fa fa-info-circle" triggers="focus" data-toggle="tooltip"-->
<!--                                               data-placement="top" title=""-->
<!--                                               :data-original-title="trans('calculator.dostavista.shipping_cost')"></i>-->
                                        </div>
                                    </div>
                                    <div class="col-auto col-md-6 text-center" id="end-scroll">
                                        <div class="row">
                                            <div class="col text-center mb-1">
                                                <button v-if="tariff.attributes.price > 0 && (!user || (user.roles[0].name !== 'client_entity'))" class="btn btn-primary" @click="createOrder(true)">
                                                    {{ trans('app.offers.pay_now') }}
                                                </button>
                                            </div>
                                            <div class="col text-center mb-1">
                                                <button v-if="user && (user.roles[0].name !== 'client_individual')" class="btn btn-info" @click="createOrder(false)">
                                                    {{ trans('app.offers.pay_later') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-2">
                                    <div class="text-center small text-muted">
<!--                                        {{trans('calculator.dostavista.sms_notification_options')}}-->
                                    </div>
                                    <div class="form-group mb-0">
                                        <toggle-button v-model="agree" :sync="true"/>
                                        <label class="font-weight-bold">{{ trans('app.offers.agree') }} </label>
                                    </div>
                                </div>
                                <div class=" my-1 py-0 px-2">
                                    <!-- Modal -->
                                    <div class="modal fade text-left" id="modalMapsDostavista" tabindex="-1"
                                         role="dialog"
                                         aria-labelledby="basicModalLabel3" aria-hidden="true"
                                         style="display: none;">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="basicModalLabel3">
                                                        {{trans('calculator.dostavista.hover_over_a_place')}}</h4>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body height-75vh">
                                                    <GmapMap
                                                        :center="mapCenter"
                                                        :zoom="12"
                                                        style="width: 100%; height: 100%"
                                                        :options="{
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            fullscreenControl: false,
                                                        }"
                                                        @click="mapClick"
                                                    >
                                                        <gmap-info-window
                                                            :options="infoOptions"
                                                            :position="marker.position"
                                                            :opened="infoWinOpen"
                                                            @closeclick="infoWinOpen=false">
                                                        </gmap-info-window>
                                                        <GmapMarker v-if="marker.position"
                                                                    :position="marker.position"
                                                                    :clickable="true"
                                                                    :draggable="false"
                                                                    @click="markerClick"
                                                        />
                                                    </GmapMap>
                                                </div>
                                                <div class="ml-1">
                                                    <button type="button" class="btn btn-info btn-min-width mr-1 mb-1"
                                                            @click="setAddressString()">
                                                        {{trans('calculator.dostavista.choose')}}
                                                    </button>
                                                    <span>{{ marker.address }}</span>
                                                </div>
                                                <div class="modal-footer">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import WhereDostavistaCard from './components/WhereDostavistaCard';
    import {gmapApi} from 'vue2-google-maps';
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import TimeRangeSlider from './components/TimeRangeSlider';

    export default {
        name: "CardDostavista",
        components: {
            WhereDostavistaCard,
            VueSlider,
            TimeRangeSlider
        },
        props: {
            clients: {
                default: () => []
            },
        },
        data: function () {
            return {
                infoWinOpen: false,
                infoOptions: {
                    content: '',
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                agree: true,
                inited: false,
                client: {},
                id: this.$store.state.calculator.tariff.attributes.remote_tariff_id,
                weight: 0,
                address: 1,
                mapCenter: {lat: 55.7507452, lng: 37.589949},
                marker: {position: null},
                geocoder: null,
                driver: '',
                movers: false,
                checked: false,
                indeterminate: true,
                dostavistaCities: {},
                cityFias: null,
                inputMovers: "",
                discount: 0,
                mapAddressString: '',
                addressStrings: {},
                data: {
                    'tariff_id': null,
                    "email": '',
                    "pay_now": false,
                    "user_id" : null,
                    from: {
                        "country_name": "",
                        "region_name": "",
                        "city_fias_id": "",
                        "city_name": "",
                        "street": "",
                        "house": "",
                        "first_name": "",
                        "last_name": "",
                        "phone": "",
                        "comment": "",
                        "save": false
                    },
                    to: {
                        "country_name": "",
                        "region_name": "",
                        "city_fias_id": "",
                        "city_name": "",
                        "street": "",
                        "house": "",
                        "first_name": "",
                        "last_name": "",
                        "phone": "",
                        "comment": "",
                        "save": false
                    },
                    'extra_points' : {},
                    "package_type": 'documents',
                    'insurance_amount': 0,
                    "cargoes": [],
                    "pickup_date": "",
                    "pickup_time_from": "",
                    "pickup_time_to": "",
                    "send_notifications": false,
                    "insurance": false,
                    "tariff_response_id": null,
                    'dostavista_order' : {
                        'loaders_count' : 0,
                    },
                }
            }
        },
        watch: {
            selectedPoint(val){
                console.log('this.addressStrings[val]', this.addressStrings[val], val);
                let marker = (!_.isEmpty(this.addressStrings[val]))?this.addressStrings[val]:{position: null};
                this.marker = JSON.parse(JSON.stringify(marker));
                this.infoOptions.content = this.marker.address;
            },
            client(val) {
                this.$set(this.data, 'user_id', _.get(val, 'id', null));
            },
            google: function (val) {
                if (val.maps) {
                    this.geocoder = new val.maps.Geocoder;
                }
            },
            cityFias: function (val) {
                if (val) {
                    axios.get('/geocoding/addressbyid?query=' + val).then(response => {
                            this.mapCenter = {
                                lat: parseFloat(response.data.data[0].attributes.geo_lat),
                                lng: parseFloat(response.data.data[0].attributes.geo_lon)
                            };
                        }
                    );
                    this.$store.dispatch('calculator/setDostavistaFias', {'fias': val})
                }
            },
            dostavistaFrom: {
                handler (val) {
                    if (val.address) {
                        this.data.from.street = val.address.street;
                        this.data.from.house = val.address.house;
                        this.data.from.latitude = val.address.lat;
                        this.data.from.longitude = val.address.lng;
                        this.data.from.postal_code = val.address.postal_code ? val.address.postal_code : this.addressFrom.postal_code;
                    }
                    if (val.phone) {
                        this.data.from.phone = val.phone;
                    }
                    if (val.dateTime && val.dateTime.pickup_date) {
                        this.data.pickup_date = val.dateTime.pickup_date.format("YYYY-MM-DD");
                        this.data.pickup_time_from = val.dateTime.pickup_time_from.format("HH:mm");
                        this.data.pickup_time_to = val.dateTime.pickup_time_to.format("HH:mm");
                    }
                    if (val.comment) {
                        this.data.from.comment = val.comment;
                    }
                    if (val.first_name) {
                        this.data.from.first_name = val.first_name;
                    }
                    if (val.last_name) {
                        this.data.from.last_name = val.last_name;
                    }
                },
                deep: true
            },
            dostavistaTo: {
                handler (val) {
                    if (val.address) {
                        this.data.to.street = val.address.street;
                        this.data.to.house = val.address.house;
                        this.data.to.latitude = val.address.lat;
                        this.data.to.longitude = val.address.lng;
                        this.data.to.postal_code = val.address.postal_code ? val.address.postal_code : this.addressFrom.postal_code;
                    }
                    if (val.phone) {
                        this.data.to.phone = val.phone;
                    }
                    if (val.comment) {
                        this.data.to.comment = val.comment;
                    }
                    if (val.first_name) {
                        this.data.to.first_name = val.first_name;
                    }
                    if (val.last_name) {
                        this.data.to.last_name = val.last_name;
                    }
                },
                deep: true
            },
            dostavistaPoints: {
                handler (val) {
                    var vm = this;
                    _.forEach(vm.data.extra_points, function(o,index){
                        if (!val[index]) {
                            Vue.delete(vm.data.extra_points, index);
                        }
                    });
                    _.forEach(val,function (o,index){
                        var point = {};
                        if (o.address) {
                            point.city_name = vm.addressFrom.city_name;
                            point.street = o.address.street;
                            point.house = o.address.house;
                            point.latitude = o.address.lat;
                            point.longitude = o.address.lng;
                        }
                        if (o.phone) {
                            point.phone = o.phone;
                        }
                        if (o.comment) {
                            point.comment = o.comment;
                        }
                        if (o.first_name) {
                            point.first_name = o.first_name;
                        }
                        if (o.last_name) {
                            point.last_name = o.last_name;
                        }
                        Vue.set(vm.data.extra_points, index, point);
                    });
                },
                deep: true
            },
            data: {
                handler (val) {
                    if (this.inited) {
                        this.$store.dispatch('calculator/checkTariff', val);
                    }
                },
                deep: true
            },
            checked: function (val) {
                if (val) {
                    this.data.dostavista_order.loaders_count = 1;
                } else {
                    this.data.dostavista_order.loaders_count = 0;
                }
            },
            inputMovers: function(val) {
                if (val) {
                    this.data.dostavista_order.loaders_count = 1+parseInt(val);
                } else {
                    this.data.dostavista_order.loaders_count = 1;
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted: function () {
            axios.get('/courierservice/dostavista/cities').then(response => {
                this.dostavistaCities = response.data.data;
            });
            this.cityFias = this.$store.state.calculator.cityFrom.city_fias_id;
            this.weight = _.sumBy(this.$store.state.calculator.cargoes.packages, function (o) {
                return o.weight;
            });
            this.checkOffset();
            this.initData();
        },
        computed: {
            isLoading: {
                get: function () {
                    return this.$store.getters['spinner/getIsLoading'];
                },
                set: function (newValue) {
                    this.$store.dispatch('spinner/setIsLoading', newValue);
                }
            },
            order: {
                get: function () {
                    return this.$store.getters['calculator/getOrder'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setOrder', newValue);
                }
            },
            selectedPoint: {
                get: function () {
                    return this.$store.getters['calculator/getSelectedPoint'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setSelectedPoint', newValue);
                }
            },
            tariff: {
                get: function () {
                    return this.$store.getters['calculator/getTariff'];
                },
                set: function (newValue) {
                    this.$store.dispatch('calculator/setTariff', newValue);
                }
            },
            user: {
                get() {
                    return this.$store.getters['user/getUser'];
                },
                set(newValue) {
                    this.$store.dispatch('user/setUser', newValue);
                }
            },
            google: gmapApi,
            price: function () {
                return this.$store.state.calculator.tariff.attributes.price
            },
            insuranceFee: function () {
                return this.$store.state.calculator.tariff.attributes.insurance_fee;
            },
            addressFrom: function () {
                return this.$store.getters['calculator/getAddressFrom'];
            },
            dostavistaFrom: function () {
                return this.$store.getters['calculator/getDostavistaFrom'];
            },
            dostavistaTo: function () {
                return this.$store.getters['calculator/getDostavistaTo'];
            },
            dostavistaPoints: function () {
                return this.$store.getters['calculator/getDostavistaExtraPoints'];
            },
            cargoes: function () {
                return this.$store.getters['calculator/getCargoes'];
            }

        },
        methods: {
            markerClick (){
                this.mapCenter = this.marker.position;
                this.infoOptions.content = this.marker.address;
                this.infoWinOpen = true;
            },
            increase(val) {
                return val + 3;
            },
            createOrder: function(payNow){
                if(!this.agree) return;
                this.inited = false;
                // check frontend errors
                this.errors.clear(null);
                if (this.errors.count('frontend')) {
                    _.forEach(this.errors.collect(), ((item, index) => {
                        this.errors.add({
                            field: index,
                            msg: this.errors.first(index, 'frontend')
                        });
                    }));
                    return;
                }

                this.isLoading = true;
                this.data.pay_now = payNow;
                this.data.tariff_response_id = this.tariff.attributes.tariff_response_id;
                if (!this.data.from.postal_code) {
                    this.data.from.postal_code = this.addressFrom.postal_code;
                }
                if (!this.data.to.postal_code) {
                    this.data.to.postal_code = this.addressFrom.postal_code;
                }
                let url = '';
                if(this.embedded) url = '?embedded=1';

                axios.post('/orders', this.data)
                    .then(response => {
                        let order_id = response.data.data.id;
                        if (payNow) {
                            axios.post('/payments', {
                                order_id
                            }).then(response => {
                                let redirect_url = response.data.redirect_url ? response.data.redirect_url : false;
                                if (redirect_url) {
                                    window.location.href = redirect_url + url;
                                }
                            });
                        } else{
                            window.location.href = '/orders/' + order_id + '/created' + url;
                        }

                    })
                    .catch(error => {
                        _.forEach(error.response.data.errors, (item, index) => {
                            if (_.isInteger(index)) {
                                this.systemMessage(
                                    "error",
                                    {
                                        text: item.detail
                                    }
                                );
                            } else {
                                this.errors.add({
                                    field: index,
                                    msg: _.head(item)
                                });
                                this.systemMessage(
                                    "error",
                                    {
                                        text: _.head(item)
                                    }
                                );
                            }
                        });
                    })
                    .finally( () => {
                        this.isLoading = false;
                        this.inited = true;
                    });
            },
            initData() {
                this.data.from.country_name = this.addressFrom.country_name;
                this.data.from.region_name =  this.addressFrom.region_name;
                this.data.from.city_id = this.addressFrom.city_fias_id;
                this.data.from.city_name = this.addressFrom.city_name;
                this.data.from.country_code = this.addressFrom.country_code;
                this.data.from.city_name_with_type = this.addressFrom.city_name_with_type;

                this.data.to.country_name = this.addressFrom.country_name;
                this.data.to.region_name =  this.addressFrom.region_name;
                this.data.to.city_id = this.addressFrom.city_fias_id;
                this.data.to.city_name = this.addressFrom.city_name;
                this.data.to.country_code = this.addressFrom.country_code;
                this.data.to.city_name_with_type = this.addressFrom.city_name_with_type;

                this.data.cargoes = this.cargoes.packages;
                this.data.package_type = this.cargoes.type;
                this.data.tariff_id = this.id;

                this.$set(this.data, 'user_id', _.isEmpty(this.order) ? this.data.user_id : this.order.user_id);
                this.client = _.get(this.order, 'user', this.client);

                this.inited = true;
            },
            setAddressString: function () {
                console.log('this.mapAddressString', this.mapAddressString);
                this.$set(this.addressStrings, String(this.selectedPoint), {'address' : this.mapAddressString, 'position': this.marker.position});
                this.mapAddressString = '';
                $('#modalMapsDostavista').modal('hide');
            },
            getTariff: function (id) {
                this.$store.dispatch('calculator/checkTariff', {'slug': 'dostavista', 'tariff_id': id})
            },
            handleScroll(event) {
                if (document.getElementById('scroll')) {
                    this.checkOffset();
                }
            },
            checkOffset: function () {
                if ($('#start-scroll').offset().top + $('#start-scroll').height()
                    >= $('#end-scroll').offset().top - 10)
                    $('#start-scroll').css('position', 'relative').css("bottom", "0").css("background", "none").css("padding", "10px 10px").css("color", "#6b6f80").css("border-radius", "0");
                if ($(document).scrollTop() + window.innerHeight < $('#end-scroll').offset().top)
                    $('#start-scroll').css('position', 'fixed').css("bottom", "-4px").css("background", "#9975f7").css("padding", "10px 10px").css("color", "#ffffff").css("border-radius", "5px"); // restore when you scroll up
            },
            toggleIndeterminate() {
                this.indeterminate = !this.indeterminate
            },
            mapClick: function (payload) {
                this.marker.position = {lat: payload.latLng.lat(), lng: payload.latLng.lng()};
                if (this.geocoder) {
                    this.geocoder.geocode({'location': this.marker.position},  (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                console.log('google map:', results);
                                this.infoOptions.content = results[0]['formatted_address'];
                                this.infoWinOpen = true;
                                this.$set(this.marker, 'address', results[0]['formatted_address']);
                                this.mapAddressString = results[0]['formatted_address'];
                            } else {
                                console.log('not found');
                            }
                        } else {
                            console.log('Geocoder failed due to: ' + status);
                        }
                    });
                }
            },
            setWeight: function (weight) {
                this.weight = weight;
            },
            setVehicles: function (id) {
                this.id = id;
                this.data.tariff_id = id;
            },
            extrapointsQuantity: function () {
                this.$store.dispatch('calculator/createExtraPoint');
            },

            toggle: function () {
                this.checked = !this.checked;
            },
            setDiscount: function (discount) {
                this.discount = discount;
            },
            // onChangeTypeDriverHandler: function (driver) {
            //     this.movers = !this.value;
            //     this.value = driver;
            //     this.driver = this.value;

            // },
            onChangeTypeMoversHandler: function (movers) {
                this.movers = movers;
            }
        }
    }
</script>
<style scoped>

    @media (min-width: 768px) {
        .modal-dialog {
            max-width: 80% !important;
        }
    }

    .users-list li + li {
        margin-left: 0 !important;
    }

    /*при экране лг справа откуда куда*/
    li {
        list-style-type: none; /* Убираем маркеры */
    }

    .activity-timeline li .timeline-icon {
        font-size: 16px !important;
        line-height: 2 !important;
    }

    .activity-timeline .act-time {
        left: -110px !important;
    }

    ul.base-timeline.activity-timeline {
        margin-left: 60px;
    }

    @media (max-width: 768px) {
        ul.base-timeline.activity-timeline {
            margin-left: 0;
        }

        .timeline .activity-timeline {
            padding-left: 0;
        }

        .activity-timeline {
            border-left: 0;
        }

        .base-timeline {
            border-left: 0;
        }
    }
</style>
