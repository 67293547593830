import MixinsEdit from '../../../vendor/codersstudio/crud/mixins/edit.js'

Vue.component('entity-profile-edit', {
    data() {
        return {
            link: '/admin/entity_profiles',
        }
    },
    mixins: [MixinsEdit]
});
