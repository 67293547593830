import MixinsCreate from '../../../vendor/codersstudio/crud/mixins/create.js'

Vue.component('entity-profile-create', {
    data() {
        return {
            link: '/admin/entity_profiles',
        }
    },
    mixins: [MixinsCreate]
});
